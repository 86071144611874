import './index.css';
import React, { Suspense, useState, useEffect, useRef } from 'react'; 
import { Canvas} from '@react-three/fiber';
import {OrbitControls, SpotLight, useGLTF, TransformControls, StandardEffects, Html, useProgress} from '@react-three/drei';
import { useControl } from "react-three-gui"
import { Physics } from "@react-three/cannon";
import { Plane } from "./Plane";
import { WideWall } from "./WideWall";
import { Floor } from "./Floor";
import { SideWalls } from "./SideWalls";
import { Pointer } from "./Pointer";
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { useLoader } from '@react-three/fiber';
import { render } from 'react-dom';
import axios from 'axios';


let roomLength = 0;
let designLoaded = false;
let verifydata = "ENTER YOU DESIGN NUMBER";
let designVerified = false;
let wallFixed = false;
let priceX = 0;
let weightX = 0;
let flodepth = 0;

let bodyColor = "Wood";
let BaseColor = "Fog";
let leftDoorColor = "Wood";
let rightDoorCOlor= "Wood";
let leftHandleColor = "Fog";
let rightHandleColor = "Fog";

const woodColorCode = "#af8a6f";
const fogColorCode = "#a6a6a6";
const nightColorCode = "#0d0d0d";
const snowColorCode = "#f8f8f8";
const grassColorCode = "a4b60c";
const chiliColorCode = "#ff635a";
const oceanBlueColorCode = "#4a5a73";
const greenGalleColorCode = "#8d9e94";

let bodyColorCode = woodColorCode;
let BaseColorCode = fogColorCode;
let leftDoorColorCode = woodColorCode;
let rightDoorCOlorCode = woodColorCode;
let leftHandleColorCode = fogColorCode;
let rightHandleColorCode = fogColorCode;

let RCol1Row4 = 0;
let RCol1Row3 = 0;
let RCol1Row2 = 0;
let RCol1Row1 = 0;

let RCol2Row4 = 0;
let RCol2Row3 = 0;
let RCol2Row2 = 0;
let RCol2Row1 = 0;

let RCol3Row4 = 0;
let RCol3Row3 = 0;
let RCol3Row2 = 0;
let RCol3Row1 = 0;

let RCol4Row4 = 0;
let RCol4Row3 = 0;
let RCol4Row2 = 0;
let RCol4Row1 = 0;

let RCol5Row4 = 0;
let RCol5Row3 = 0;
let RCol5Row2 = 0;
let RCol5Row1 = 0;

let RCol6Row4 = 0;
let RCol6Row3 = 0;
let RCol6Row2 = 0;
let RCol6Row1 = 0;

let RCol7Row4 = 0;
let RCol7Row3 = 0;
let RCol7Row2 = 0;
let RCol7Row1 = 0;

let RCol8Row4 = 0;
let RCol8Row3 = 0;
let RCol8Row2 = 0;
let RCol8Row1 = 0;

let RCol9Row4 = 0;
let RCol9Row3 = 0;
let RCol9Row2 = 0;
let RCol9Row1 = 0;

let RCol10Row4 = 0;
let RCol10Row3 = 0;
let RCol10Row2 = 0;
let RCol10Row1 = 0;

let RCol11Row4 = 0;
let RCol11Row3 = 0;
let RCol11Row2 = 0;
let RCol11Row1 = 0;

let RCol12Row4 = 0;
let RCol12Row3 = 0;
let RCol12Row2 = 0;
let RCol12Row1 = 0;

let RCol13Row4 = 0;
let RCol13Row3 = 0;
let RCol13Row2 = 0;
let RCol13Row1 = 0;

let RCol14Row4 = 0;
let RCol14Row3 = 0;
let RCol14Row2 = 0;
let RCol14Row1 = 0;



let wallshowhideimg = "./images/remove-walls.png";
let fixRoomHideShow = "./images/arrow-down.png";
let navigatorUpArrow ="./images/up-arrow.png";
let navigatorLeftArrow = "./images/left-arrow.png";
let navigatorRightArrow =  "./images/arrow-orange.png";
let navigatorDownArrow = "./images/down-arrow.png";

function Loader() {
  const { progress } = useProgress()
  return <Html className="loadingContainer" >Loading... {progress.toFixed(2)}% Completed</Html>
}

function Model10(props) {
  const { nodes, materials } = useGLTF('/Small.gltf')
  return (
    <group {...props} dispose={null} scale={1.0633846}>
      <mesh geometry={nodes.Right_Door.geometry} material={materials.Right_Door} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rightdoor} visible={props.addRemoveItems.doorsOn}/>
      <mesh geometry={nodes['Long_Handle-R'].geometry} material={materials['Long_Handle-R']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rhandle} visible={props.addRemoveItems.longHandleOn}/>
      <mesh geometry={nodes['Round_Handle-R'].geometry} material={materials['Round_Handle-R']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rhandle} visible={props.addRemoveItems.RoundHandleOn}/>
      <mesh geometry={nodes.Right_Lateral001.geometry} material={materials.Right_Door} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rightdoor} visible={false}/>
      <mesh geometry={nodes.Back_Panel.geometry} material={materials.Back_Panel} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.backOn}/>
      <mesh geometry={nodes.Left_Lateral.geometry} material={materials.Left_Lateral} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.leftOn}/>
      <mesh geometry={nodes['Long_Handle-L'].geometry} material={materials['Long_Handle-L']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.lhandle} visible={props.addRemoveItems.longHandleOn}/>
      <mesh geometry={nodes['Round_Handle-L'].geometry} material={materials['Round_Handle-L']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.lhandle} visible={props.addRemoveItems.RoundHandleOn}/>
      <mesh geometry={nodes.Right_Lateral.geometry} material={materials.Right_Lateral} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.rightOn}/>
      <mesh geometry={nodes.Left_Door.geometry} material={materials.Left_Door} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.leftDoor} visible={props.addRemoveItems.doorsOn} />
      <mesh geometry={nodes.Base_with_4_legs.geometry} material={materials.Base_with_4_legs} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.basefeet} visible={true} />
      <mesh geometry={nodes.Rack_with_top_plate.geometry} material={materials.Rack_with_top_plate} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={true}/>
    </group>
  )
}


function Model10M(props) {
  const { nodes, materials } = useGLTF('/medium.gltf')
  return (
    <group {...props} dispose={null} scale={1.0633846}>
      <mesh geometry={nodes.Base.geometry} material={materials.Base} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.basefeet} visible={true}/>
      <mesh geometry={nodes.Left_Long_Handle.geometry} material={materials.Left_Long_Handle} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rhandle} visible={props.addRemoveItems.longHandleOn}/>
      <mesh geometry={nodes.Right_Long_Handle.geometry} material={materials.Right_Long_Handle} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.lhandle} visible={props.addRemoveItems.longHandleOn}/>
      <mesh geometry={nodes.Right_Round_Handle.geometry} material={materials.Right_Round_Handle} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.lhandle} visible={props.addRemoveItems.RoundHandleOn}/>
      <mesh geometry={nodes.Left_Round_Handle.geometry} material={materials.Left_Round_Handle} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rhandle} visible={props.addRemoveItems.RoundHandleOn}/>
      <mesh geometry={nodes.Right_Panel_Bottom.geometry} material={materials.Right_Panel_Bottom} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.rightOn}/>
      <mesh geometry={nodes.Back_Panel_Bottom.geometry} material={materials.Back_Panel_Bottom} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.backOn}/>
      <mesh geometry={nodes.Left_Panel_Bottom.geometry} material={materials.Left_Panel_Bottom} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.leftOn}/>
      <mesh geometry={nodes.Rack.geometry} material={materials.Rack}rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={true}  />
      <mesh geometry={nodes.Left_Door.geometry} material={materials.Left_Door} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.leftDoor} visible={props.addRemoveItems.doorsOn}/>
      <mesh geometry={nodes.Right_Door.geometry} material={materials.Right_Door} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rightdoor} visible={props.addRemoveItems.doorsOn}/>
      <mesh geometry={nodes.Middle_Separator.geometry} material={materials.Middle_Separator} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.seperatorOn}/>
      <mesh geometry={nodes.Right_Panel_Top.geometry} material={materials.Right_Panel_Top} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.rightOn}/>
      <mesh geometry={nodes.Left_Panel_Top.geometry} material={materials.Left_Panel_Top} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.leftOn}/>
      <mesh geometry={nodes.Back_Panel_Top.geometry} material={materials.Back_Panel_Top} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.backOn}/>
    </group>
  )
}


function Model10L(props) {
  const { nodes, materials } = useGLTF('/Large.gltf')
  return (
    <group {...props} dispose={null} scale={1.0633846}>
      <mesh geometry={nodes['Long_Handle-R'].geometry} material={materials['Long Handle-R']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rhandle} visible={props.addRemoveItems.longHandleOn}/>
      <mesh geometry={nodes['Long_Handle-L'].geometry} material={materials['Long Handle-L']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.lhandle} visible={props.addRemoveItems.longHandleOn}/>
      <mesh geometry={nodes.Left_Door.geometry} material={materials['Left Door']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.leftDoor} visible={props.addRemoveItems.doorsOn}/>
      <mesh geometry={nodes.Right_Door.geometry} material={materials['Right Door.009']}rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rightdoor} visible={props.addRemoveItems.doorsOn}/>
      <mesh geometry={nodes['Shelf_Separator-3'].geometry} material={materials['Shelf Separator-3']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.seperatorOn3}/>
      <mesh geometry={nodes['Shelf_Separator-2'].geometry} material={materials['Shelf Separator-2']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.seperatorOn2}/>
      <mesh geometry={nodes['Shelf_Separator-1'].geometry} material={materials['Shelf Separator-1']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.seperatorOn}/>
      <mesh geometry={nodes['Right_Lateral-4'].geometry} material={materials['Right Lateral-4']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.rightOn}/>
      <mesh geometry={nodes['Back_Panel-4'].geometry} material={materials['Back Panel-4']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.backOn}/>
      <mesh geometry={nodes['Left_Lateral-4'].geometry} material={materials['Left Lateral-4']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.leftOn}/>
      <mesh geometry={nodes['Right_Lateral-3'].geometry} material={materials['Right Lateral-3']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.rightOn}/>
      <mesh geometry={nodes['Back_Panel-3'].geometry} material={materials['Back Panel-3']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.backOn}/>
      <mesh geometry={nodes['Left_Lateral-3'].geometry} material={materials['Left Lateral-3']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.leftOn}/>
      <mesh geometry={nodes['Right_Lateral-1'].geometry} material={materials['Right Lateral-1']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.rightOn}/>
      <mesh geometry={nodes['Right_Lateral-2'].geometry} material={materials['Right Lateral-2']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.rightOn}/>
      <mesh geometry={nodes['Left_Lateral-1'].geometry} material={materials['Left Lateral-1']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.leftOn}/>
      <mesh geometry={nodes['Left_Lateral-2'].geometry} material={materials['Left Lateral-2']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.leftOn}/>
      <mesh geometry={nodes['Back_Panel-2'].geometry} material={materials['Back Panel-2']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.backOn}/>
      <mesh geometry={nodes['Back_Panel-1'].geometry} material={materials['Back Panel-1']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.backOn}/>
      <mesh geometry={nodes.Base_with_4_legs.geometry} material={materials['Base with 4 legs']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.basefeet} visible={true}/>
      <mesh geometry={nodes.Rack_with_top_plate.geometry} material={materials['Rack with top plate']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={true}/>
    </group>
  )
}


function Model10Up(props) {
  const { nodes, materials } = useGLTF('/Small.gltf')
  return (
    <group {...props} dispose={null} scale={1.0633846}>
      <mesh geometry={nodes.Right_Door.geometry} material={materials.Right_Door} rotation={[Math.PI / 2, 0, 0]}material-color={props.customColors.rightdoor} visible={props.addRemoveItems.doorsOn}/>
      <mesh geometry={nodes['Long_Handle-R'].geometry} material={materials['Long_Handle-R']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rhandle} visible={props.addRemoveItems.longHandleOn}/>
      <mesh geometry={nodes['Round_Handle-R'].geometry} material={materials['Round_Handle-R']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rhandle} visible={props.addRemoveItems.RoundHandleOn}/>
      <mesh geometry={nodes.Right_Lateral001.geometry} material={materials.Right_Door} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rightdoor} visible={false}/>
      <mesh geometry={nodes.Back_Panel.geometry} material={materials.Back_Panel} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.backOn}/>
      <mesh geometry={nodes.Left_Lateral.geometry} material={materials.Left_Lateral} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.leftOn}/>
      <mesh geometry={nodes['Long_Handle-L'].geometry} material={materials['Long_Handle-L']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.lhandle} visible={props.addRemoveItems.longHandleOn}/>
      <mesh geometry={nodes['Round_Handle-L'].geometry} material={materials['Round_Handle-L']} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.lhandle} visible={props.addRemoveItems.RoundHandleOn}/>
      <mesh geometry={nodes.Right_Lateral.geometry} material={materials.Right_Lateral} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.rightOn}/>
      <mesh geometry={nodes.Left_Door.geometry} material={materials.Left_Door} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.leftDoor} visible={props.addRemoveItems.doorsOn} />
      <mesh geometry={nodes.Base_with_4_legs.geometry} material={materials.Base_with_4_legs} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.basefeet} visible={false} />
      <mesh geometry={nodes.Rack_with_top_plate.geometry} material={materials.Rack_with_top_plate} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={true}/>
    </group>
  )
}


function Model10MUp(props) {
  const { nodes, materials } = useGLTF('/medium.gltf')
  return (
    <group {...props} dispose={null} scale={1.0633846}>
      <mesh geometry={nodes.Base.geometry} material={materials.Base} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.basefeet} visible={false}/>
      <mesh geometry={nodes.Left_Long_Handle.geometry} material={materials.Left_Long_Handle} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rhandle} visible={props.addRemoveItems.longHandleOn}/>
      <mesh geometry={nodes.Right_Long_Handle.geometry} material={materials.Right_Long_Handle} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.lhandle} visible={props.addRemoveItems.longHandleOn}/>
      <mesh geometry={nodes.Right_Round_Handle.geometry} material={materials.Right_Round_Handle} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.lhandle} visible={props.addRemoveItems.RoundHandleOn}/>
      <mesh geometry={nodes.Left_Round_Handle.geometry} material={materials.Left_Round_Handle} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rhandle} visible={props.addRemoveItems.RoundHandleOn}/>
      <mesh geometry={nodes.Right_Panel_Bottom.geometry} material={materials.Right_Panel_Bottom} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.rightOn}/>
      <mesh geometry={nodes.Back_Panel_Bottom.geometry} material={materials.Back_Panel_Bottom} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.backOn}/>
      <mesh geometry={nodes.Left_Panel_Bottom.geometry} material={materials.Left_Panel_Bottom} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.leftOn}/>
      <mesh geometry={nodes.Rack.geometry} material={materials.Rack}rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={true}  />
      <mesh geometry={nodes.Left_Door.geometry} material={materials.Left_Door} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.leftDoor} visible={props.addRemoveItems.doorsOn}/>
      <mesh geometry={nodes.Right_Door.geometry} material={materials.Right_Door} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.rightdoor} visible={props.addRemoveItems.doorsOn}/>
      <mesh geometry={nodes.Middle_Separator.geometry} material={materials.Middle_Separator} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.seperatorOn}/>
      <mesh geometry={nodes.Right_Panel_Top.geometry} material={materials.Right_Panel_Top} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.rightOn}/>
      <mesh geometry={nodes.Left_Panel_Top.geometry} material={materials.Left_Panel_Top} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.leftOn}/>
      <mesh geometry={nodes.Back_Panel_Top.geometry} material={materials.Back_Panel_Top} rotation={[Math.PI / 2, 0, 0]} material-color={props.customColors.back} visible={props.addRemoveItems.backOn}/>
    </group>
  )
}

function App() {
  const [inputWidth, setInputWidth] = useState(5);
  const [inputHeight, setInputHeight] = useState(3);
  const [inputDepth, setInputDepth] = useState(4);
  const [maxRightCount, setMaxRightCount] = useState(7);
  const [maxUpCount, setMaxUpCount] = useState(8);
  const [wallBtnCaption, setwallBtnCaption] = useState("HIDE WALLS");

  const [posx, setPosx] = useState(2.5);
  const [posz, setPosz] = useState(2);
  const [posy, setPosy] = useState(1.5);
  const [wallvisible, setWallVisible] = useState(true);
  //const [selModule, setSelModule] = useState("S");
  const [matColor, setMatColor] = useState("#af8a6f");
  const [selColorName, setSelColorName] = useState("Wood");
  const [startX, setStartX] = useState(-2);
  const [startY, setStartY] = useState(0.1);
  const [colCount, setColCount] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [selectedMat, setSelectedMat] = useState("back")
  const [basefeet, setBasefeet] = useState("#a6a6a6");
  const [leftDoor,setLeftDoor] = useState("#af8a6f");
  const [rightdoor,setRightDoor] = useState("#af8a6f");
  const [back,setBack] = useState("#af8a6f");
  const [lhandle,setLHandle] = useState("#a6a6a6");
  const [rhandle,setRHandle] = useState("#a6a6a6");
  const [backZone, setBackZone] = useState(true);
  const [baseZone, setBaseZone] = useState(false);
  const [ldoorZone, setLDoorZone] = useState(false);
  const [rdoorZone, setRDoorZone] = useState(false);

  const [lhandleZone, setLHandleZone] = useState(false);
  const [rhandleZone, setRHandleZone] = useState(false);
  const [curZone, setCurZone] = useState("Rack & Panels");
  const [backOn, setBackOn] = useState(false);
  const [doorsOn, setDoorOn] = useState(false);
  const [seperatorOn, setSeperatorOn] = useState(false);
  const [seperatorOn2, setSeperatorOn2] = useState(false);
  const [seperatorOn3, setSeperatorOn3] = useState(false);

  const [longHandleOn, setLongHandleOn] = useState(false);
  const [RoundHandleOn, setRoundHandleOn] = useState(false);
  const [leftOn, setLeftOn] = useState(false);
  const [rightOn, setRightOn] = useState(false);

  const [showHideDoors, setShowHideDoors] = useState("HIDE DOORS");
  const [placeUpRight, setPlaceUpRight] = useState("LANDSCAPE");
  const [addremoveextention, setAddremoveextention] = useState("ADD"); 

  const [floWidth, setFloWidth] = useState(0);
  const [floHeight, setFloHeight] = useState(0);

  
  

  const [upArrrow, setUpArrow] = useState(false);
  const [downArrrow, setDownArrow] = useState(false);
  const [leftArrrow, setLeftArrow] = useState(false);
  const [rightArrrow, setRightArrow] = useState(false);

  const [col01Row01, setCol01Row01] = useState(false);
  const [col02Row01, setCol02Row01] = useState(false);
  const [col03Row01, setCol03Row01] = useState(false);
  const [col04Row01, setCol04Row01] = useState(false);
  const [col05Row01, setCol05Row01] = useState(false);
  const [col06Row01, setCol06Row01] = useState(false);
  const [col07Row01, setCol07Row01] = useState(false);
  const [col08Row01, setCol08Row01] = useState(false);
  const [col09Row01, setCol09Row01] = useState(false);
  const [col10Row01, setCol10Row01] = useState(false);
  const [col11Row01, setCol11Row01] = useState(false);
  const [col12Row01, setCol12Row01] = useState(false);
  const [col13Row01, setCol13Row01] = useState(false);
  const [col14Row01, setCol14Row01] = useState(false);
  
  const [col01Row02, setCol01Row02] = useState(false);
  const [col02Row02, setCol02Row02] = useState(false);
  const [col03Row02, setCol03Row02] = useState(false);
  const [col04Row02, setCol04Row02] = useState(false);
  const [col05Row02, setCol05Row02] = useState(false);
  const [col06Row02, setCol06Row02] = useState(false);
  const [col07Row02, setCol07Row02] = useState(false);
  const [col08Row02, setCol08Row02] = useState(false);
  const [col09Row02, setCol09Row02] = useState(false);
  const [col10Row02, setCol10Row02] = useState(false);
  const [col11Row02, setCol11Row02] = useState(false);
  const [col12Row02, setCol12Row02] = useState(false);
  const [col13Row02, setCol13Row02] = useState(false);
  const [col14Row02, setCol14Row02] = useState(false);
  
  const [col01Row03, setCol01Row03] = useState(false);
  const [col02Row03, setCol02Row03] = useState(false);
  const [col03Row03, setCol03Row03] = useState(false);
  const [col04Row03, setCol04Row03] = useState(false);
  const [col05Row03, setCol05Row03] = useState(false);
  const [col06Row03, setCol06Row03] = useState(false);
  const [col07Row03, setCol07Row03] = useState(false);
  const [col08Row03, setCol08Row03] = useState(false);
  const [col09Row03, setCol09Row03] = useState(false);
  const [col10Row03, setCol10Row03] = useState(false);
  const [col11Row03, setCol11Row03] = useState(false);
  const [col12Row03, setCol12Row03] = useState(false);
  const [col13Row03, setCol13Row03] = useState(false);
  const [col14Row03, setCol14Row03] = useState(false);
  
  const [col01Row04, setCol01Row04] = useState(false);
  const [col02Row04, setCol02Row04] = useState(false);
  const [col03Row04, setCol03Row04] = useState(false);
  const [col04Row04, setCol04Row04] = useState(false);
  const [col05Row04, setCol05Row04] = useState(false);
  const [col06Row04, setCol06Row04] = useState(false);
  const [col07Row04, setCol07Row04] = useState(false);
  const [col08Row04, setCol08Row04] = useState(false);
  const [col09Row04, setCol09Row04] = useState(false);
  const [col10Row04, setCol10Row04] = useState(false);
  const [col11Row04, setCol11Row04] = useState(false);
  const [col12Row04, setCol12Row04] = useState(false);
  const [col13Row04, setCol13Row04] = useState(false);
  const [col14Row04, setCol14Row04] = useState(false);


const [designNo, setDesignNo] = useState('');
const [designData, setDesignData] = useState([]);

  const [col01X, setCol01X] = useState(startX);
  let newX = startX + 0.768;
  const [col02X, setCol02X] = useState(newX);
  newX = newX + 0.768;
  const [col03X, setCol03X] = useState(newX);
  newX = newX + 0.768;
  const [col04X, setCol04X] = useState(newX);
  newX = newX + 0.768;
  const [col05X, setCol05X] = useState(newX);
  newX = newX + 0.768;
  const [col06X, setCol06X] = useState(newX);
  newX = newX + 0.768;
  const [col07X, setCol07X] = useState(newX);
  newX = newX + 0.768;
  const [col08X, setCol08X] = useState(newX);
  newX = newX + 0.768;
  const [col09X, setCol09X] = useState(newX);
  newX = newX + 0.768;
  const [col10X, setCol10X] = useState(newX);
  newX = newX + 0.768;
  const [col11X, setCol11X] = useState(newX);
  newX = newX + 0.768;
  const [col12X, setCol12X] = useState(newX);
  newX = newX + 0.768;
  const [col13X, setCol13X] = useState(newX);
  newX = newX + 0.768;
  const [col14X, setCol14X] = useState(newX);

  const [row01X, setRow01X] = useState(startY);
  let newY = startY + 0.419;
  const [row02X, setRow02X] = useState(newY);
  newY = newY + 0.419;
  const [row03X, setRow03X] = useState(newY);
  newY = newY + 0.419;
  const [row04X, setRow04X] = useState(newY);
  newY = newY + 0.419;
  const [row05X, setRow05X] = useState(newY);
  newY = newY + 0.419;
  const [row06X, setRow06X] = useState(newY);
  newY = newY + 0.419;
  const [row07X, setRow07X] = useState(newY);
  newY = newY + 0.419;
  const [row08X, setRow08X] = useState(newY);
  newY = newY + 0.419;
  const [row09X, setRow09X] = useState(newY);
  newY = newY + 0.419;
  const [row10X, setRow10X] = useState(newY);
  newY = newY + 0.419;
  const [row11X, setRow11X] = useState(newY);
  newY = newY + 0.419;
  const [row12X, setRow12X] = useState(newY);
  newY = newY + 0.419;
  const [row13X, setRow13X] = useState(newY);

  const [curX, setCurX] = useState(0);
  const [curY, setCurY] = useState(0);

  const woodColorCode = "#af8a6f";
  const fogColorCode = "#a6a6a6";
  const nightColorCode = "#0d0d0d";
  const snowColorCode = "#f8f8f8";
  const grassColorCode = "#a4b60c";
  const chiliColorCode = "#ff635a";
  const oceanBlueColorCode = "#4a5a73";
  const greenGalleColorCode = "#8d9e94";


  const getBodyColorCode = (event) => {
      if (bodyColor === "Wood") {
        bodyColorCode = woodColorCode;
      } else if (bodyColor === "Fog") {
        bodyColorCode = fogColorCode;
      } else if (bodyColor === "Night") {
        bodyColorCode = nightColorCode;
      } else if (bodyColor === "Snow") {
        bodyColorCode = snowColorCode;
      } else if (bodyColor === "Grass") {
        bodyColorCode = grassColorCode;
      } else if (bodyColor === "Chili") {
        bodyColorCode = chiliColorCode;
      } else if (bodyColor === "Ocean Blue") {
        bodyColorCode = oceanBlueColorCode;
      } else if (bodyColor === "Green Galle") {
        bodyColorCode = greenGalleColorCode;
      }
  }

  const getBaseColorCode = (event) => {
    if (BaseColor === "Wood") {
      BaseColorCode = woodColorCode;
    } else if (BaseColor === "Fog") {
      BaseColorCode = fogColorCode;
    } else if (BaseColor === "Night") {
      BaseColorCode = nightColorCode;
    } else if (BaseColor === "Snow") {
      BaseColorCode = snowColorCode;
    } else if (BaseColor === "Grass") {
      BaseColorCode = grassColorCode;
    } else if (BaseColor === "Chili") {
      BaseColorCode = chiliColorCode;
    } else if (BaseColor === "Ocean Blue") {
      BaseColorCode = oceanBlueColorCode;
    } else if (BaseColor === "Green Galle") {
      BaseColorCode = greenGalleColorCode;
    }
  }

  const getLeftDoorColorCode = (event) => {
    if (leftDoorColor === "Wood") {
      leftDoorColorCode = woodColorCode;
    } else if (leftDoorColor === "Fog") {
      leftDoorColorCode = fogColorCode;
    } else if (leftDoorColor === "Night") {
      leftDoorColorCode = nightColorCode;
    } else if (leftDoorColor === "Snow") {
      leftDoorColorCode = snowColorCode;
    } else if (leftDoorColor === "Grass") {
      leftDoorColorCode = grassColorCode;
    } else if (leftDoorColor === "Chili") {
      leftDoorColorCode = chiliColorCode;
    } else if (leftDoorColor === "Ocean Blue") {
      leftDoorColorCode = oceanBlueColorCode;
    } else if (leftDoorColor === "Green Galle") {
      leftDoorColorCode = greenGalleColorCode;
    }
  }

  const getRightDoorColorCode = (event) => {
    if (rightDoorCOlor === "Wood") {
      rightDoorCOlorCode = woodColorCode;
    } else if (rightDoorCOlor === "Fog") {
      rightDoorCOlorCode = fogColorCode;
    } else if (rightDoorCOlor === "Night") {
      rightDoorCOlorCode = nightColorCode;
    } else if (rightDoorCOlor === "Snow") {
      rightDoorCOlorCode = snowColorCode;
    } else if (rightDoorCOlor === "Grass") {
      rightDoorCOlorCode = grassColorCode;
    } else if (rightDoorCOlor === "Chili") {
      rightDoorCOlorCode = chiliColorCode;
    } else if (rightDoorCOlor === "Ocean Blue") {
      rightDoorCOlorCode = oceanBlueColorCode;
    } else if (rightDoorCOlor === "Green Galle") {
      rightDoorCOlorCode = greenGalleColorCode;
    }
  }

  const getLeftHandleolorCode = (event) => {
    if (leftHandleColor === "Wood") {
      leftHandleColorCode = woodColorCode;
    } else if (leftHandleColor === "Fog") {
      leftHandleColorCode = fogColorCode;
    } else if (leftHandleColor === "Night") {
      leftHandleColorCode = nightColorCode;
    } else if (leftHandleColor === "Snow") {
      leftHandleColorCode = snowColorCode;
    } else if (leftHandleColor === "Grass") {
      leftHandleColorCode = grassColorCode;
    } else if (leftHandleColor === "Chili") {
      leftHandleColorCode = chiliColorCode;
    } else if (leftHandleColor === "Ocean Blue") {
      leftHandleColorCode = oceanBlueColorCode;
    } else if (leftHandleColor === "Green Galle") {
      leftHandleColorCode = greenGalleColorCode;
    }
}

const getRightHandleColorCode = (event) => {
  if (rightHandleColor === "Wood") {
    rightHandleColorCode = woodColorCode;
  } else if (rightHandleColor === "Fog") {
    rightHandleColorCode = fogColorCode;
  } else if (rightHandleColor === "Night") {
    rightHandleColorCode = nightColorCode;
  } else if (rightHandleColor === "Snow") {
    rightHandleColorCode = snowColorCode;
  } else if (rightHandleColor === "Grass") {
    rightHandleColorCode = grassColorCode;
  } else if (rightHandleColor === "Chili") {
    rightHandleColorCode = chiliColorCode;
  } else if (rightHandleColor === "Ocean Blue") {
    rightHandleColorCode = oceanBlueColorCode;
  } else if (rightHandleColor === "Green Galle") {
    rightHandleColorCode = greenGalleColorCode;
  }
}

const applyColors = () => {
  setBasefeet(BaseColorCode);
  setBack(bodyColorCode);
  setLHandle(leftHandleColorCode);
  setRHandle(rightHandleColorCode);
  setLeftDoor(leftDoorColorCode);
  setRightDoor(rightDoorCOlorCode);
}

let fixRoom = (event) => {
  if (designVerified === false) {
    alert("Please verify your design details to get room dimensions and then try.")
    wallFixed = false;
  } else {
    if (inputWidth < 2 || inputHeight < 2 || inputDepth < 2)  {
        alert("The Room dimentions are too small. Please re-define the space.");
      
    } else if (inputWidth > 11 || inputHeight > 5 || inputDepth > 10)  {
        alert("The Room dimentions are too large. Please re-define the space.");
    
    } else if (floWidth > inputWidth) {
        alert("No space is sufficient for the added Modules. Please re-define the space.")
    
    } else {
        const getposx = inputWidth/2;
        setPosx(getposx);
        const getposy = inputHeight/2;
        setPosy(getposy);
        const getposz = inputDepth/2;
        setPosz(getposz);
        const getInitialX = -getposx + 0.5;
        setStartX(getInitialX);
        const hCount = Math.floor((inputWidth - 0.5)/0.768);
        setMaxRightCount(hCount);
        const vCount = Math.floor((inputHeight - 0.2)/0.419);
        setMaxUpCount(vCount);

        setCol01X(getInitialX);
        let newX = getInitialX + 0.768;
        setCol02X(newX);
        newX = newX + 0.768;
        setCol03X(newX);
        newX = newX + 0.768;
        setCol04X(newX);
        newX = newX + 0.768;
        setCol05X(newX);
        newX = newX + 0.768;
        setCol06X(newX);
        newX = newX + 0.768;
        setCol07X(newX);
        newX = newX + 0.768;
        setCol08X(newX);
        newX = newX + 0.768;
        setCol09X(newX);
        newX = newX + 0.768;
        setCol10X(newX);
        newX = newX + 0.768;
        setCol11X(newX);
        newX = newX + 0.768;
        setCol12X(newX);
        newX = newX + 0.768;
        setCol13X(newX);
        newX = newX + 0.768;
        setCol14X(newX);

      }
      wallFixed = true;
      verifydata = "ROOM IS FIXED FOR LOADING YOUR DESIGN.";
    }
  }
  let onOffWalls = (event) => {
    if (wallBtnCaption === "HIDE WALLS") {
      setWallVisible(false);
      setwallBtnCaption("SHOW WALLS");
      wallshowhideimg = "./images/show-walls.png";
    } else {
      setWallVisible(true);
      setwallBtnCaption("HIDE WALLS");
      wallshowhideimg = "./images/remove-walls.png";
    }
  }

  let moveUp = (event) => {
    
    if (curY === row01X) {
      if (curX === col01X && col01Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col02X && col02Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col03X && col03Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col04X && col04Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col05X && col05Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col06X && col06Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col07X && col07Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col08X && col08Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col09X && col09Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col10X && col10Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col11X && col11Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col12X && col12Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col13X && col13Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col14X && col14Row02 === true) {
        setCurY(row02X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } 
    
    } else if (curY === row02X) {
     
      if (curX === col01X && col01Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col02X && col02Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col03X && col03Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col04X && col04Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col05X && col06Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col06X && col06Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col07X && col07Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col08X && col08Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col09X && col09Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col10X && col10Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col11X && col11Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col12X && col12Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col13X && col13Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col14X && col14Row03 === true) {
        setCurY(row03X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      }     
      
    } else if (curY === row03X) {
      if (curX === col01X && col01Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col02X && col02Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col03X && col03Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col04X && col04Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col05X && col05Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col06X && col06Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col07X && col07Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col08X && col08Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col09X && col09Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col10X && col10Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col11X && col11Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col12X && col12Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col13X && col13Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } else if (curX === col14X && col14Row04 === true) {
        setCurY(row04X);
        setPlaceUpRight("PORTRAIT");
        setUpArrow(true);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(false);
      } 
  
    } else  {
      alert("No more Upward movement")
    }
    navigatorUpArrow ="./images/arrow-orange.png";
    navigatorLeftArrow = "./images/left-arrow.png";
    navigatorRightArrow =  "./images/right-arrow.png"
    navigatorDownArrow = "./images/down-arrow.png";
   
  }

  let moveDown = (event) => {
    setPlaceUpRight("PORTRAIT");
    setUpArrow(false);
    setDownArrow(true);
    setLeftArrow(false);
    setRightArrow(false);
    if (curY === row13X) {
        setCurY(row12X);
    } else if (curY === row12X) {
      setCurY(row11X);
    } else if (curY === row11X) {
      setCurY(row10X);
    } else if (curY === row10X) {
      setCurY(row09X);
    } else if (curY === row09X) {
      setCurY(row08X);
    } else if (curY === row08X) {
      setCurY(row07X);
    } else if (curY === row07X) {
      setCurY(row06X);
    } else if (curY === row06X) {
      setCurY(row05X);
    } else if (curY === row05X) {
      setCurY(row04X);
    } else if (curY === row04X) {
      setCurY(row03X);
    } else if (curY === row03X) {
      setCurY(row02X);
    } else if (curY === row02X) {
      setCurY(row01X);
      setPlaceUpRight("LANDSCAPE");
    } else  {
      alert("No more Downward movement")
    }
    navigatorUpArrow ="./images/up-arrow.png";
    navigatorLeftArrow = "./images/left-arrow.png";
    navigatorRightArrow =  "./images/right-arrow.png"
    navigatorDownArrow = "./images/arrow-orange.png";
  }

  let moveLeft = (event) => {
    if (curX === col14X) {
      if (curY === row01X &&  col13Row01 === true) {
        setCurX(col13X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col13Row02 === true) {
        setCurX(col13X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col13Row03 === true) {
        setCurX(col13X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col13Row04 === true) {
        setCurX(col13X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col13X) {
      if (curY === row01X &&  col12Row01 === true) {
        setCurX(col12X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col12Row02 === true) {
        setCurX(col12X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col12Row03 === true) {
        setCurX(col12X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col12Row04 === true) {
        setCurX(col12X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col12X) {
      if (curY === row01X &&  col11Row01 === true) {
        setCurX(col11X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col11Row02 === true) {
        setCurX(col11X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col11Row03 === true) {
        setCurX(col11X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col11Row04 === true) {
        setCurX(col11X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col11X) {
      if (curY === row01X &&  col10Row01 === true) {
        setCurX(col10X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col10Row02 === true) {
        setCurX(col10X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col10Row03 === true) {
        setCurX(col10X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col10Row04 === true) {
        setCurX(col10X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col10X) {
      if (curY === row01X &&  col09Row01 === true) {
        setCurX(col09X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col09Row02 === true) {
        setCurX(col09X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col09Row03 === true) {
        setCurX(col09X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col09Row04 === true) {
        setCurX(col09X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col09X) {
      if (curY === row01X &&  col08Row01 === true) {
        setCurX(col08X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col08Row02 === true) {
        setCurX(col08X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col08Row03 === true) {
        setCurX(col08X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col08Row04 === true) {
        setCurX(col08X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col08X) {
      if (curY === row01X &&  col07Row01 === true) {
        setCurX(col07X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col07Row02 === true) {
        setCurX(col07X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col07Row03 === true) {
        setCurX(col07X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col07Row04 === true) {
        setCurX(col07X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col07X) {
      if (curY === row01X &&  col06Row01 === true) {
        setCurX(col06X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col06Row02 === true) {
        setCurX(col06X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col06Row03 === true) {
        setCurX(col06X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col06Row04 === true) {
        setCurX(col06X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col06X) {
      if (curY === row01X &&  col05Row01 === true) {
        setCurX(col05X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col05Row02 === true) {
        setCurX(col05X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col05Row03 === true) {
        setCurX(col05X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col05Row04 === true) {
        setCurX(col05X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col05X) {
      if (curY === row01X &&  col04Row01 === true) {
        setCurX(col04X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col04Row02 === true) {
        setCurX(col04X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col04Row03 === true) {
        setCurX(col04X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col04Row04 === true) {
        setCurX(col04X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col04X) {
      if (curY === row01X &&  col03Row01 === true) {
        setCurX(col03X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col03Row02 === true) {
        setCurX(col03X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col03Row03 === true) {
        setCurX(col03X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col03Row04 === true) {
        setCurX(col03X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col03X) {
      if (curY === row01X &&  col02Row01 === true) {
        setCurX(col02X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col02Row02 === true) {
        setCurX(col02X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col02Row03 === true) {
        setCurX(col02X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col02Row04 === true) {
        setCurX(col02X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else if (curX === col02X) {
      if (curY === row01X &&  col01Row01 === true) {
        setCurX(col01X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row02X &&  col01Row02 === true) {
        setCurX(col01X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row03X &&  col01Row03 === true) {
        setCurX(col01X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      } else if (curY === row04X &&  col01Row04 === true) {
        setCurX(col01X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(true);
        setRightArrow(false);
      }
    } else  {
      alert("No more left movement");
    }
    navigatorUpArrow ="./images/up-arrow.png";
    navigatorLeftArrow = "./images/arrow-orange.png";
    navigatorRightArrow =  "./images/right-arrow.png"
    navigatorDownArrow = "./images/down-arrow.png";

  }

  let moveRight = (event) => {
    
    if (curX === col01X) {
      if (curY === row01X &&  col02Row01 === true) {
        setCurX(col02X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col02Row02 === true) {
        setCurX(col02X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col02Row03 === true) {
        setCurX(col02X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col02Row04 === true) {
        setCurX(col02X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }
    } else if (curX === col02X) {
      if (curY === row01X &&  col03Row01 === true) {
        setCurX(col03X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col03Row02 === true) {
        setCurX(col03X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col03Row03 === true) {
        setCurX(col03X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col03Row04 === true) {
        setCurX(col03X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }

    } else if (curX === col03X) {
      if (curY === row01X &&  col04Row01 === true) {
        setCurX(col04X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col04Row02 === true) {
        setCurX(col04X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col04Row03 === true) {
        setCurX(col04X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col04Row04 === true) {
        setCurX(col04X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }
     
    } else if (curX === col04X) {
      if (curY === row01X &&  col05Row01 === true) {
        setCurX(col05X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col05Row02 === true) {
        setCurX(col05X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col05Row03 === true) {
        setCurX(col05X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col05Row04 === true) {
        setCurX(col05X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }
      
    } else if (curX === col05X) {
      if (curY === row01X &&  col06Row01 === true) {
        setCurX(col06X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col06Row02 === true) {
        setCurX(col06X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col06Row03 === true) {
        setCurX(col06X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col06Row04 === true) {
        setCurX(col06X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }
      
    } else if (curX === col06X) {
      if (curY === row01X &&  col07Row01 === true) {
        setCurX(col07X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col07Row02 === true) {
        setCurX(col07X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col07Row03 === true) {
        setCurX(col07X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col07Row04 === true) {
        setCurX(col07X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }
      
    } else if (curX === col07X) {
      if (curY === row01X &&  col08Row01 === true) {
        setCurX(col08X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else  if (curY === row02X &&  col08Row02 === true) {
        setCurX(col08X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col08Row03 === true) {
        setCurX(col08X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col08Row04 === true) {
        setCurX(col08X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }
      
    } else if (curX === col08X) {
      if (curY === row01X &&  col09Row01 === true) {
        setCurX(col09X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col09Row02 === true) {
        setCurX(col09X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col09Row03 === true) {
        setCurX(col09X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col09Row04 === true) {
        setCurX(col09X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }
     
    } else if (curX === col09X) {
      if (curY === row01X &&  col10Row01 === true) {
        setCurX(col10X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col10Row02 === true) {
        setCurX(col10X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col10Row03 === true) {
        setCurX(col10X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col10Row04 === true) {
        setCurX(col10X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }
     
    } else if (curX === col10X) {
      if (curY === row01X &&  col11Row01 === true) {
        setCurX(col11X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col11Row02 === true) {
        setCurX(col11X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col11Row03 === true) {
        setCurX(col11X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col11Row04 === true) {
        setCurX(col11X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }
     
    } else if (curX === col11X) {
      if (curY === row01X &&  col12Row01 === true) {
        setCurX(col12X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col12Row02 === true) {
        setCurX(col12X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col12Row03 === true) {
        setCurX(col12X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col12Row04 === true) {
        setCurX(col12X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }

    } else if (curX === col12X) {
      if (curY === row01X &&  col13Row01 === true) {
        setCurX(col13X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col13Row02 === true) {
        setCurX(col13X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col13Row03 === true) {
        setCurX(col13X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col13Row04=== true) {
        setCurX(col13X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }

    } else if (curX === col13X) {
      if (curY === row01X &&  col14Row01=== true) {
        setCurX(col14X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row02X &&  col14Row02=== true) {
        setCurX(col14X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row03X &&  col14Row03=== true) {
        setCurX(col14X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      } else if (curY === row04X &&  col14Row04=== true) {
        setCurX(col14X);
        setPlaceUpRight("LANDSCAPE");
        setUpArrow(false);
        setDownArrow(false);
        setLeftArrow(false);
        setRightArrow(true);
      }
      
    } else  {
      alert("No more right movement")
    }
    navigatorUpArrow ="./images/up-arrow.png";
    navigatorLeftArrow ="./images/left-arrow.png";
    navigatorRightArrow =  "./images/arrow-orange.png";
    navigatorDownArrow = "./images/down-arrow.png";
  }
 
  const handleKeyDown = (event) => {
    event.preventDefault();
    const key = event.code;
    switch (key) {
        case 'ArrowLeft':
            // left key pressed
            moveLeft();
            break;
        case 'ArrowUp':
            // up key pressed
            moveUp();
            break;
        case 'ArrowRight':
            // right key pressed
            moveRight();
            break;
        case 'ArrowDown':
            // down key pressed
            moveDown();
            break;  
        case 'Space':
          // down key pressed
          setPlacement();
              break;  
    }   
}       
 
   //First- base Row Small Module
   const [baseNaked01, setBaseNaked01] = useState([]);
   const [baseNaked02, setBaseNaked02] = useState([]);
   const [baseNaked03, setBaseNaked03] = useState([]);
   const [baseNaked04, setBaseNaked04] = useState([]);
   const [baseNaked05, setBaseNaked05] = useState([]);
   const [baseNaked06, setBaseNaked06] = useState([]);
   const [baseNaked07, setBaseNaked07] = useState([]);
   const [baseNaked08, setBaseNaked08] = useState([]);
   const [baseNaked09, setBaseNaked09] = useState([]);
   const [baseNaked10, setBaseNaked10] = useState([]);
   const [baseNaked11, setBaseNaked11] = useState([]);
   const [baseNaked12, setBaseNaked12] = useState([]);
   const [baseNaked13, setBaseNaked13] = useState([]);
   const [baseNaked14, setBaseNaked14] = useState([]);

    // 2nd row small modules

  const [row2Naked01, setRow2Naked01] = useState([]);
  const [row2Naked02, setRow2Naked02] = useState([]);
  const [row2Naked03, setRow2Naked03] = useState([]);
  const [row2Naked04, setRow2Naked04] = useState([]);
  const [row2Naked05, setRow2Naked05] = useState([]);
  const [row2Naked06, setRow2Naked06] = useState([]);
  const [row2Naked07, setRow2Naked07] = useState([]);
  const [row2Naked08, setRow2Naked08] = useState([]);
  const [row2Naked09, setRow2Naked09] = useState([]);
  const [row2Naked10, setRow2Naked10] = useState([]);
  const [row2Naked11, setRow2Naked11] = useState([]);
  const [row2Naked12, setRow2Naked12] = useState([]);
  const [row2Naked13, setRow2Naked13] = useState([]);
  const [row2Naked14, setRow2Naked14] = useState([]);

  const [row3Naked01, setRow3Naked01] = useState([]);
  const [row3Naked02, setRow3Naked02] = useState([]);
  const [row3Naked03, setRow3Naked03] = useState([]);
  const [row3Naked04, setRow3Naked04] = useState([]);
  const [row3Naked05, setRow3Naked05] = useState([]);
  const [row3Naked06, setRow3Naked06] = useState([]);
  const [row3Naked07, setRow3Naked07] = useState([]);
  const [row3Naked08, setRow3Naked08] = useState([]);
  const [row3Naked09, setRow3Naked09] = useState([]);
  const [row3Naked10, setRow3Naked10] = useState([]);
  const [row3Naked11, setRow3Naked11] = useState([]);
  const [row3Naked12, setRow3Naked12] = useState([]);
  const [row3Naked13, setRow3Naked13] = useState([]);
  const [row3Naked14, setRow3Naked14] = useState([]);

  const [row4Naked01, setRow4Naked01] = useState([]);
  const [row4Naked02, setRow4Naked02] = useState([]);
  const [row4Naked03, setRow4Naked03] = useState([]);
  const [row4Naked04, setRow4Naked04] = useState([]);
  const [row4Naked05, setRow4Naked05] = useState([]);
  const [row4Naked06, setRow4Naked06] = useState([]);
  const [row4Naked07, setRow4Naked07] = useState([]);
  const [row4Naked08, setRow4Naked08] = useState([]);
  const [row4Naked09, setRow4Naked09] = useState([]);
  const [row4Naked10, setRow4Naked10] = useState([]);
  const [row4Naked11, setRow4Naked11] = useState([]);
  const [row4Naked12, setRow4Naked12] = useState([]);
  const [row4Naked13, setRow4Naked13] = useState([]);
  const [row4Naked14, setRow4Naked14] = useState([]);
  

  // First Row Medium Modules
  const [baseNakedM01, setBaseNakedM01] = useState([]);
  const [baseNakedM02, setBaseNakedM02] = useState([]);
  const [baseNakedM03, setBaseNakedM03] = useState([]);
  const [baseNakedM04, setBaseNakedM04] = useState([]);
  const [baseNakedM05, setBaseNakedM05] = useState([]);
  const [baseNakedM06, setBaseNakedM06] = useState([]);
  const [baseNakedM07, setBaseNakedM07] = useState([]);
  const [baseNakedM08, setBaseNakedM08] = useState([]);
  const [baseNakedM09, setBaseNakedM09] = useState([]);
  const [baseNakedM10, setBaseNakedM10] = useState([]);
  const [baseNakedM11, setBaseNakedM11] = useState([]);
  const [baseNakedM12, setBaseNakedM12] = useState([]);
  const [baseNakedM13, setBaseNakedM13] = useState([]);
  const [baseNakedM14, setBaseNakedM14] = useState([]);

// 2nd row Medium Module

const [row2NakedM01, setRow2NakedM01] = useState([]);
const [row2NakedM02, setRow2NakedM02] = useState([]);
const [row2NakedM03, setRow2NakedM03] = useState([]);
const [row2NakedM04, setRow2NakedM04] = useState([]);
const [row2NakedM05, setRow2NakedM05] = useState([]);
const [row2NakedM06, setRow2NakedM06] = useState([]);
const [row2NakedM07, setRow2NakedM07] = useState([]);
const [row2NakedM08, setRow2NakedM08] = useState([]);
const [row2NakedM09, setRow2NakedM09] = useState([]);
const [row2NakedM10, setRow2NakedM10] = useState([]);
const [row2NakedM11, setRow2NakedM11] = useState([]);
const [row2NakedM12, setRow2NakedM12] = useState([]);
const [row2NakedM13, setRow2NakedM13] = useState([]);
const [row2NakedM14, setRow2NakedM14] = useState([]);

//3rd row Medium

const [row3NakedM01, setRow3NakedM01] = useState([]);
const [row3NakedM02, setRow3NakedM02] = useState([]);
const [row3NakedM03, setRow3NakedM03] = useState([]);
const [row3NakedM04, setRow3NakedM04] = useState([]);
const [row3NakedM05, setRow3NakedM05] = useState([]);
const [row3NakedM06, setRow3NakedM06] = useState([]);
const [row3NakedM07, setRow3NakedM07] = useState([]);
const [row3NakedM08, setRow3NakedM08] = useState([]);
const [row3NakedM09, setRow3NakedM09] = useState([]);
const [row3NakedM10, setRow3NakedM10] = useState([]);
const [row3NakedM11, setRow3NakedM11] = useState([]);
const [row3NakedM12, setRow3NakedM12] = useState([]);
const [row3NakedM13, setRow3NakedM13] = useState([]);
const [row3NakedM14, setRow3NakedM14] = useState([]);

const [baseNakedL01, setBaseNakedL01] = useState([]);
const [baseNakedL02, setBaseNakedL02] = useState([]);
const [baseNakedL03, setBaseNakedL03] = useState([]);
const [baseNakedL04, setBaseNakedL04] = useState([]);
const [baseNakedL05, setBaseNakedL05] = useState([]);
const [baseNakedL06, setBaseNakedL06] = useState([]);
const [baseNakedL07, setBaseNakedL07] = useState([]);
const [baseNakedL08, setBaseNakedL08] = useState([]);
const [baseNakedL09, setBaseNakedL09] = useState([]);
const [baseNakedL10, setBaseNakedL10] = useState([]);
const [baseNakedL11, setBaseNakedL11] = useState([]);
const [baseNakedL12, setBaseNakedL12] = useState([]);
const [baseNakedL13, setBaseNakedL13] = useState([]);
const [baseNakedL14, setBaseNakedL14] = useState([]);

let setPlacement = (event) => {
  if (placeUpRight === "LANDSCAPE") {
    setPlaceUpRight("PORTRAIT");
    setUpArrow(true);
    setDownArrow(false);
    setLeftArrow(false);
    setRightArrow(false);
  } else {
    setPlaceUpRight("LANDSCAPE");
    setUpArrow(false);
    setDownArrow(false);
    setLeftArrow(false);
    setRightArrow(true);
  }

}

const restAndFixRoomAgain = (event) => {
  if (window.confirm("This will re-set all your changes and let you specify Flo space again.") === true) {
    window.location.reload();
  }
}


const reloadc1r1 = (e) => {
	if (RCol1Row1 === 1) {
		setBaseNaked01(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row01X,0.5]} />
            ]
          	)

	} else if (RCol1Row1 === 2) {
		setBaseNaked01(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row01X,0.5]} />
            ]
          	)
	} else if (RCol1Row1 === 3) {
		setBaseNaked01(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row01X,0.5]} />
            ]
          	)
	} else if (RCol1Row1 === 4) {
		setBaseNaked01(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
            ]
          	)
	} else if (RCol1Row1 === 5) {
		setBaseNaked01(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
            ]
          	)
	} else if (RCol1Row1 === 6) {
		setBaseNaked01(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row01X,0.5]} />
            ]
          	)
	} else if (RCol1Row1 === 7) {
		setBaseNaked01(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row01X,0.5]} />
            ]
          	)
	} else if (RCol1Row1 === 8) {
		setBaseNaked01(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
            ]
          	)
	} else if (RCol1Row1 === 9) {
		setBaseNaked01(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
            ]
          	)
	} else if (RCol1Row1 === 10) {
		setBaseNaked01(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row01X,0.5]} />
            ]
          	)
	} else if (RCol1Row1 === 11) {
		 setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
   		
	} else if (RCol1Row1 === 12) {
 		setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	
	} else if (RCol1Row1 === 13) {
 		setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 14) {
 		setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 15) {
			setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 16) {
		setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 17) {
setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 18) {
		setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 19) {
		setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 20) {
setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 11.1) {
 		setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 12.1) {
		setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 13.1) {
setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 14.1) {
setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 15.1) {
setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 16.1) {
setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 17.1) {
setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 18.1) {
setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 19.1) {
setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 20.1) {
setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 21) {
 setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 22) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 23) {
 setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 24) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 25) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 26) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 27) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 28) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 29) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
		]
          )
	} else if (RCol1Row1 === 30) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 21.1) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 22.1) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 23.1) {
 setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 24.1) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 25.1) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 26.1) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 27.1) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 28.1) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 29.1) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
 		]
          )
	} else if (RCol1Row1 === 30.1) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 21.3) {
 setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 22.3) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 23.3) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 24.3) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 25.3) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 26.3) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 27.3) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 28.3) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 29.3) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 30.3) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 21.5) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 22.5) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 23.5) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 24.5) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 25.5) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 26.5) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 27.5) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 28.5) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 29.5) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 30.5) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 21.4) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 22.4) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 23.4) {
 setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 24.4) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 25.4) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 26.4) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 27.4) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 28.4) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 29.4) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 30.4) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 21.6) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 22.6) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 23.6) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 24.6) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 25.6) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 26.6) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 27.6) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 28.6) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 29.6) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 30.6) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 21.8) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 22.8) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 23.8) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 24.8) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 25.8) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 26.8) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 27.8) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 28.8) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 29.8) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 30.8) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 21.9) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 22.9) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 23.9) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 24.9) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 25.9) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 26.9) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 27.9) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 28.9) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 29.9) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 30.9) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	}
}

const reloadc1r2 = (e) => {
	if (RCol1Row2 === 1) {
		setRow2Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row02X,0.5]} />
            ]
          	)

	} else if (RCol1Row2 === 2) {
		setRow2Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row02X,0.5]} />
            ]
          	)
	} else if (RCol1Row2 === 3) {
		setRow2Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row02X,0.5]} />
            ]
          	)
	} else if (RCol1Row2 === 4) {
		setRow2Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
            ]
          	)
	} else if (RCol1Row2 === 5) {
		setRow2Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
            ]
          	)
	} else if (RCol1Row2 === 6) {
		setRow2Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row02X,0.5]} />
            ]
          	)
	} else if (RCol1Row2 === 7) {
		setRow2Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row02X,0.5]} />
            ]
          	)
	} else if (RCol1Row2 === 8) {
		setRow2Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
            ]
          	)
	} else if (RCol1Row2 === 9) {
		setRow2Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
            ]
          	)
	} else if (RCol1Row2 === 10) {
		setRow2Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row02X,0.5]} />
            ]
          	)
	} else if (RCol1Row2 === 11) {
	if (RCol1Row1 < 11) {
		 setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
   	}
	} else if (RCol1Row2 === 12) {
	if (RCol1Row1 < 11) {
 		setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
	}
	} else if (RCol1Row2 === 13) {
	if (RCol1Row1 < 11) {
 		setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
	}
	} else if (RCol1Row2 === 14) {
	if (RCol1Row1 < 11) {
 		setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
	}
	} else if (RCol1Row2 === 15) {
	if (RCol1Row1 < 11) {
			setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
	}
	} else if (RCol1Row2 === 16) {
	if (RCol1Row1 < 11) {
		setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
	}
	} else if (RCol1Row2 === 17) {
if (RCol1Row1 < 11) {
setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol1Row2 === 18) {
if (RCol1Row1 < 11) {
		setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol1Row2 === 19) {
if (RCol1Row1 < 11) {
		setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol1Row2 === 20) {
if (RCol1Row1 < 11) {
setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol1Row2 === 11.1) {
if (RCol1Row1 < 11) {
 		setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol1Row2 === 12.1) {
if (RCol1Row1 < 11) {
		setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol1Row2 === 13.1) {
if (RCol1Row1 < 11) {
setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol1Row2 === 14.1) {
if (RCol1Row1 < 11) {
setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol1Row2 === 15.1) {
if (RCol1Row1 < 11) {
setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol1Row2 === 16.1) {
if (RCol1Row1 < 11) {
setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol1Row2 === 17.1) {
if (RCol1Row1 < 11) {
setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol1Row2 === 18.1) {
if (RCol1Row1 < 11) {
setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol1Row2 === 19.1) {
if (RCol1Row1 < 11) {
setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol1Row2 === 20.1) {
if (RCol1Row1 < 11) {
setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	} 
}

const reloadc1r3 = (e) => {
	if (RCol1Row3 === 1) {
		setRow3Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row03X,0.5]} />
            ]
          	)

	} else if (RCol1Row3 === 2) {
		setRow3Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row03X,0.5]} />
            ]
          	)
	} else if (RCol1Row3 === 3) {
		setRow3Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row03X,0.5]} />
            ]
          	)
	} else if (RCol1Row3 === 4) {
		setRow3Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
            ]
          	)
	} else if (RCol1Row3 === 5) {
		setRow3Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
            ]
          	)
	} else if (RCol1Row3 === 6) {
		setRow3Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row03X,0.5]} />
            ]
          	)
	} else if (RCol1Row3 === 7) {
		setRow3Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row03X,0.5]} />
            ]
          	)
	} else if (RCol1Row3 === 8) {
		setRow3Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
            ]
          	)
	} else if (RCol1Row3 === 9) {
		setRow3Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
            ]
          	)
	} else if (RCol1Row3 === 10) {
		setRow3Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row03X,0.5]} />
            ]
          	)
	} else if (RCol1Row3 === 11) {
	if (RCol1Row2 < 11 || RCol1Row1 > 10) {
		 setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
   	}
	} else if (RCol1Row3 === 12) {
	if (RCol1Row2 < 11 || RCol1Row1 > 10) {
 		setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
	}
	} else if (RCol1Row3 === 13) {
	if (RCol1Row2 < 11 || RCol1Row1 > 10) {
 		setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
	}
	} else if (RCol1Row3 === 14) {
	if (RCol1Row2 < 11 || RCol1Row1 > 10) {
 		setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
	}
	} else if (RCol1Row3 === 15) {
	if (RCol1Row2 < 11 || RCol1Row1 > 10) {
			setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
	}
	} else if (RCol1Row3 === 16) {
	if (RCol1Row2 < 11 || RCol1Row1 > 10) {
		setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
	}
	} else if (RCol1Row3 === 17) {
if (RCol1Row2 < 11 || RCol1Row1 > 10) {
setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol1Row3 === 18) {
if (RCol1Row2 < 11 || RCol1Row1 > 10) {
		setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol1Row3 === 19) {
if (RCol1Row2 < 11 || RCol1Row1 > 10) {
		setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol1Row3 === 20) {
if (RCol1Row2 < 11 || RCol1Row1 > 10) {
setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol1Row3 === 11.1) {
if (RCol1Row2 < 11 || RCol1Row1 > 10) {
 		setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col01X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol1Row3 === 12.1) {
if (RCol1Row2 < 11 || RCol1Row1 > 10) {
		setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col01X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol1Row3 === 13.1) {
if (RCol1Row2 < 11 || RCol1Row1 > 10) {
setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col01X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol1Row3 === 14.1) {
if (RCol1Row2 < 11 || RCol1Row1 > 10) {
setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol1Row3 === 15.1) {
if (RCol1Row2 < 11 || RCol1Row1 > 10) {
setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol1Row3 === 16.1) {
if (RCol1Row2 < 11 || RCol1Row1 > 10) {
setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col01X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol1Row3 === 17.1) {
if (RCol1Row2 < 11 || RCol1Row1 > 10) {
setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol1Row3 === 18.1) {
if (RCol1Row2 < 11 || RCol1Row1 > 10) {
setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol1Row3 === 19.1) {
if (RCol1Row2 < 11 || RCol1Row1 > 10) {
setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol1Row3 === 20.1) {
if (RCol1Row2 < 11 || RCol1Row1 > 10) {
setRow3NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
            ]
          )
}
	} 
}

const reloadc1r4 = (e) => {
	if (RCol1Row4 === 1) {
		setRow4Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row04X,0.5]} />
            ]
          	)

	} else if (RCol1Row4 === 2) {
		setRow4Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col01X,row04X,0.5]} />
            ]
          	)
	} else if (RCol1Row4 === 3) {
		setRow4Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row04X,0.5]} />
            ]
          	)
	} else if (RCol1Row4 === 4) {
		setRow4Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row04X,0.5]} />
            ]
          	)
	} else if (RCol1Row4 === 5) {
		setRow4Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
            ]
          	)
	} else if (RCol1Row4 === 6) {
		setRow4Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col01X,row04X,0.5]} />
            ]
          	)
	} else if (RCol1Row4 === 7) {
		setRow4Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col01X,row04X,0.5]} />
            ]
          	)
	} else if (RCol1Row4 === 8) {
		setRow4Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
            ]
          	)
	} else if (RCol1Row4 === 9) {
		setRow4Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
            ]
          	)
	} else if (RCol1Row4 === 10) {
		setRow4Naked01(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col01X,row04X,0.5]} />
            ]
          	)
	} 
}

const reloadc2r1 = (e) => {
	if (RCol2Row1 === 1) {
		setBaseNaked02(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row01X,0.5]} />
            ]
          	)

	} else if (RCol2Row1 === 2) {
		setBaseNaked02(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row01X,0.5]} />
            ]
          	)
	} else if (RCol2Row1 === 3) {
		setBaseNaked02(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row01X,0.5]} />
            ]
          	)
	} else if (RCol2Row1 === 4) {
		setBaseNaked02(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
            ]
          	)
	} else if (RCol2Row1 === 5) {
		setBaseNaked02(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
            ]
          	)
	} else if (RCol2Row1 === 6) {
		setBaseNaked02(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row01X,0.5]} />
            ]
          	)
	} else if (RCol2Row1 === 7) {
		setBaseNaked02(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row01X,0.5]} />
            ]
          	)
	} else if (RCol2Row1 === 8) {
		setBaseNaked02(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
            ]
          	)
	} else if (RCol2Row1 === 9) {
		setBaseNaked02(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
            ]
          	)
	} else if (RCol2Row1 === 10) {
		setBaseNaked02(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row01X,0.5]} />
            ]
          	)
	} else if (RCol2Row1 === 11) {
		 setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
   
	} else if (RCol2Row1 === 12) {
 		setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 13) {
 		setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 14) {
 		setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 15) {
			setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 16) {
		setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 17) {
setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 18) {
		setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 19) {
		setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 20) {
setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 11.1) {
 		setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 12.1) {
		setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 13.1) {
setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 14.1) {
setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 15.1) {
setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 16.1) {
setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 17.1) {
setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 18.1) {
setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 19.1) {
setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 20.1) {
setBaseNakedM02(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 21) {
 setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 22) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 23) {
 setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 24) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 25) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 26) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 27) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 28) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 29) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
		]
          )
	} else if (RCol2Row1 === 30) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 21.1) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 22.1) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 23.1) {
 setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 24.1) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 25.1) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 26.1) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 27.1) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 28.1) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 29.1) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
 		]
          )
	} else if (RCol2Row1 === 30.1) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 21.3) {
 setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 22.3) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 23.3) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 24.3) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 25.3) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 26.3) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 27.3) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 28.3) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 29.3) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 30.3) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 21.5) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 22.5) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 23.5) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 24.5) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 25.5) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 26.5) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 27.5) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 28.5) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 29.5) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 30.5) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 21.4) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 22.4) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 23.4) {
 setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 24.4) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 25.4) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 26.4) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 27.4) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 28.4) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 29.4) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 30.4) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 21.6) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 22.6) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 23.6) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 24.6) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 25.6) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 26.6) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 27.6) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 28.6) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 29.6) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 30.6) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 21.8) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 22.8) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 23.8) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 24.8) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 25.8) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 26.8) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 27.8) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 28.8) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 29.8) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 30.8) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 21.9) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 22.9) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 23.9) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 24.9) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 25.9) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 26.9) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 27.9) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 28.9) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 29.9) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	} else if (RCol2Row1 === 30.9) {
setBaseNakedL02(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
            ]
          )
	}
}
const reloadc2r2 = (e) => {
	if (RCol2Row2 === 1) {
		setRow2Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row02X,0.5]} />
            ]
          	)

	} else if (RCol2Row2 === 2) {
		setRow2Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row02X,0.5]} />
            ]
          	)
	} else if (RCol2Row2 === 3) {
		setRow2Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row02X,0.5]} />
            ]
          	)
	} else if (RCol2Row2 === 4) {
		setRow2Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
            ]
          	)
	} else if (RCol2Row2 === 5) {
		setRow2Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
            ]
          	)
	} else if (RCol2Row2 === 6) {
		setRow2Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row02X,0.5]} />
            ]
          	)
	} else if (RCol2Row2 === 7) {
		setRow2Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row02X,0.5]} />
            ]
          	)
	} else if (RCol2Row2 === 8) {
		setRow2Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
            ]
          	)
	} else if (RCol2Row2 === 9) {
		setRow2Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
            ]
          	)
	} else if (RCol2Row2 === 10) {
		setRow2Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row02X,0.5]} />
            ]
          	)
	} else if (RCol2Row2 === 11) {
	if (RCol2Row1 < 11) {
		 setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
   }
	} else if (RCol2Row2 === 12) {
	if (RCol2Row1 < 11) {
 		setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol2Row2 === 13) {
	if (RCol2Row1 < 11) {
 		setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol2Row2 === 14) {
	if (RCol2Row1 < 11) {
 		setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol2Row2 === 15) {
	if (RCol2Row1 < 11) {
			setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol2Row2 === 16) {
	if (RCol2Row1 < 11) {
		setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol2Row2 === 17) {
	if (RCol2Row1 < 11) {
setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol2Row2 === 18) {
	if (RCol2Row1 < 11) {
		setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol2Row2 === 19) {
	if (RCol2Row1 < 11) {
		setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol2Row2 === 20) {
	if (RCol2Row1 < 11) {
setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol2Row2 === 11.1) {
	if (RCol2Row1 < 11) {
 		setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col02X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol2Row2 === 12.1) {
	if (RCol2Row1 < 11) {
		setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col02X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol2Row2 === 13.1) {
	if (RCol2Row1 < 11) {
setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col02X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol2Row2 === 14.1) {
	if (RCol2Row1 < 11) {
setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol2Row2 === 15.1) {
	if (RCol2Row1 < 11) {
setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol2Row2 === 16.1) {
	if (RCol2Row1 < 11) {
setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col02X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol2Row2 === 17.1) {
	if (RCol2Row1 < 11) {
setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol2Row2 === 18.1) {
	if (RCol2Row1 < 11) {
setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol2Row2 === 19.1) {
	if (RCol2Row1 < 11) {
setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol2Row2 === 20.1) {
	if (RCol2Row1 < 11) {
setRow2NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
            ]
          )
}
	} 
}

const reloadc2r3 = (e) => {
	if (RCol2Row3 === 1) {
		setRow3Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row03X,0.5]} />
            ]
          	)

	} else if (RCol2Row3 === 2) {
		setRow3Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row03X,0.5]} />
            ]
          	)
	} else if (RCol2Row3 === 3) {
		setRow3Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row03X,0.5]} />
            ]
          	)
	} else if (RCol2Row3 === 4) {
		setRow3Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
            ]
          	)
	} else if (RCol2Row3 === 5) {
		setRow3Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
            ]
          	)
	} else if (RCol2Row3 === 6) {
		setRow3Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row03X,0.5]} />
            ]
          	)
	} else if (RCol2Row3 === 7) {
		setRow3Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row03X,0.5]} />
            ]
          	)
	} else if (RCol2Row3 === 8) {
		setRow3Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
            ]
          	)
	} else if (RCol2Row3 === 9) {
		setRow3Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
            ]
          	)
	} else if (RCol2Row3 === 10) {
		setRow3Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row03X,0.5]} />
            ]
          	)
	} else if (RCol2Row3 === 11) {
	if (RCol2Row2 < 11 || RCol2Row1 > 10) {

		 setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
   }
	} else if (RCol2Row3 === 12) {
	if (RCol2Row2 < 11 || RCol2Row1 > 10) {
 		setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol2Row3 === 13) {
	if (RCol2Row2 < 11 || RCol2Row1 > 10) {
 		setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol2Row3 === 14) {
	if (RCol2Row2 < 11 || RCol2Row1 > 10) {
 		setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol2Row3 === 15) {
	if (RCol2Row2 < 11 || RCol2Row1 > 10) {
			setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol2Row3 === 16) {
	if (RCol2Row2 < 11 || RCol2Row1 > 10) {
		setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol2Row3 === 17) {
	if (RCol2Row2 < 11 || RCol2Row1 > 10) {
setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol2Row3 === 18) {
	if (RCol2Row2 < 11 || RCol2Row1 > 10) {
		setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol2Row3 === 19) {
	if (RCol2Row2 < 11 || RCol2Row1 > 10) {
		setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol2Row3 === 20) {
	if (RCol2Row2 < 11 || RCol2Row1 > 10) {
setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol2Row3 === 11.1) {
	if (RCol2Row2 < 11 || RCol2Row1 > 10) {
 		setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col02X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol2Row3 === 12.1) {
	if (RCol2Row2 < 11 || RCol2Row1 > 10) {
		setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col02X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol2Row3 === 13.1) {
	if (RCol2Row2 < 11 || RCol2Row1 > 10) {
setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col02X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol2Row3 === 14.1) {
	if (RCol2Row2 < 11 || RCol2Row1 > 10) {
setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol2Row3 === 15.1) {
	if (RCol2Row2 < 11 || RCol2Row1 > 10) {
setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol2Row3 === 16.1) {
	if (RCol2Row2 < 11 || RCol2Row1 > 10) {
setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col02X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol2Row3 === 17.1) {
	if (RCol2Row2 < 11 || RCol2Row1 > 10) {
setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol2Row3 === 18.1) {
	if (RCol2Row2 < 11 || RCol2Row1 > 10) {
setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol2Row3 === 19.1) {
	if (RCol2Row2 < 11 || RCol2Row1 > 10) {
setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol2Row3 === 20.1) {
	if (RCol2Row2 < 11 || RCol2Row1 > 10) {
setRow3NakedM02(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
            ]
          )
}
	} 
}

const reloadc2r4 = (e) => {
	if (RCol2Row4 === 1) {
		setRow4Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row04X,0.5]} />
            ]
          	)

	} else if (RCol2Row4 === 2) {
		setRow4Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col02X,row04X,0.5]} />
            ]
          	)
	} else if (RCol2Row4 === 3) {
		setRow4Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row04X,0.5]} />
            ]
          	)
	} else if (RCol2Row4 === 4) {
		setRow4Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row04X,0.5]} />
            ]
          	)
	} else if (RCol2Row4 === 5) {
		setRow4Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
            ]
          	)
	} else if (RCol2Row4 === 6) {
		setRow4Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col02X,row04X,0.5]} />
            ]
          	)
	} else if (RCol2Row4 === 7) {
		setRow4Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col02X,row04X,0.5]} />
            ]
          	)
	} else if (RCol2Row4 === 8) {
		setRow4Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
            ]
          	)
	} else if (RCol2Row4 === 9) {
		setRow4Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
            ]
          	)
	} else if (RCol2Row4 === 10) {
		setRow4Naked02(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col02X,row04X,0.5]} />
            ]
          	)
	} 
}
const reloadc3r1 = (e) => {
	if (RCol3Row1 === 1) {
		setBaseNaked03(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row01X,0.5]} />
            ]
          	)

	} else if (RCol3Row1 === 2) {
		setBaseNaked03(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row01X,0.5]} />
            ]
          	)
	} else if (RCol3Row1 === 3) {
		setBaseNaked03(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row01X,0.5]} />
            ]
          	)
	} else if (RCol3Row1 === 4) {
		setBaseNaked03(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
            ]
          	)
	} else if (RCol3Row1 === 5) {
		setBaseNaked03(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
            ]
          	)
	} else if (RCol3Row1 === 6) {
		setBaseNaked03(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row01X,0.5]} />
            ]
          	)
	} else if (RCol3Row1 === 7) {
		setBaseNaked03(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row01X,0.5]} />
            ]
          	)
	} else if (RCol3Row1 === 8) {
		setBaseNaked03(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
            ]
          	)
	} else if (RCol3Row1 === 9) {
		setBaseNaked03(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
            ]
          	)
	} else if (RCol3Row1 === 10) {
		setBaseNaked03(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row01X,0.5]} />
            ]
          	)
	} else if (RCol3Row1 === 11) {
		 setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
   
	} else if (RCol3Row1 === 12) {
 		setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 13) {
 		setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 14) {
 		setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 15) {
			setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 16) {
		setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 17) {
setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 18) {
		setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 19) {
		setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 20) {
setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 11.1) {
 		setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 12.1) {
		setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 13.1) {
setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 14.1) {
setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 15.1) {
setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 16.1) {
setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 17.1) {
setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 18.1) {
setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 19.1) {
setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 20.1) {
setBaseNakedM03(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 21) {
 setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 22) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 23) {
 setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 24) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 25) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 26) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 27) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 28) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 29) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
		]
          )
	} else if (RCol3Row1 === 30) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 21.1) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 22.1) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 23.1) {
 setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 24.1) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 25.1) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 26.1) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 27.1) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 28.1) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 29.1) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
 		]
          )
	} else if (RCol3Row1 === 30.1) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 21.3) {
 setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 22.3) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 23.3) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 24.3) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 25.3) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 26.3) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 27.3) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 28.3) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 29.3) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 30.3) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 21.5) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 22.5) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 23.5) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 24.5) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 25.5) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 26.5) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 27.5) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 28.5) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 29.5) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 30.5) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 21.4) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 22.4) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 23.4) {
 setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 24.4) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 25.4) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 26.4) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 27.4) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 28.4) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 29.4) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 30.4) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 21.6) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 22.6) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 23.6) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 24.6) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 25.6) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 26.6) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 27.6) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 28.6) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 29.6) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 30.6) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 21.8) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 22.8) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 23.8) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 24.8) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 25.8) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 26.8) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 27.8) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 28.8) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 29.8) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 30.8) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 21.9) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 22.9) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 23.9) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 24.9) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 25.9) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 26.9) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 27.9) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 28.9) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 29.9) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	} else if (RCol3Row1 === 30.9) {
setBaseNakedL03(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
            ]
          )
	}
}

const reloadc3r2 = (e) => {
	if (RCol3Row2 === 1) {
		setRow2Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row02X,0.5]} />
            ]
          	)

	} else if (RCol3Row2 === 2) {
		setRow2Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row02X,0.5]} />
            ]
          	)
	} else if (RCol3Row2 === 3) {
		setRow2Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row02X,0.5]} />
            ]
          	)
	} else if (RCol3Row2 === 4) {
		setRow2Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
            ]
          	)
	} else if (RCol3Row2 === 5) {
		setRow2Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
            ]
          	)
	} else if (RCol3Row2 === 6) {
		setRow2Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row02X,0.5]} />
            ]
          	)
	} else if (RCol3Row2 === 7) {
		setRow2Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row02X,0.5]} />
            ]
          	)
	} else if (RCol3Row2 === 8) {
		setRow2Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
            ]
          	)
	} else if (RCol3Row2 === 9) {
		setRow2Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
            ]
          	)
	} else if (RCol3Row2 === 10) {
		setRow2Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row02X,0.5]} />
            ]
          	)
	} else if (RCol3Row2 === 11) {
	if (RCol3Row1 < 11) {
		 setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
   }
	} else if (RCol3Row2 === 12) {
	if (RCol3Row1 < 11) {
 		setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol3Row2 === 13) {
	if (RCol3Row1 < 11) {
 		setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol3Row2 === 14) {
	if (RCol3Row1 < 11) {
 		setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol3Row2 === 15) {
	if (RCol3Row1 < 11) {
			setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol3Row2 === 16) {
	if (RCol3Row1 < 11) {
		setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol3Row2 === 17) {
	if (RCol3Row1 < 11) {
setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol3Row2 === 18) {
	if (RCol3Row1 < 11) {
		setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol3Row2 === 19) {
	if (RCol3Row1 < 11) {
		setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol3Row2 === 20) {
	if (RCol3Row1 < 11) {
setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol3Row2 === 11.1) {
	if (RCol3Row1 < 11) {
 		setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col03X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol3Row2 === 12.1) {
	if (RCol3Row1 < 11) {
		setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col03X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol3Row2 === 13.1) {
	if (RCol3Row1 < 11) {
setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col03X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol3Row2 === 14.1) {
	if (RCol3Row1 < 11) {
setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol3Row2 === 15.1) {
	if (RCol3Row1 < 11) {
setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol3Row2 === 16.1) {
	if (RCol3Row1 < 11) {
setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col03X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol3Row2 === 17.1) {
	if (RCol3Row1 < 11) {
setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol3Row2 === 18.1) {
	if (RCol3Row1 < 11) {
setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol3Row2 === 19.1) {
	if (RCol3Row1 < 11) {
setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol3Row2 === 20.1) {
	if (RCol3Row1 < 11) {
setRow2NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
            ]
          )
}
	} 
}

const reloadc3r3 = (e) => {
	if (RCol3Row3 === 1) {
		setRow3Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row03X,0.5]} />
            ]
          	)

	} else if (RCol3Row3 === 2) {
		setRow3Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row03X,0.5]} />
            ]
          	)
	} else if (RCol3Row3 === 3) {
		setRow3Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row03X,0.5]} />
            ]
          	)
	} else if (RCol3Row3 === 4) {
		setRow3Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
            ]
          	)
	} else if (RCol3Row3 === 5) {
		setRow3Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
            ]
          	)
	} else if (RCol3Row3 === 6) {
		setRow3Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row03X,0.5]} />
            ]
          	)
	} else if (RCol3Row3 === 7) {
		setRow3Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row03X,0.5]} />
            ]
          	)
	} else if (RCol3Row3 === 8) {
		setRow3Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
            ]
          	)
	} else if (RCol3Row3 === 9) {
		setRow3Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
            ]
          	)
	} else if (RCol3Row3 === 10) {
		setRow3Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row03X,0.5]} />
            ]
          	)
	} else if (RCol3Row3 === 11) {
	if (RCol3Row2 < 11 || RCol3Row1 > 10) {

		 setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
   }
	} else if (RCol3Row3 === 12) {
	if (RCol3Row2 < 11 || RCol3Row1 > 10) {
 		setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol3Row3 === 13) {
	if (RCol3Row2 < 11 || RCol3Row1 > 10) {
 		setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol3Row3 === 14) {
	if (RCol3Row2 < 11 || RCol3Row1 > 10) {
 		setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol3Row3 === 15) {
	if (RCol3Row2 < 11 || RCol3Row1 > 10) {
			setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol3Row3 === 16) {
	if (RCol3Row2 < 11 || RCol3Row1 > 10) {
		setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol3Row3 === 17) {
	if (RCol3Row2 < 11 || RCol3Row1 > 10) {
setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol3Row3 === 18) {
	if (RCol3Row2 < 11 || RCol3Row1 > 10) {
		setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol3Row3 === 19) {
	if (RCol3Row2 < 11 || RCol3Row1 > 10) {
		setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol3Row3 === 20) {
	if (RCol3Row2 < 11 || RCol3Row1 > 10) {
setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol3Row3 === 11.1) {
	if (RCol3Row2 < 11 || RCol3Row1 > 10) {
 		setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col03X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol3Row3 === 12.1) {
	if (RCol3Row2 < 11 || RCol3Row1 > 10) {
		setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col03X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol3Row3 === 13.1) {
	if (RCol3Row2 < 11 || RCol3Row1 > 10) {
setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col03X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol3Row3 === 14.1) {
	if (RCol3Row2 < 11 || RCol3Row1 > 10) {
setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol3Row3 === 15.1) {
	if (RCol3Row2 < 11 || RCol3Row1 > 10) {
setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol3Row3 === 16.1) {
	if (RCol3Row2 < 11 || RCol3Row1 > 10) {
setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col03X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol3Row3 === 17.1) {
	if (RCol3Row2 < 11 || RCol3Row1 > 10) {
setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol3Row3 === 18.1) {
	if (RCol3Row2 < 11 || RCol3Row1 > 10) {
setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol3Row3 === 19.1) {
	if (RCol3Row2 < 11 || RCol3Row1 > 10) {
setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol3Row3 === 20.1) {
	if (RCol3Row2 < 11 || RCol3Row1 > 10) {
setRow3NakedM03(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
            ]
          )
}
	} 
}
const reloadc3r4 = (e) => {
	if (RCol3Row4 === 1) {
		setRow4Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row04X,0.5]} />
            ]
          	)

	} else if (RCol3Row4 === 2) {
		setRow4Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col03X,row04X,0.5]} />
            ]
          	)
	} else if (RCol3Row4 === 3) {
		setRow4Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row04X,0.5]} />
            ]
          	)
	} else if (RCol3Row4 === 4) {
		setRow4Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row04X,0.5]} />
            ]
          	)
	} else if (RCol3Row4 === 5) {
		setRow4Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
            ]
          	)
	} else if (RCol3Row4 === 6) {
		setRow4Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col03X,row04X,0.5]} />
            ]
          	)
	} else if (RCol3Row4 === 7) {
		setRow4Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col03X,row04X,0.5]} />
            ]
          	)
	} else if (RCol3Row4 === 8) {
		setRow4Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
            ]
          	)
	} else if (RCol3Row4 === 9) {
		setRow4Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
            ]
          	)
	} else if (RCol3Row4 === 10) {
		setRow4Naked03(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col03X,row04X,0.5]} />
            ]
          	)
	} 
}
const reloadc4r1 = (e) => {
	if (RCol4Row1 === 1) {
		setBaseNaked04(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row01X,0.5]} />
            ]
          	)

	} else if (RCol4Row1 === 2) {
		setBaseNaked04(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row01X,0.5]} />
            ]
          	)
	} else if (RCol4Row1 === 3) {
		setBaseNaked04(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row01X,0.5]} />
            ]
          	)
	} else if (RCol4Row1 === 4) {
		setBaseNaked04(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
            ]
          	)
	} else if (RCol4Row1 === 5) {
		setBaseNaked04(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
            ]
          	)
	} else if (RCol4Row1 === 6) {
		setBaseNaked04(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row01X,0.5]} />
            ]
          	)
	} else if (RCol4Row1 === 7) {
		setBaseNaked04(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row01X,0.5]} />
            ]
          	)
	} else if (RCol4Row1 === 8) {
		setBaseNaked04(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
            ]
          	)
	} else if (RCol4Row1 === 9) {
		setBaseNaked04(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
            ]
          	)
	} else if (RCol4Row1 === 10) {
		setBaseNaked04(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row01X,0.5]} />
            ]
          	)
	} else if (RCol4Row1 === 11) {
		 setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
   
	} else if (RCol4Row1 === 12) {
 		setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 13) {
 		setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 14) {
 		setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 15) {
			setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 16) {
		setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 17) {
setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 18) {
		setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 19) {
		setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 20) {
setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 11.1) {
 		setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 12.1) {
		setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 13.1) {
setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 14.1) {
setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 15.1) {
setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 16.1) {
setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 17.1) {
setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 18.1) {
setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 19.1) {
setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 20.1) {
setBaseNakedM04(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 21) {
 setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 22) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 23) {
 setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 24) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 25) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 26) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 27) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 28) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 29) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
		]
          )
	} else if (RCol4Row1 === 30) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 21.1) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 22.1) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 23.1) {
 setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 24.1) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 25.1) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 26.1) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 27.1) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 28.1) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 29.1) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
 		]
          )
	} else if (RCol4Row1 === 30.1) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 21.3) {
 setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 22.3) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 23.3) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 24.3) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 25.3) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 26.3) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 27.3) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 28.3) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 29.3) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 30.3) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 21.5) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 22.5) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 23.5) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 24.5) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 25.5) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 26.5) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 27.5) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 28.5) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 29.5) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 30.5) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 21.4) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 22.4) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 23.4) {
 setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 24.4) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 25.4) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 26.4) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 27.4) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 28.4) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 29.4) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 30.4) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 21.6) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 22.6) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 23.6) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 24.6) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 25.6) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 26.6) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 27.6) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 28.6) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 29.6) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 30.6) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 21.8) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 22.8) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 23.8) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 24.8) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 25.8) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 26.8) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 27.8) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 28.8) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 29.8) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 30.8) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 21.9) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 22.9) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 23.9) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 24.9) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 25.9) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 26.9) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 27.9) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 28.9) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 29.9) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	} else if (RCol4Row1 === 30.9) {
setBaseNakedL04(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
            ]
          )
	}
}
const reloadc4r2 = (e) => {
	if (RCol4Row2 === 1) {
		setRow2Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row02X,0.5]} />
            ]
          	)

	} else if (RCol4Row2 === 2) {
		setRow2Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row02X,0.5]} />
            ]
          	)
	} else if (RCol4Row2 === 3) {
		setRow2Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row02X,0.5]} />
            ]
          	)
	} else if (RCol4Row2 === 4) {
		setRow2Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
            ]
          	)
	} else if (RCol4Row2 === 5) {
		setRow2Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
            ]
          	)
	} else if (RCol4Row2 === 6) {
		setRow2Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row02X,0.5]} />
            ]
          	)
	} else if (RCol4Row2 === 7) {
		setRow2Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row02X,0.5]} />
            ]
          	)
	} else if (RCol4Row2 === 8) {
		setRow2Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
            ]
          	)
	} else if (RCol4Row2 === 9) {
		setRow2Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
            ]
          	)
	} else if (RCol4Row2 === 10) {
		setRow2Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row02X,0.5]} />
            ]
          	)
	} else if (RCol4Row2 === 11) {
	if (RCol4Row1 < 11) {
		 setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
   }
	} else if (RCol4Row2 === 12) {
	if (RCol4Row1 < 11) {
 		setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol4Row2 === 13) {
	if (RCol4Row1 < 11) {
 		setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol4Row2 === 14) {
	if (RCol4Row1 < 11) {
 		setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol4Row2 === 15) {
	if (RCol4Row1 < 11) {
			setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol4Row2 === 16) {
	if (RCol4Row1 < 11) {
		setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol4Row2 === 17) {
	if (RCol4Row1 < 11) {
setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol4Row2 === 18) {
	if (RCol4Row1 < 11) {
		setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol4Row2 === 19) {
	if (RCol4Row1 < 11) {
		setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol4Row2 === 20) {
	if (RCol4Row1 < 11) {
setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol4Row2 === 11.1) {
	if (RCol4Row1 < 11) {
 		setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col04X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol4Row2 === 12.1) {
	if (RCol4Row1 < 11) {
		setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col04X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol4Row2 === 13.1) {
	if (RCol4Row1 < 11) {
setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col04X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol4Row2 === 14.1) {
	if (RCol4Row1 < 11) {
setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol4Row2 === 15.1) {
	if (RCol4Row1 < 11) {
setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol4Row2 === 16.1) {
	if (RCol4Row1 < 11) {
setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col04X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol4Row2 === 17.1) {
	if (RCol4Row1 < 11) {
setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol4Row2 === 18.1) {
	if (RCol4Row1 < 11) {
setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol4Row2 === 19.1) {
	if (RCol4Row1 < 11) {
setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol4Row2 === 20.1) {
	if (RCol4Row1 < 11) {
setRow2NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
            ]
          )
}
	} 
}
const reloadc4r3 = (e) => {
	if (RCol4Row3 === 1) {
		setRow3Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row03X,0.5]} />
            ]
          	)

	} else if (RCol4Row3 === 2) {
		setRow3Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row03X,0.5]} />
            ]
          	)
	} else if (RCol4Row3 === 3) {
		setRow3Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row03X,0.5]} />
            ]
          	)
	} else if (RCol4Row3 === 4) {
		setRow3Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
            ]
          	)
	} else if (RCol4Row3 === 5) {
		setRow3Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
            ]
          	)
	} else if (RCol4Row3 === 6) {
		setRow3Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row03X,0.5]} />
            ]
          	)
	} else if (RCol4Row3 === 7) {
		setRow3Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row03X,0.5]} />
            ]
          	)
	} else if (RCol4Row3 === 8) {
		setRow3Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
            ]
          	)
	} else if (RCol4Row3 === 9) {
		setRow3Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
            ]
          	)
	} else if (RCol4Row3 === 10) {
		setRow3Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row03X,0.5]} />
            ]
          	)
	} else if (RCol4Row3 === 11) {
	if (RCol4Row2 < 11 || RCol4Row1 > 10) {

		 setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
   }
	} else if (RCol4Row3 === 12) {
	if (RCol4Row2 < 11 || RCol4Row1 > 10) {
 		setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol4Row3 === 13) {
	if (RCol4Row2 < 11 || RCol4Row1 > 10) {
 		setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol4Row3 === 14) {
	if (RCol4Row2 < 11 || RCol4Row1 > 10) {
 		setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol4Row3 === 15) {
	if (RCol4Row2 < 11 || RCol4Row1 > 10) {
			setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol4Row3 === 16) {
	if (RCol4Row2 < 11 || RCol4Row1 > 10) {
		setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol4Row3 === 17) {
	if (RCol4Row2 < 11 || RCol4Row1 > 10) {
setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol4Row3 === 18) {
	if (RCol4Row2 < 11 || RCol4Row1 > 10) {
		setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol4Row3 === 19) {
	if (RCol4Row2 < 11 || RCol4Row1 > 10) {
		setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol4Row3 === 20) {
	if (RCol4Row2 < 11 || RCol4Row1 > 10) {
setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol4Row3 === 11.1) {
	if (RCol4Row2 < 11 || RCol4Row1 > 10) {
 		setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col04X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol4Row3 === 12.1) {
	if (RCol4Row2 < 11 || RCol4Row1 > 10) {
		setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col04X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol4Row3 === 13.1) {
	if (RCol4Row2 < 11 || RCol4Row1 > 10) {
setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col04X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol4Row3 === 14.1) {
	if (RCol4Row2 < 11 || RCol4Row1 > 10) {
setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol4Row3 === 15.1) {
	if (RCol4Row2 < 11 || RCol4Row1 > 10) {
setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol4Row3 === 16.1) {
	if (RCol4Row2 < 11 || RCol4Row1 > 10) {
setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col04X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol4Row3 === 17.1) {
	if (RCol4Row2 < 11 || RCol4Row1 > 10) {
setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol4Row3 === 18.1) {
	if (RCol4Row2 < 11 || RCol4Row1 > 10) {
setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol4Row3 === 19.1) {
	if (RCol4Row2 < 11 || RCol4Row1 > 10) {
setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol4Row3 === 20.1) {
	if (RCol4Row2 < 11 || RCol4Row1 > 10) {
setRow3NakedM04(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
            ]
          )
}
	} 
}

const reloadc4r4 = (e) => {
	if (RCol4Row4 === 1) {
		setRow4Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row04X,0.5]} />
            ]
          	)

	} else if (RCol4Row4 === 2) {
		setRow4Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col04X,row04X,0.5]} />
            ]
          	)
	} else if (RCol4Row4 === 3) {
		setRow4Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row04X,0.5]} />
            ]
          	)
	} else if (RCol4Row4 === 4) {
		setRow4Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row04X,0.5]} />
            ]
          	)
	} else if (RCol4Row4 === 5) {
		setRow4Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
            ]
          	)
	} else if (RCol4Row4 === 6) {
		setRow4Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col04X,row04X,0.5]} />
            ]
          	)
	} else if (RCol4Row4 === 7) {
		setRow4Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col04X,row04X,0.5]} />
            ]
          	)
	} else if (RCol4Row4 === 8) {
		setRow4Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
            ]
          	)
	} else if (RCol4Row4 === 9) {
		setRow4Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
            ]
          	)
	} else if (RCol4Row4 === 10) {
		setRow4Naked04(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col04X,row04X,0.5]} />
            ]
          	)
	} 
}

const reloadc5r1 = (e) => {
	if (RCol5Row1 === 1) {
		setBaseNaked05(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row01X,0.5]} />
            ]
          	)

	} else if (RCol5Row1 === 2) {
		setBaseNaked05(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row01X,0.5]} />
            ]
          	)
	} else if (RCol5Row1 === 3) {
		setBaseNaked05(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row01X,0.5]} />
            ]
          	)
	} else if (RCol5Row1 === 4) {
		setBaseNaked05(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
            ]
          	)
	} else if (RCol5Row1 === 5) {
		setBaseNaked05(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
            ]
          	)
	} else if (RCol5Row1 === 6) {
		setBaseNaked05(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row01X,0.5]} />
            ]
          	)
	} else if (RCol5Row1 === 7) {
		setBaseNaked05(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row01X,0.5]} />
            ]
          	)
	} else if (RCol5Row1 === 8) {
		setBaseNaked05(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
            ]
          	)
	} else if (RCol5Row1 === 9) {
		setBaseNaked05(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
            ]
          	)
	} else if (RCol5Row1 === 10) {
		setBaseNaked05(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row01X,0.5]} />
            ]
          	)
	} else if (RCol5Row1 === 11) {
		 setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
   
	} else if (RCol5Row1 === 12) {
 		setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 13) {
 		setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 14) {
 		setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 15) {
			setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 16) {
		setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 17) {
setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 18) {
		setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 19) {
		setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 20) {
setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 11.1) {
 		setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 12.1) {
		setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 13.1) {
setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 14.1) {
setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 15.1) {
setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 16.1) {
setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 17.1) {
setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 18.1) {
setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 19.1) {
setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 20.1) {
setBaseNakedM05(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 21) {
 setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 22) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 23) {
 setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 24) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 25) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 26) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 27) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 28) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 29) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
		]
          )
	} else if (RCol5Row1 === 30) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 21.1) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 22.1) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 23.1) {
 setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 24.1) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 25.1) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 26.1) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 27.1) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 28.1) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 29.1) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
 		]
          )
	} else if (RCol5Row1 === 30.1) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 21.3) {
 setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 22.3) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 23.3) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 24.3) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 25.3) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 26.3) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 27.3) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 28.3) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 29.3) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 30.3) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 21.5) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 22.5) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 23.5) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 24.5) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 25.5) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 26.5) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 27.5) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 28.5) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 29.5) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 30.5) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 21.4) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 22.4) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 23.4) {
 setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 24.4) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 25.4) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 26.4) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 27.4) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 28.4) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 29.4) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 30.4) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 21.6) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 22.6) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 23.6) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 24.6) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 25.6) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 26.6) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 27.6) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 28.6) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 29.6) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 30.6) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 21.8) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 22.8) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 23.8) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 24.8) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 25.8) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 26.8) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 27.8) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 28.8) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 29.8) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 30.8) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 21.9) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 22.9) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 23.9) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 24.9) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 25.9) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 26.9) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 27.9) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 28.9) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 29.9) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	} else if (RCol5Row1 === 30.9) {
setBaseNakedL05(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
            ]
          )
	}
}

const reloadc5r2 = (e) => {
	if (RCol5Row2 === 1) {
		setRow2Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row02X,0.5]} />
            ]
          	)

	} else if (RCol5Row2 === 2) {
		setRow2Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row02X,0.5]} />
            ]
          	)
	} else if (RCol5Row2 === 3) {
		setRow2Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row02X,0.5]} />
            ]
          	)
	} else if (RCol5Row2 === 4) {
		setRow2Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
            ]
          	)
	} else if (RCol5Row2 === 5) {
		setRow2Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
            ]
          	)
	} else if (RCol5Row2 === 6) {
		setRow2Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row02X,0.5]} />
            ]
          	)
	} else if (RCol5Row2 === 7) {
		setRow2Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row02X,0.5]} />
            ]
          	)
	} else if (RCol5Row2 === 8) {
		setRow2Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
            ]
          	)
	} else if (RCol5Row2 === 9) {
		setRow2Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
            ]
          	)
	} else if (RCol5Row2 === 10) {
		setRow2Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row02X,0.5]} />
            ]
          	)
	} else if (RCol5Row2 === 11) {
	if (RCol5Row1 < 11) {
		 setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
   }
	} else if (RCol5Row2 === 12) {
	if (RCol5Row1 < 11) {
 		setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol5Row2 === 13) {
	if (RCol5Row1 < 11) {
 		setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol5Row2 === 14) {
	if (RCol5Row1 < 11) {
 		setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol5Row2 === 15) {
	if (RCol5Row1 < 11) {
			setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol5Row2 === 16) {
	if (RCol5Row1 < 11) {
		setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol5Row2 === 17) {
	if (RCol5Row1 < 11) {
setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol5Row2 === 18) {
	if (RCol5Row1 < 11) {
		setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol5Row2 === 19) {
	if (RCol5Row1 < 11) {
		setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol5Row2 === 20) {
	if (RCol5Row1 < 11) {
setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol5Row2 === 11.1) {
	if (RCol5Row1 < 11) {
 		setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col05X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol5Row2 === 12.1) {
	if (RCol5Row1 < 11) {
		setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col05X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol5Row2 === 13.1) {
	if (RCol5Row1 < 11) {
setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col05X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol5Row2 === 14.1) {
	if (RCol5Row1 < 11) {
setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol5Row2 === 15.1) {
	if (RCol5Row1 < 11) {
setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol5Row2 === 16.1) {
	if (RCol5Row1 < 11) {
setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col05X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol5Row2 === 17.1) {
	if (RCol5Row1 < 11) {
setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol5Row2 === 18.1) {
	if (RCol5Row1 < 11) {
setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol5Row2 === 19.1) {
	if (RCol5Row1 < 11) {
setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol5Row2 === 20.1) {
	if (RCol5Row1 < 11) {
setRow2NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
            ]
          )
}
	} 
}

const reloadc5r3 = (e) => {
	if (RCol5Row3 === 1) {
		setRow3Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row03X,0.5]} />
            ]
          	)

	} else if (RCol5Row3 === 2) {
		setRow3Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row03X,0.5]} />
            ]
          	)
	} else if (RCol5Row3 === 3) {
		setRow3Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row03X,0.5]} />
            ]
          	)
	} else if (RCol5Row3 === 4) {
		setRow3Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
            ]
          	)
	} else if (RCol5Row3 === 5) {
		setRow3Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
            ]
          	)
	} else if (RCol5Row3 === 6) {
		setRow3Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row03X,0.5]} />
            ]
          	)
	} else if (RCol5Row3 === 7) {
		setRow3Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row03X,0.5]} />
            ]
          	)
	} else if (RCol5Row3 === 8) {
		setRow3Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
            ]
          	)
	} else if (RCol5Row3 === 9) {
		setRow3Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
            ]
          	)
	} else if (RCol5Row3 === 10) {
		setRow3Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row03X,0.5]} />
            ]
          	)
	} else if (RCol5Row3 === 11) {
	if (RCol5Row2 < 11 || RCol5Row1 > 10) {

		 setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
   }
	} else if (RCol5Row3 === 12) {
	if (RCol5Row2 < 11 || RCol5Row1 > 10) {
 		setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol5Row3 === 13) {
	if (RCol5Row2 < 11 || RCol5Row1 > 10) {
 		setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol5Row3 === 14) {
	if (RCol5Row2 < 11 || RCol5Row1 > 10) {
 		setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol5Row3 === 15) {
	if (RCol5Row2 < 11 || RCol5Row1 > 10) {
			setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol5Row3 === 16) {
	if (RCol5Row2 < 11 || RCol5Row1 > 10) {
		setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol5Row3 === 17) {
	if (RCol5Row2 < 11 || RCol5Row1 > 10) {
setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol5Row3 === 18) {
	if (RCol5Row2 < 11 || RCol5Row1 > 10) {
		setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol5Row3 === 19) {
	if (RCol5Row2 < 11 || RCol5Row1 > 10) {
		setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol5Row3 === 20) {
	if (RCol5Row2 < 11 || RCol5Row1 > 10) {
setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol5Row3 === 11.1) {
	if (RCol5Row2 < 11 || RCol5Row1 > 10) {
 		setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col05X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol5Row3 === 12.1) {
	if (RCol5Row2 < 11 || RCol5Row1 > 10) {
		setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col05X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol5Row3 === 13.1) {
	if (RCol5Row2 < 11 || RCol5Row1 > 10) {
setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col05X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol5Row3 === 14.1) {
	if (RCol5Row2 < 11 || RCol5Row1 > 10) {
setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol5Row3 === 15.1) {
	if (RCol5Row2 < 11 || RCol5Row1 > 10) {
setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol5Row3 === 16.1) {
	if (RCol5Row2 < 11 || RCol5Row1 > 10) {
setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col05X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol5Row3 === 17.1) {
	if (RCol5Row2 < 11 || RCol5Row1 > 10) {
setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol5Row3 === 18.1) {
	if (RCol5Row2 < 11 || RCol5Row1 > 10) {
setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol5Row3 === 19.1) {
	if (RCol5Row2 < 11 || RCol5Row1 > 10) {
setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol5Row3 === 20.1) {
	if (RCol5Row2 < 11 || RCol5Row1 > 10) {
setRow3NakedM05(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
            ]
          )
}
	} 
}

const reloadc5r4 = (e) => {
	if (RCol5Row4 === 1) {
		setRow4Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row04X,0.5]} />
            ]
          	)

	} else if (RCol5Row4 === 2) {
		setRow4Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col05X,row04X,0.5]} />
            ]
          	)
	} else if (RCol5Row4 === 3) {
		setRow4Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row04X,0.5]} />
            ]
          	)
	} else if (RCol5Row4 === 4) {
		setRow4Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row04X,0.5]} />
            ]
          	)
	} else if (RCol5Row4 === 5) {
		setRow4Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
            ]
          	)
	} else if (RCol5Row4 === 6) {
		setRow4Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col05X,row04X,0.5]} />
            ]
          	)
	} else if (RCol5Row4 === 7) {
		setRow4Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col05X,row04X,0.5]} />
            ]
          	)
	} else if (RCol5Row4 === 8) {
		setRow4Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
            ]
          	)
	} else if (RCol5Row4 === 9) {
		setRow4Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
            ]
          	)
	} else if (RCol5Row4 === 10) {
		setRow4Naked05(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col05X,row04X,0.5]} />
            ]
          	)
	} 
}
const reloadc6r1 = (e) => {
	if (RCol6Row1 === 1) {
		setBaseNaked06(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row01X,0.5]} />
            ]
          	)

	} else if (RCol6Row1 === 2) {
		setBaseNaked06(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row01X,0.5]} />
            ]
          	)
	} else if (RCol6Row1 === 3) {
		setBaseNaked06(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row01X,0.5]} />
            ]
          	)
	} else if (RCol6Row1 === 4) {
		setBaseNaked06(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
            ]
          	)
	} else if (RCol6Row1 === 5) {
		setBaseNaked06(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
            ]
          	)
	} else if (RCol6Row1 === 6) {
		setBaseNaked06(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row01X,0.5]} />
            ]
          	)
	} else if (RCol6Row1 === 7) {
		setBaseNaked06(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row01X,0.5]} />
            ]
          	)
	} else if (RCol6Row1 === 8) {
		setBaseNaked06(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
            ]
          	)
	} else if (RCol6Row1 === 9) {
		setBaseNaked06(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
            ]
          	)
	} else if (RCol6Row1 === 10) {
		setBaseNaked06(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row01X,0.5]} />
            ]
          	)
	} else if (RCol6Row1 === 11) {
		 setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
   
	} else if (RCol6Row1 === 12) {
 		setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 13) {
 		setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 14) {
 		setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 15) {
			setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 16) {
		setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 17) {
setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 18) {
		setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 19) {
		setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 20) {
setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 11.1) {
 		setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 12.1) {
		setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 13.1) {
setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 14.1) {
setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 15.1) {
setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 16.1) {
setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 17.1) {
setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 18.1) {
setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 19.1) {
setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 20.1) {
setBaseNakedM06(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 21) {
 setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 22) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 23) {
 setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 24) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 25) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 26) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 27) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 28) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 29) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
		]
          )
	} else if (RCol6Row1 === 30) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 21.1) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 22.1) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 23.1) {
 setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 24.1) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 25.1) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 26.1) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 27.1) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 28.1) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 29.1) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
 		]
          )
	} else if (RCol6Row1 === 30.1) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 21.3) {
 setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 22.3) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 23.3) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 24.3) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 25.3) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 26.3) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 27.3) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 28.3) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 29.3) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 30.3) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 21.5) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 22.5) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 23.5) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 24.5) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 25.5) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 26.5) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 27.5) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 28.5) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 29.5) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 30.5) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 21.4) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 22.4) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 23.4) {
 setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 24.4) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 25.4) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 26.4) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 27.4) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 28.4) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 29.4) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 30.4) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 21.6) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 22.6) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 23.6) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 24.6) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 25.6) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 26.6) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 27.6) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 28.6) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 29.6) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 30.6) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 21.8) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 22.8) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 23.8) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 24.8) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 25.8) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 26.8) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 27.8) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 28.8) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 29.8) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 30.8) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 21.9) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 22.9) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 23.9) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 24.9) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 25.9) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 26.9) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 27.9) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 28.9) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 29.9) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	} else if (RCol6Row1 === 30.9) {
setBaseNakedL06(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
            ]
          )
	}
}

const reloadc6r2 = (e) => {
	if (RCol6Row2 === 1) {
		setRow2Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row02X,0.5]} />
            ]
          	)

	} else if (RCol6Row2 === 2) {
		setRow2Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row02X,0.5]} />
            ]
          	)
	} else if (RCol6Row2 === 3) {
		setRow2Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row02X,0.5]} />
            ]
          	)
	} else if (RCol6Row2 === 4) {
		setRow2Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
            ]
          	)
	} else if (RCol6Row2 === 5) {
		setRow2Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
            ]
          	)
	} else if (RCol6Row2 === 6) {
		setRow2Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row02X,0.5]} />
            ]
          	)
	} else if (RCol6Row2 === 7) {
		setRow2Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row02X,0.5]} />
            ]
          	)
	} else if (RCol6Row2 === 8) {
		setRow2Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
            ]
          	)
	} else if (RCol6Row2 === 9) {
		setRow2Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
            ]
          	)
	} else if (RCol6Row2 === 10) {
		setRow2Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row02X,0.5]} />
            ]
          	)
	} else if (RCol6Row2 === 11) {
	if (RCol6Row1 < 11) {
		 setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
   }
	} else if (RCol6Row2 === 12) {
	if (RCol6Row1 < 11) {
 		setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol6Row2 === 13) {
	if (RCol6Row1 < 11) {
 		setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol6Row2 === 14) {
	if (RCol6Row1 < 11) {
 		setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol6Row2 === 15) {
	if (RCol6Row1 < 11) {
			setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol6Row2 === 16) {
	if (RCol6Row1 < 11) {
		setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol6Row2 === 17) {
	if (RCol6Row1 < 11) {
setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol6Row2 === 18) {
	if (RCol6Row1 < 11) {
		setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol6Row2 === 19) {
	if (RCol6Row1 < 11) {
		setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol6Row2 === 20) {
	if (RCol6Row1 < 11) {
setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol6Row2 === 11.1) {
	if (RCol6Row1 < 11) {
 		setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col06X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol6Row2 === 12.1) {
	if (RCol6Row1 < 11) {
		setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col06X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol6Row2 === 13.1) {
	if (RCol6Row1 < 11) {
setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col06X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol6Row2 === 14.1) {
	if (RCol6Row1 < 11) {
setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol6Row2 === 15.1) {
	if (RCol6Row1 < 11) {
setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol6Row2 === 16.1) {
	if (RCol6Row1 < 11) {
setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col06X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol6Row2 === 17.1) {
	if (RCol6Row1 < 11) {
setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol6Row2 === 18.1) {
	if (RCol6Row1 < 11) {
setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol6Row2 === 19.1) {
	if (RCol6Row1 < 11) {
setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol6Row2 === 20.1) {
	if (RCol6Row1 < 11) {
setRow2NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
            ]
          )
}
	} 
}

const reloadc6r3 = (e) => {
	if (RCol6Row3 === 1) {
		setRow3Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row03X,0.5]} />
            ]
          	)

	} else if (RCol6Row3 === 2) {
		setRow3Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row03X,0.5]} />
            ]
          	)
	} else if (RCol6Row3 === 3) {
		setRow3Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row03X,0.5]} />
            ]
          	)
	} else if (RCol6Row3 === 4) {
		setRow3Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
            ]
          	)
	} else if (RCol6Row3 === 5) {
		setRow3Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
            ]
          	)
	} else if (RCol6Row3 === 6) {
		setRow3Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row03X,0.5]} />
            ]
          	)
	} else if (RCol6Row3 === 7) {
		setRow3Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row03X,0.5]} />
            ]
          	)
	} else if (RCol6Row3 === 8) {
		setRow3Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
            ]
          	)
	} else if (RCol6Row3 === 9) {
		setRow3Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
            ]
          	)
	} else if (RCol6Row3 === 10) {
		setRow3Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row03X,0.5]} />
            ]
          	)
	} else if (RCol6Row3 === 11) {
	if (RCol6Row2 < 11 || RCol6Row1 > 10) {

		 setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
   }
	} else if (RCol6Row3 === 12) {
	if (RCol6Row2 < 11 || RCol6Row1 > 10) {
 		setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol6Row3 === 13) {
	if (RCol6Row2 < 11 || RCol6Row1 > 10) {
 		setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol6Row3 === 14) {
	if (RCol6Row2 < 11 || RCol6Row1 > 10) {
 		setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol6Row3 === 15) {
	if (RCol6Row2 < 11 || RCol6Row1 > 10) {
			setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol6Row3 === 16) {
	if (RCol6Row2 < 11 || RCol6Row1 > 10) {
		setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol6Row3 === 17) {
	if (RCol6Row2 < 11 || RCol6Row1 > 10) {
setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol6Row3 === 18) {
	if (RCol6Row2 < 11 || RCol6Row1 > 10) {
		setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol6Row3 === 19) {
	if (RCol6Row2 < 11 || RCol6Row1 > 10) {
		setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol6Row3 === 20) {
	if (RCol6Row2 < 11 || RCol6Row1 > 10) {
setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol6Row3 === 11.1) {
	if (RCol6Row2 < 11 || RCol6Row1 > 10) {
 		setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col06X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol6Row3 === 12.1) {
	if (RCol6Row2 < 11 || RCol6Row1 > 10) {
		setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col06X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol6Row3 === 13.1) {
	if (RCol6Row2 < 11 || RCol6Row1 > 10) {
setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col06X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol6Row3 === 14.1) {
	if (RCol6Row2 < 11 || RCol6Row1 > 10) {
setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol6Row3 === 15.1) {
	if (RCol6Row2 < 11 || RCol6Row1 > 10) {
setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol6Row3 === 16.1) {
	if (RCol6Row2 < 11 || RCol6Row1 > 10) {
setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col06X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol6Row3 === 17.1) {
	if (RCol6Row2 < 11 || RCol6Row1 > 10) {
setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol6Row3 === 18.1) {
	if (RCol6Row2 < 11 || RCol6Row1 > 10) {
setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol6Row3 === 19.1) {
	if (RCol6Row2 < 11 || RCol6Row1 > 10) {
setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol6Row3 === 20.1) {
	if (RCol6Row2 < 11 || RCol6Row1 > 10) {
setRow3NakedM06(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
            ]
          )
}
	} 
}

const reloadc6r4 = (e) => {
	if (RCol6Row4 === 1) {
		setRow4Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row04X,0.5]} />
            ]
          	)

	} else if (RCol6Row4 === 2) {
		setRow4Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col06X,row04X,0.5]} />
            ]
          	)
	} else if (RCol6Row4 === 3) {
		setRow4Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row04X,0.5]} />
            ]
          	)
	} else if (RCol6Row4 === 4) {
		setRow4Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row04X,0.5]} />
            ]
          	)
	} else if (RCol6Row4 === 5) {
		setRow4Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
            ]
          	)
	} else if (RCol6Row4 === 6) {
		setRow4Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col06X,row04X,0.5]} />
            ]
          	)
	} else if (RCol6Row4 === 7) {
		setRow4Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col06X,row04X,0.5]} />
            ]
          	)
	} else if (RCol6Row4 === 8) {
		setRow4Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
            ]
          	)
	} else if (RCol6Row4 === 9) {
		setRow4Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
            ]
          	)
	} else if (RCol6Row4 === 10) {
		setRow4Naked06(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col06X,row04X,0.5]} />
            ]
          	)
	} 
}

const reloadc7r1 = (e) => {
	if (RCol7Row1 === 1) {
		setBaseNaked07(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row01X,0.5]} />
            ]
          	)

	} else if (RCol7Row1 === 2) {
		setBaseNaked07(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row01X,0.5]} />
            ]
          	)
	} else if (RCol7Row1 === 3) {
		setBaseNaked07(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row01X,0.5]} />
            ]
          	)
	} else if (RCol7Row1 === 4) {
		setBaseNaked07(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
            ]
          	)
	} else if (RCol7Row1 === 5) {
		setBaseNaked07(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
            ]
          	)
	} else if (RCol7Row1 === 6) {
		setBaseNaked07(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row01X,0.5]} />
            ]
          	)
	} else if (RCol7Row1 === 7) {
		setBaseNaked07(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row01X,0.5]} />
            ]
          	)
	} else if (RCol7Row1 === 8) {
		setBaseNaked07(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
            ]
          	)
	} else if (RCol7Row1 === 9) {
		setBaseNaked07(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
            ]
          	)
	} else if (RCol7Row1 === 10) {
		setBaseNaked07(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row01X,0.5]} />
            ]
          	)
	} else if (RCol7Row1 === 11) {
		 setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
   
	} else if (RCol7Row1 === 12) {
 		setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 13) {
 		setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 14) {
 		setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 15) {
			setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 16) {
		setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 17) {
setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 18) {
		setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 19) {
		setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 20) {
setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 11.1) {
 		setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 12.1) {
		setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 13.1) {
setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 14.1) {
setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 15.1) {
setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 16.1) {
setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 17.1) {
setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 18.1) {
setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 19.1) {
setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 20.1) {
setBaseNakedM07(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 21) {
 setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 22) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 23) {
 setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 24) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 25) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 26) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 27) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 28) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 29) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
		]
          )
	} else if (RCol7Row1 === 30) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 21.1) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 22.1) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 23.1) {
 setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 24.1) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 25.1) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 26.1) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 27.1) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 28.1) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 29.1) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
 		]
          )
	} else if (RCol7Row1 === 30.1) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 21.3) {
 setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 22.3) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 23.3) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 24.3) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 25.3) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 26.3) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 27.3) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 28.3) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 29.3) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 30.3) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 21.5) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 22.5) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 23.5) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 24.5) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 25.5) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 26.5) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 27.5) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 28.5) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 29.5) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 30.5) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 21.4) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 22.4) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 23.4) {
 setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 24.4) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 25.4) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 26.4) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 27.4) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 28.4) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 29.4) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 30.4) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 21.6) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 22.6) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 23.6) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 24.6) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 25.6) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 26.6) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 27.6) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 28.6) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 29.6) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 30.6) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 21.8) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 22.8) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 23.8) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 24.8) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 25.8) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 26.8) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 27.8) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 28.8) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 29.8) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 30.8) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 21.9) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 22.9) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 23.9) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 24.9) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 25.9) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 26.9) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 27.9) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 28.9) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 29.9) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	} else if (RCol7Row1 === 30.9) {
setBaseNakedL07(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
            ]
          )
	}
}
const reloadc7r2 = (e) => {
	if (RCol7Row2 === 1) {
		setRow2Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row02X,0.5]} />
            ]
          	)

	} else if (RCol7Row2 === 2) {
		setRow2Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row02X,0.5]} />
            ]
          	)
	} else if (RCol7Row2 === 3) {
		setRow2Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row02X,0.5]} />
            ]
          	)
	} else if (RCol7Row2 === 4) {
		setRow2Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
            ]
          	)
	} else if (RCol7Row2 === 5) {
		setRow2Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
            ]
          	)
	} else if (RCol7Row2 === 6) {
		setRow2Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row02X,0.5]} />
            ]
          	)
	} else if (RCol7Row2 === 7) {
		setRow2Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row02X,0.5]} />
            ]
          	)
	} else if (RCol7Row2 === 8) {
		setRow2Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
            ]
          	)
	} else if (RCol7Row2 === 9) {
		setRow2Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
            ]
          	)
	} else if (RCol7Row2 === 10) {
		setRow2Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row02X,0.5]} />
            ]
          	)
	} else if (RCol7Row2 === 11) {
	if (RCol7Row1 < 11) {
		 setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
   }
	} else if (RCol7Row2 === 12) {
	if (RCol7Row1 < 11) {
 		setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol7Row2 === 13) {
	if (RCol7Row1 < 11) {
 		setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol7Row2 === 14) {
	if (RCol7Row1 < 11) {
 		setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol7Row2 === 15) {
	if (RCol7Row1 < 11) {
			setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol7Row2 === 16) {
	if (RCol7Row1 < 11) {
		setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol7Row2 === 17) {
	if (RCol7Row1 < 11) {
setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol7Row2 === 18) {
	if (RCol7Row1 < 11) {
		setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol7Row2 === 19) {
	if (RCol7Row1 < 11) {
		setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol7Row2 === 20) {
	if (RCol7Row1 < 11) {
setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol7Row2 === 11.1) {
	if (RCol7Row1 < 11) {
 		setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col07X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol7Row2 === 12.1) {
	if (RCol7Row1 < 11) {
		setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col07X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol7Row2 === 13.1) {
	if (RCol7Row1 < 11) {
setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col07X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol7Row2 === 14.1) {
	if (RCol7Row1 < 11) {
setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol7Row2 === 15.1) {
	if (RCol7Row1 < 11) {
setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol7Row2 === 16.1) {
	if (RCol7Row1 < 11) {
setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col07X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol7Row2 === 17.1) {
	if (RCol7Row1 < 11) {
setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol7Row2 === 18.1) {
	if (RCol7Row1 < 11) {
setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol7Row2 === 19.1) {
	if (RCol7Row1 < 11) {
setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol7Row2 === 20.1) {
	if (RCol7Row1 < 11) {
setRow2NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
            ]
          )
}
	} 
}

const reloadc7r3 = (e) => {
	if (RCol7Row3 === 1) {
		setRow3Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row03X,0.5]} />
            ]
          	)

	} else if (RCol7Row3 === 2) {
		setRow3Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row03X,0.5]} />
            ]
          	)
	} else if (RCol7Row3 === 3) {
		setRow3Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row03X,0.5]} />
            ]
          	)
	} else if (RCol7Row3 === 4) {
		setRow3Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
            ]
          	)
	} else if (RCol7Row3 === 5) {
		setRow3Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
            ]
          	)
	} else if (RCol7Row3 === 6) {
		setRow3Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row03X,0.5]} />
            ]
          	)
	} else if (RCol7Row3 === 7) {
		setRow3Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row03X,0.5]} />
            ]
          	)
	} else if (RCol7Row3 === 8) {
		setRow3Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
            ]
          	)
	} else if (RCol7Row3 === 9) {
		setRow3Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
            ]
          	)
	} else if (RCol7Row3 === 10) {
		setRow3Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row03X,0.5]} />
            ]
          	)
	} else if (RCol7Row3 === 11) {
	if (RCol7Row2 < 11 || RCol7Row1 > 10) {

		 setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
   }
	} else if (RCol7Row3 === 12) {
	if (RCol7Row2 < 11 || RCol7Row1 > 10) {
 		setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol7Row3 === 13) {
	if (RCol7Row2 < 11 || RCol7Row1 > 10) {
 		setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol7Row3 === 14) {
	if (RCol7Row2 < 11 || RCol7Row1 > 10) {
 		setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol7Row3 === 15) {
	if (RCol7Row2 < 11 || RCol7Row1 > 10) {
			setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol7Row3 === 16) {
	if (RCol7Row2 < 11 || RCol7Row1 > 10) {
		setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol7Row3 === 17) {
	if (RCol7Row2 < 11 || RCol7Row1 > 10) {
setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol7Row3 === 18) {
	if (RCol7Row2 < 11 || RCol7Row1 > 10) {
		setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol7Row3 === 19) {
	if (RCol7Row2 < 11 || RCol7Row1 > 10) {
		setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol7Row3 === 20) {
	if (RCol7Row2 < 11 || RCol7Row1 > 10) {
setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol7Row3 === 11.1) {
	if (RCol7Row2 < 11 || RCol7Row1 > 10) {
 		setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col07X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol7Row3 === 12.1) {
	if (RCol7Row2 < 11 || RCol7Row1 > 10) {
		setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col07X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol7Row3 === 13.1) {
	if (RCol7Row2 < 11 || RCol7Row1 > 10) {
setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col07X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol7Row3 === 14.1) {
	if (RCol7Row2 < 11 || RCol7Row1 > 10) {
setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol7Row3 === 15.1) {
	if (RCol7Row2 < 11 || RCol7Row1 > 10) {
setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol7Row3 === 16.1) {
	if (RCol7Row2 < 11 || RCol7Row1 > 10) {
setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col07X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol7Row3 === 17.1) {
	if (RCol7Row2 < 11 || RCol7Row1 > 10) {
setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol7Row3 === 18.1) {
	if (RCol7Row2 < 11 || RCol7Row1 > 10) {
setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol7Row3 === 19.1) {
	if (RCol7Row2 < 11 || RCol7Row1 > 10) {
setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol7Row3 === 20.1) {
	if (RCol7Row2 < 11 || RCol7Row1 > 10) {
setRow3NakedM07(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
            ]
          )
}
	} 
}

const reloadc7r4 = (e) => {
	if (RCol7Row4 === 1) {
		setRow4Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row04X,0.5]} />
            ]
          	)

	} else if (RCol7Row4 === 2) {
		setRow4Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col07X,row04X,0.5]} />
            ]
          	)
	} else if (RCol7Row4 === 3) {
		setRow4Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row04X,0.5]} />
            ]
          	)
	} else if (RCol7Row4 === 4) {
		setRow4Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row04X,0.5]} />
            ]
          	)
	} else if (RCol7Row4 === 5) {
		setRow4Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
            ]
          	)
	} else if (RCol7Row4 === 6) {
		setRow4Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col07X,row04X,0.5]} />
            ]
          	)
	} else if (RCol7Row4 === 7) {
		setRow4Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col07X,row04X,0.5]} />
            ]
          	)
	} else if (RCol7Row4 === 8) {
		setRow4Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
            ]
          	)
	} else if (RCol7Row4 === 9) {
		setRow4Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
            ]
          	)
	} else if (RCol7Row4 === 10) {
		setRow4Naked07(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col07X,row04X,0.5]} />
            ]
          	)
	} 
}

const reloadc8r1 = (e) => {
	if (RCol8Row1 === 1) {
		setBaseNaked08(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row01X,0.5]} />
            ]
          	)

	} else if (RCol8Row1 === 2) {
		setBaseNaked08(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row01X,0.5]} />
            ]
          	)
	} else if (RCol8Row1 === 3) {
		setBaseNaked08(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row01X,0.5]} />
            ]
          	)
	} else if (RCol8Row1 === 4) {
		setBaseNaked08(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
            ]
          	)
	} else if (RCol8Row1 === 5) {
		setBaseNaked08(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
            ]
          	)
	} else if (RCol8Row1 === 6) {
		setBaseNaked08(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row01X,0.5]} />
            ]
          	)
	} else if (RCol8Row1 === 7) {
		setBaseNaked08(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row01X,0.5]} />
            ]
          	)
	} else if (RCol8Row1 === 8) {
		setBaseNaked08(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
            ]
          	)
	} else if (RCol8Row1 === 9) {
		setBaseNaked08(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
            ]
          	)
	} else if (RCol8Row1 === 10) {
		setBaseNaked08(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row01X,0.5]} />
            ]
          	)
	} else if (RCol8Row1 === 11) {
		 setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
   
	} else if (RCol8Row1 === 12) {
 		setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 13) {
 		setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 14) {
 		setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 15) {
			setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 16) {
		setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 17) {
setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 18) {
		setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 19) {
		setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 20) {
setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 11.1) {
 		setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 12.1) {
		setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 13.1) {
setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 14.1) {
setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 15.1) {
setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 16.1) {
setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 17.1) {
setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 18.1) {
setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 19.1) {
setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 20.1) {
setBaseNakedM08(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 21) {
 setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 22) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 23) {
 setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 24) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 25) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 26) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 27) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 28) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 29) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
		]
          )
	} else if (RCol8Row1 === 30) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 21.1) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 22.1) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 23.1) {
 setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 24.1) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 25.1) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 26.1) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 27.1) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 28.1) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 29.1) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
 		]
          )
	} else if (RCol8Row1 === 30.1) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 21.3) {
 setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 22.3) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 23.3) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 24.3) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 25.3) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 26.3) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 27.3) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 28.3) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 29.3) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 30.3) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 21.5) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 22.5) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 23.5) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 24.5) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 25.5) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 26.5) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 27.5) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 28.5) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 29.5) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 30.5) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 21.4) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 22.4) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 23.4) {
 setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 24.4) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 25.4) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 26.4) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 27.4) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 28.4) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 29.4) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 30.4) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 21.6) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 22.6) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 23.6) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 24.6) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 25.6) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 26.6) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 27.6) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 28.6) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 29.6) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 30.6) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 21.8) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 22.8) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 23.8) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 24.8) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 25.8) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 26.8) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 27.8) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 28.8) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 29.8) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 30.8) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 21.9) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 22.9) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 23.9) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 24.9) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 25.9) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 26.9) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 27.9) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 28.9) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 29.9) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	} else if (RCol8Row1 === 30.9) {
setBaseNakedL08(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
            ]
          )
	}
}

const reloadc8r2 = (e) => {
	if (RCol8Row2 === 1) {
		setRow2Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row02X,0.5]} />
            ]
          	)

	} else if (RCol8Row2 === 2) {
		setRow2Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row02X,0.5]} />
            ]
          	)
	} else if (RCol8Row2 === 3) {
		setRow2Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row02X,0.5]} />
            ]
          	)
	} else if (RCol8Row2 === 4) {
		setRow2Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
            ]
          	)
	} else if (RCol8Row2 === 5) {
		setRow2Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
            ]
          	)
	} else if (RCol8Row2 === 6) {
		setRow2Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row02X,0.5]} />
            ]
          	)
	} else if (RCol8Row2 === 7) {
		setRow2Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row02X,0.5]} />
            ]
          	)
	} else if (RCol8Row2 === 8) {
		setRow2Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
            ]
          	)
	} else if (RCol8Row2 === 9) {
		setRow2Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
            ]
          	)
	} else if (RCol8Row2 === 10) {
		setRow2Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row02X,0.5]} />
            ]
          	)
	} else if (RCol8Row2 === 11) {
	if (RCol8Row1 < 11) {
		 setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
   }
	} else if (RCol8Row2 === 12) {
	if (RCol8Row1 < 11) {
 		setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol8Row2 === 13) {
	if (RCol8Row1 < 11) {
 		setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol8Row2 === 14) {
	if (RCol8Row1 < 11) {
 		setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol8Row2 === 15) {
	if (RCol8Row1 < 11) {
			setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol8Row2 === 16) {
	if (RCol8Row1 < 11) {
		setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol8Row2 === 17) {
	if (RCol8Row1 < 11) {
setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol8Row2 === 18) {
	if (RCol8Row1 < 11) {
		setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol8Row2 === 19) {
	if (RCol8Row1 < 11) {
		setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol8Row2 === 20) {
	if (RCol8Row1 < 11) {
setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol8Row2 === 11.1) {
	if (RCol8Row1 < 11) {
 		setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col08X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol8Row2 === 12.1) {
	if (RCol8Row1 < 11) {
		setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col08X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol8Row2 === 13.1) {
	if (RCol8Row1 < 11) {
setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col08X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol8Row2 === 14.1) {
	if (RCol8Row1 < 11) {
setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol8Row2 === 15.1) {
	if (RCol8Row1 < 11) {
setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol8Row2 === 16.1) {
	if (RCol8Row1 < 11) {
setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col08X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol8Row2 === 17.1) {
	if (RCol8Row1 < 11) {
setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol8Row2 === 18.1) {
	if (RCol8Row1 < 11) {
setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol8Row2 === 19.1) {
	if (RCol8Row1 < 11) {
setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol8Row2 === 20.1) {
	if (RCol8Row1 < 11) {
setRow2NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
            ]
          )
}
	} 
}

const reloadc8r3 = (e) => {
	if (RCol8Row3 === 1) {
		setRow3Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row03X,0.5]} />
            ]
          	)

	} else if (RCol8Row3 === 2) {
		setRow3Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row03X,0.5]} />
            ]
          	)
	} else if (RCol8Row3 === 3) {
		setRow3Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row03X,0.5]} />
            ]
          	)
	} else if (RCol8Row3 === 4) {
		setRow3Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
            ]
          	)
	} else if (RCol8Row3 === 5) {
		setRow3Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
            ]
          	)
	} else if (RCol8Row3 === 6) {
		setRow3Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row03X,0.5]} />
            ]
          	)
	} else if (RCol8Row3 === 7) {
		setRow3Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row03X,0.5]} />
            ]
          	)
	} else if (RCol8Row3 === 8) {
		setRow3Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
            ]
          	)
	} else if (RCol8Row3 === 9) {
		setRow3Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
            ]
          	)
	} else if (RCol8Row3 === 10) {
		setRow3Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row03X,0.5]} />
            ]
          	)
	} else if (RCol8Row3 === 11) {
	if (RCol8Row2 < 11 || RCol8Row1 > 10) {

		 setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
   }
	} else if (RCol8Row3 === 12) {
	if (RCol8Row2 < 11 || RCol8Row1 > 10) {
 		setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol8Row3 === 13) {
	if (RCol8Row2 < 11 || RCol8Row1 > 10) {
 		setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol8Row3 === 14) {
	if (RCol8Row2 < 11 || RCol8Row1 > 10) {
 		setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol8Row3 === 15) {
	if (RCol8Row2 < 11 || RCol8Row1 > 10) {
			setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol8Row3 === 16) {
	if (RCol8Row2 < 11 || RCol8Row1 > 10) {
		setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol8Row3 === 17) {
	if (RCol8Row2 < 11 || RCol8Row1 > 10) {
setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol8Row3 === 18) {
	if (RCol8Row2 < 11 || RCol8Row1 > 10) {
		setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol8Row3 === 19) {
	if (RCol8Row2 < 11 || RCol8Row1 > 10) {
		setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol8Row3 === 20) {
	if (RCol8Row2 < 11 || RCol8Row1 > 10) {
setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol8Row3 === 11.1) {
	if (RCol8Row2 < 11 || RCol8Row1 > 10) {
 		setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col08X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol8Row3 === 12.1) {
	if (RCol8Row2 < 11 || RCol8Row1 > 10) {
		setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col08X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol8Row3 === 13.1) {
	if (RCol8Row2 < 11 || RCol8Row1 > 10) {
setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col08X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol8Row3 === 14.1) {
	if (RCol8Row2 < 11 || RCol8Row1 > 10) {
setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol8Row3 === 15.1) {
	if (RCol8Row2 < 11 || RCol8Row1 > 10) {
setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol8Row3 === 16.1) {
	if (RCol8Row2 < 11 || RCol8Row1 > 10) {
setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col08X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol8Row3 === 17.1) {
	if (RCol8Row2 < 11 || RCol8Row1 > 10) {
setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol8Row3 === 18.1) {
	if (RCol8Row2 < 11 || RCol8Row1 > 10) {
setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol8Row3 === 19.1) {
	if (RCol8Row2 < 11 || RCol8Row1 > 10) {
setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol8Row3 === 20.1) {
	if (RCol8Row2 < 11 || RCol8Row1 > 10) {
setRow3NakedM08(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
            ]
          )
}
	} 
}
const reloadc8r4 = (e) => {
	if (RCol8Row4 === 1) {
		setRow4Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row04X,0.5]} />
            ]
          	)

	} else if (RCol8Row4 === 2) {
		setRow4Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col08X,row04X,0.5]} />
            ]
          	)
	} else if (RCol8Row4 === 3) {
		setRow4Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row04X,0.5]} />
            ]
          	)
	} else if (RCol8Row4 === 4) {
		setRow4Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row04X,0.5]} />
            ]
          	)
	} else if (RCol8Row4 === 5) {
		setRow4Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
            ]
          	)
	} else if (RCol8Row4 === 6) {
		setRow4Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col08X,row04X,0.5]} />
            ]
          	)
	} else if (RCol8Row4 === 7) {
		setRow4Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col08X,row04X,0.5]} />
            ]
          	)
	} else if (RCol8Row4 === 8) {
		setRow4Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
            ]
          	)
	} else if (RCol8Row4 === 9) {
		setRow4Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
            ]
          	)
	} else if (RCol8Row4 === 10) {
		setRow4Naked08(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col08X,row04X,0.5]} />
            ]
          	)
	} 
}

const reloadc9r1 = (e) => {
	if (RCol9Row1 === 1) {
		setBaseNaked09(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row01X,0.5]} />
            ]
          	)

	} else if (RCol9Row1 === 2) {
		setBaseNaked09(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row01X,0.5]} />
            ]
          	)
	} else if (RCol9Row1 === 3) {
		setBaseNaked09(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row01X,0.5]} />
            ]
          	)
	} else if (RCol9Row1 === 4) {
		setBaseNaked09(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
            ]
          	)
	} else if (RCol9Row1 === 5) {
		setBaseNaked09(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
            ]
          	)
	} else if (RCol9Row1 === 6) {
		setBaseNaked09(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row01X,0.5]} />
            ]
          	)
	} else if (RCol9Row1 === 7) {
		setBaseNaked09(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row01X,0.5]} />
            ]
          	)
	} else if (RCol9Row1 === 8) {
		setBaseNaked09(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
            ]
          	)
	} else if (RCol9Row1 === 9) {
		setBaseNaked09(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
            ]
          	)
	} else if (RCol9Row1 === 10) {
		setBaseNaked09(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row01X,0.5]} />
            ]
          	)
	} else if (RCol9Row1 === 11) {
		 setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
   
	} else if (RCol9Row1 === 12) {
 		setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 13) {
 		setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 14) {
 		setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 15) {
			setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 16) {
		setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 17) {
setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 18) {
		setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 19) {
		setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 20) {
setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 11.1) {
 		setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 12.1) {
		setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 13.1) {
setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 14.1) {
setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 15.1) {
setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 16.1) {
setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 17.1) {
setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 18.1) {
setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 19.1) {
setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 20.1) {
setBaseNakedM09(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 21) {
 setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 22) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 23) {
 setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 24) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 25) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 26) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 27) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 28) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 29) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
		]
          )
	} else if (RCol9Row1 === 30) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 21.1) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 22.1) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 23.1) {
 setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 24.1) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 25.1) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 26.1) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 27.1) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 28.1) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 29.1) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
 		]
          )
	} else if (RCol9Row1 === 30.1) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 21.3) {
 setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 22.3) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 23.3) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 24.3) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 25.3) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 26.3) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 27.3) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 28.3) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 29.3) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 30.3) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 21.5) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 22.5) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 23.5) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 24.5) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 25.5) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 26.5) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 27.5) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 28.5) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 29.5) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 30.5) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 21.4) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 22.4) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 23.4) {
 setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 24.4) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 25.4) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 26.4) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 27.4) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 28.4) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 29.4) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 30.4) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 21.6) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 22.6) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 23.6) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 24.6) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 25.6) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 26.6) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 27.6) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 28.6) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 29.6) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 30.6) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 21.8) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 22.8) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 23.8) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 24.8) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 25.8) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 26.8) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 27.8) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 28.8) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 29.8) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 30.8) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 21.9) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 22.9) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 23.9) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 24.9) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 25.9) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 26.9) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 27.9) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 28.9) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 29.9) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	} else if (RCol9Row1 === 30.9) {
setBaseNakedL09(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
            ]
          )
	}
}
const reloadc9r2 = (e) => {
	if (RCol9Row2 === 1) {
		setRow2Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row02X,0.5]} />
            ]
          	)

	} else if (RCol9Row2 === 2) {
		setRow2Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row02X,0.5]} />
            ]
          	)
	} else if (RCol9Row2 === 3) {
		setRow2Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row02X,0.5]} />
            ]
          	)
	} else if (RCol9Row2 === 4) {
		setRow2Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
            ]
          	)
	} else if (RCol9Row2 === 5) {
		setRow2Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
            ]
          	)
	} else if (RCol9Row2 === 6) {
		setRow2Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row02X,0.5]} />
            ]
          	)
	} else if (RCol9Row2 === 7) {
		setRow2Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row02X,0.5]} />
            ]
          	)
	} else if (RCol9Row2 === 8) {
		setRow2Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
            ]
          	)
	} else if (RCol9Row2 === 9) {
		setRow2Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
            ]
          	)
	} else if (RCol9Row2 === 10) {
		setRow2Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row02X,0.5]} />
            ]
          	)
	} else if (RCol9Row2 === 11) {
	if (RCol9Row1 < 11) {
		 setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
   }
	} else if (RCol9Row2 === 12) {
	if (RCol9Row1 < 11) {
 		setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol9Row2 === 13) {
	if (RCol9Row1 < 11) {
 		setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol9Row2 === 14) {
	if (RCol9Row1 < 11) {
 		setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol9Row2 === 15) {
	if (RCol9Row1 < 11) {
			setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol9Row2 === 16) {
	if (RCol9Row1 < 11) {
		setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol9Row2 === 17) {
	if (RCol9Row1 < 11) {
setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol9Row2 === 18) {
	if (RCol9Row1 < 11) {
		setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol9Row2 === 19) {
	if (RCol9Row1 < 11) {
		setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol9Row2 === 20) {
	if (RCol9Row1 < 11) {
setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol9Row2 === 11.1) {
	if (RCol9Row1 < 11) {
 		setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col09X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol9Row2 === 12.1) {
	if (RCol9Row1 < 11) {
		setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col09X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol9Row2 === 13.1) {
	if (RCol9Row1 < 11) {
setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col09X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol9Row2 === 14.1) {
	if (RCol9Row1 < 11) {
setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol9Row2 === 15.1) {
	if (RCol9Row1 < 11) {
setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol9Row2 === 16.1) {
	if (RCol9Row1 < 11) {
setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col09X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol9Row2 === 17.1) {
	if (RCol9Row1 < 11) {
setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol9Row2 === 18.1) {
	if (RCol9Row1 < 11) {
setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol9Row2 === 19.1) {
	if (RCol9Row1 < 11) {
setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol9Row2 === 20.1) {
	if (RCol9Row1 < 11) {
setRow2NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
            ]
          )
}
	} 
}

const reloadc9r3 = (e) => {
	if (RCol9Row3 === 1) {
		setRow3Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row03X,0.5]} />
            ]
          	)

	} else if (RCol9Row3 === 2) {
		setRow3Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row03X,0.5]} />
            ]
          	)
	} else if (RCol9Row3 === 3) {
		setRow3Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row03X,0.5]} />
            ]
          	)
	} else if (RCol9Row3 === 4) {
		setRow3Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
            ]
          	)
	} else if (RCol9Row3 === 5) {
		setRow3Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
            ]
          	)
	} else if (RCol9Row3 === 6) {
		setRow3Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row03X,0.5]} />
            ]
          	)
	} else if (RCol9Row3 === 7) {
		setRow3Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row03X,0.5]} />
            ]
          	)
	} else if (RCol9Row3 === 8) {
		setRow3Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
            ]
          	)
	} else if (RCol9Row3 === 9) {
		setRow3Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
            ]
          	)
	} else if (RCol9Row3 === 10) {
		setRow3Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row03X,0.5]} />
            ]
          	)
	} else if (RCol9Row3 === 11) {
	if (RCol9Row2 < 11 || RCol9Row1 > 10) {

		 setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
   }
	} else if (RCol9Row3 === 12) {
	if (RCol9Row2 < 11 || RCol9Row1 > 10) {
 		setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol9Row3 === 13) {
	if (RCol9Row2 < 11 || RCol9Row1 > 10) {
 		setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol9Row3 === 14) {
	if (RCol9Row2 < 11 || RCol9Row1 > 10) {
 		setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol9Row3 === 15) {
	if (RCol9Row2 < 11 || RCol9Row1 > 10) {
			setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol9Row3 === 16) {
	if (RCol9Row2 < 11 || RCol9Row1 > 10) {
		setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol9Row3 === 17) {
	if (RCol9Row2 < 11 || RCol9Row1 > 10) {
setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol9Row3 === 18) {
	if (RCol9Row2 < 11 || RCol9Row1 > 10) {
		setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol9Row3 === 19) {
	if (RCol9Row2 < 11 || RCol9Row1 > 10) {
		setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol9Row3 === 20) {
	if (RCol9Row2 < 11 || RCol9Row1 > 10) {
setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol9Row3 === 11.1) {
	if (RCol9Row2 < 11 || RCol9Row1 > 10) {
 		setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col09X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol9Row3 === 12.1) {
	if (RCol9Row2 < 11 || RCol9Row1 > 10) {
		setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col09X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol9Row3 === 13.1) {
	if (RCol9Row2 < 11 || RCol9Row1 > 10) {
setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col09X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol9Row3 === 14.1) {
	if (RCol9Row2 < 11 || RCol9Row1 > 10) {
setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol9Row3 === 15.1) {
	if (RCol9Row2 < 11 || RCol9Row1 > 10) {
setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol9Row3 === 16.1) {
	if (RCol9Row2 < 11 || RCol9Row1 > 10) {
setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col09X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol9Row3 === 17.1) {
	if (RCol9Row2 < 11 || RCol9Row1 > 10) {
setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol9Row3 === 18.1) {
	if (RCol9Row2 < 11 || RCol9Row1 > 10) {
setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol9Row3 === 19.1) {
	if (RCol9Row2 < 11 || RCol9Row1 > 10) {
setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol9Row3 === 20.1) {
	if (RCol9Row2 < 11 || RCol9Row1 > 10) {
setRow3NakedM09(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
            ]
          )
}
	} 
}

const reloadc9r4 = (e) => {
	if (RCol9Row4 === 1) {
		setRow4Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row04X,0.5]} />
            ]
          	)

	} else if (RCol9Row4 === 2) {
		setRow4Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col09X,row04X,0.5]} />
            ]
          	)
	} else if (RCol9Row4 === 3) {
		setRow4Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row04X,0.5]} />
            ]
          	)
	} else if (RCol9Row4 === 4) {
		setRow4Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row04X,0.5]} />
            ]
          	)
	} else if (RCol9Row4 === 5) {
		setRow4Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
            ]
          	)
	} else if (RCol9Row4 === 6) {
		setRow4Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col09X,row04X,0.5]} />
            ]
          	)
	} else if (RCol9Row4 === 7) {
		setRow4Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col09X,row04X,0.5]} />
            ]
          	)
	} else if (RCol9Row4 === 8) {
		setRow4Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
            ]
          	)
	} else if (RCol9Row4 === 9) {
		setRow4Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
            ]
          	)
	} else if (RCol9Row4 === 10) {
		setRow4Naked09(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col09X,row04X,0.5]} />
            ]
          	)
	} 
}

const reloadc10r1 = (e) => {
	if (RCol10Row1 === 1) {
		setBaseNaked10(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row01X,0.5]} />
            ]
          	)

	} else if (RCol10Row1 === 2) {
		setBaseNaked10(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row01X,0.5]} />
            ]
          	)
	} else if (RCol10Row1 === 3) {
		setBaseNaked10(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row01X,0.5]} />
            ]
          	)
	} else if (RCol10Row1 === 4) {
		setBaseNaked10(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
            ]
          	)
	} else if (RCol10Row1 === 5) {
		setBaseNaked10(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
            ]
          	)
	} else if (RCol10Row1 === 6) {
		setBaseNaked10(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row01X,0.5]} />
            ]
          	)
	} else if (RCol10Row1 === 7) {
		setBaseNaked10(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row01X,0.5]} />
            ]
          	)
	} else if (RCol10Row1 === 8) {
		setBaseNaked10(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
            ]
          	)
	} else if (RCol10Row1 === 9) {
		setBaseNaked10(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
            ]
          	)
	} else if (RCol10Row1 === 10) {
		setBaseNaked10(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row01X,0.5]} />
            ]
          	)
	} else if (RCol10Row1 === 11) {
		 setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
   
	} else if (RCol10Row1 === 12) {
 		setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 13) {
 		setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 14) {
 		setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 15) {
			setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 16) {
		setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 17) {
setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 18) {
		setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 19) {
		setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 20) {
setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 11.1) {
 		setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 12.1) {
		setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 13.1) {
setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 14.1) {
setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 15.1) {
setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 16.1) {
setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 17.1) {
setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 18.1) {
setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 19.1) {
setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 20.1) {
setBaseNakedM10(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 21) {
 setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 22) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 23) {
 setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 24) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 25) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 26) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 27) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 28) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 29) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
		]
          )
	} else if (RCol10Row1 === 30) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 21.1) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 22.1) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 23.1) {
 setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 24.1) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 25.1) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 26.1) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 27.1) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 28.1) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 29.1) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
 		]
          )
	} else if (RCol10Row1 === 30.1) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 21.3) {
 setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 22.3) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 23.3) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 24.3) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 25.3) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 26.3) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 27.3) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 28.3) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 29.3) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 30.3) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 21.5) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 22.5) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 23.5) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 24.5) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 25.5) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 26.5) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 27.5) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 28.5) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 29.5) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 30.5) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 21.4) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 22.4) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 23.4) {
 setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 24.4) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 25.4) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 26.4) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 27.4) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 28.4) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 29.4) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 30.4) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 21.6) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 22.6) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 23.6) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 24.6) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 25.6) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 26.6) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 27.6) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 28.6) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 29.6) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 30.6) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 21.8) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 22.8) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 23.8) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 24.8) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 25.8) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 26.8) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 27.8) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 28.8) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 29.8) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 30.8) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 21.9) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 22.9) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 23.9) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 24.9) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 25.9) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 26.9) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 27.9) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 28.9) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 29.9) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	} else if (RCol10Row1 === 30.9) {
setBaseNakedL10(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
            ]
          )
	}
}

const reloadc10r2 = (e) => {
	if (RCol10Row2 === 1) {
		setRow2Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row02X,0.5]} />
            ]
          	)

	} else if (RCol10Row2 === 2) {
		setRow2Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row02X,0.5]} />
            ]
          	)
	} else if (RCol10Row2 === 3) {
		setRow2Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row02X,0.5]} />
            ]
          	)
	} else if (RCol10Row2 === 4) {
		setRow2Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
            ]
          	)
	} else if (RCol10Row2 === 5) {
		setRow2Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
            ]
          	)
	} else if (RCol10Row2 === 6) {
		setRow2Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row02X,0.5]} />
            ]
          	)
	} else if (RCol10Row2 === 7) {
		setRow2Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row02X,0.5]} />
            ]
          	)
	} else if (RCol10Row2 === 8) {
		setRow2Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
            ]
          	)
	} else if (RCol10Row2 === 9) {
		setRow2Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
            ]
          	)
	} else if (RCol10Row2 === 10) {
		setRow2Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row02X,0.5]} />
            ]
          	)
	} else if (RCol10Row2 === 11) {
	if (RCol10Row1 < 11) {
		 setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
   }
	} else if (RCol10Row2 === 12) {
	if (RCol10Row1 < 11) {
 		setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol10Row2 === 13) {
	if (RCol10Row1 < 11) {
 		setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol10Row2 === 14) {
	if (RCol10Row1 < 11) {
 		setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol10Row2 === 15) {
	if (RCol10Row1 < 11) {
			setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol10Row2 === 16) {
	if (RCol10Row1 < 11) {
		setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol10Row2 === 17) {
	if (RCol10Row1 < 11) {
setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol10Row2 === 18) {
	if (RCol10Row1 < 11) {
		setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol10Row2 === 19) {
	if (RCol10Row1 < 11) {
		setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol10Row2 === 20) {
	if (RCol10Row1 < 11) {
setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol10Row2 === 11.1) {
	if (RCol10Row1 < 11) {
 		setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col10X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol10Row2 === 12.1) {
	if (RCol10Row1 < 11) {
		setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col10X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol10Row2 === 13.1) {
	if (RCol10Row1 < 11) {
setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col10X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol10Row2 === 14.1) {
	if (RCol10Row1 < 11) {
setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol10Row2 === 15.1) {
	if (RCol10Row1 < 11) {
setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol10Row2 === 16.1) {
	if (RCol10Row1 < 11) {
setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col10X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol10Row2 === 17.1) {
	if (RCol10Row1 < 11) {
setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol10Row2 === 18.1) {
	if (RCol10Row1 < 11) {
setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol10Row2 === 19.1) {
	if (RCol10Row1 < 11) {
setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol10Row2 === 20.1) {
	if (RCol10Row1 < 11) {
setRow2NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
            ]
          )
}
	} 
}

const reloadc10r3 = (e) => {
	if (RCol10Row3 === 1) {
		setRow3Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row03X,0.5]} />
            ]
          	)

	} else if (RCol10Row3 === 2) {
		setRow3Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row03X,0.5]} />
            ]
          	)
	} else if (RCol10Row3 === 3) {
		setRow3Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row03X,0.5]} />
            ]
          	)
	} else if (RCol10Row3 === 4) {
		setRow3Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
            ]
          	)
	} else if (RCol10Row3 === 5) {
		setRow3Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
            ]
          	)
	} else if (RCol10Row3 === 6) {
		setRow3Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row03X,0.5]} />
            ]
          	)
	} else if (RCol10Row3 === 7) {
		setRow3Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row03X,0.5]} />
            ]
          	)
	} else if (RCol10Row3 === 8) {
		setRow3Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
            ]
          	)
	} else if (RCol10Row3 === 9) {
		setRow3Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
            ]
          	)
	} else if (RCol10Row3 === 10) {
		setRow3Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row03X,0.5]} />
            ]
          	)
	} else if (RCol10Row3 === 11) {
	if (RCol10Row2 < 11 || RCol10Row1 > 10) {

		 setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
   }
	} else if (RCol10Row3 === 12) {
	if (RCol10Row2 < 11 || RCol10Row1 > 10) {
 		setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol10Row3 === 13) {
	if (RCol10Row2 < 11 || RCol10Row1 > 10) {
 		setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol10Row3 === 14) {
	if (RCol10Row2 < 11 || RCol10Row1 > 10) {
 		setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol10Row3 === 15) {
	if (RCol10Row2 < 11 || RCol10Row1 > 10) {
			setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol10Row3 === 16) {
	if (RCol10Row2 < 11 || RCol10Row1 > 10) {
		setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol10Row3 === 17) {
	if (RCol10Row2 < 11 || RCol10Row1 > 10) {
setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol10Row3 === 18) {
	if (RCol10Row2 < 11 || RCol10Row1 > 10) {
		setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol10Row3 === 19) {
	if (RCol10Row2 < 11 || RCol10Row1 > 10) {
		setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol10Row3 === 20) {
	if (RCol10Row2 < 11 || RCol10Row1 > 10) {
setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol10Row3 === 11.1) {
	if (RCol10Row2 < 11 || RCol10Row1 > 10) {
 		setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col10X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol10Row3 === 12.1) {
	if (RCol10Row2 < 11 || RCol10Row1 > 10) {
		setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col10X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol10Row3 === 13.1) {
	if (RCol10Row2 < 11 || RCol10Row1 > 10) {
setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col10X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol10Row3 === 14.1) {
	if (RCol10Row2 < 11 || RCol10Row1 > 10) {
setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol10Row3 === 15.1) {
	if (RCol10Row2 < 11 || RCol10Row1 > 10) {
setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol10Row3 === 16.1) {
	if (RCol10Row2 < 11 || RCol10Row1 > 10) {
setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col10X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol10Row3 === 17.1) {
	if (RCol10Row2 < 11 || RCol10Row1 > 10) {
setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol10Row3 === 18.1) {
	if (RCol10Row2 < 11 || RCol10Row1 > 10) {
setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol10Row3 === 19.1) {
	if (RCol10Row2 < 11 || RCol10Row1 > 10) {
setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol10Row3 === 20.1) {
	if (RCol10Row2 < 11 || RCol10Row1 > 10) {
setRow3NakedM10(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
            ]
          )
}
	} 
}

const reloadc10r4 = (e) => {
	if (RCol10Row4 === 1) {
		setRow4Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row04X,0.5]} />
            ]
          	)

	} else if (RCol10Row4 === 2) {
		setRow4Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col10X,row04X,0.5]} />
            ]
          	)
	} else if (RCol10Row4 === 3) {
		setRow4Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row04X,0.5]} />
            ]
          	)
	} else if (RCol10Row4 === 4) {
		setRow4Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row04X,0.5]} />
            ]
          	)
	} else if (RCol10Row4 === 5) {
		setRow4Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
            ]
          	)
	} else if (RCol10Row4 === 6) {
		setRow4Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col10X,row04X,0.5]} />
            ]
          	)
	} else if (RCol10Row4 === 7) {
		setRow4Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col10X,row04X,0.5]} />
            ]
          	)
	} else if (RCol10Row4 === 8) {
		setRow4Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
            ]
          	)
	} else if (RCol10Row4 === 9) {
		setRow4Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
            ]
          	)
	} else if (RCol10Row4 === 10) {
		setRow4Naked10(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col10X,row04X,0.5]} />
            ]
          	)
	} 
}

const reloadc11r1 = (e) => {
	if (RCol11Row1 === 1) {
		setBaseNaked11(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row01X,0.5]} />
            ]
          	)

	} else if (RCol11Row1 === 2) {
		setBaseNaked11(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row01X,0.5]} />
            ]
          	)
	} else if (RCol11Row1 === 3) {
		setBaseNaked11(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row01X,0.5]} />
            ]
          	)
	} else if (RCol11Row1 === 4) {
		setBaseNaked11(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
            ]
          	)
	} else if (RCol11Row1 === 5) {
		setBaseNaked11(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
            ]
          	)
	} else if (RCol11Row1 === 6) {
		setBaseNaked11(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row01X,0.5]} />
            ]
          	)
	} else if (RCol11Row1 === 7) {
		setBaseNaked11(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row01X,0.5]} />
            ]
          	)
	} else if (RCol11Row1 === 8) {
		setBaseNaked11(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
            ]
          	)
	} else if (RCol11Row1 === 9) {
		setBaseNaked11(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
            ]
          	)
	} else if (RCol11Row1 === 10) {
		setBaseNaked11(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row01X,0.5]} />
            ]
          	)
	} else if (RCol11Row1 === 11) {
		 setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
   
	} else if (RCol11Row1 === 12) {
 		setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 13) {
 		setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 14) {
 		setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 15) {
			setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 16) {
		setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 17) {
setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 18) {
		setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 19) {
		setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 20) {
setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 11.1) {
 		setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 12.1) {
		setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 13.1) {
setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 14.1) {
setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 15.1) {
setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 16.1) {
setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 17.1) {
setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 18.1) {
setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 19.1) {
setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 20.1) {
setBaseNakedM11(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 21) {
 setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 22) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 23) {
 setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 24) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 25) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 26) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 27) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 28) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 29) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
		]
          )
	} else if (RCol11Row1 === 30) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 21.1) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 22.1) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 23.1) {
 setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 24.1) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 25.1) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 26.1) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 27.1) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 28.1) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 29.1) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
 		]
          )
	} else if (RCol11Row1 === 30.1) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 21.3) {
 setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 22.3) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 23.3) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 24.3) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 25.3) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 26.3) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 27.3) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 28.3) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 29.3) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 30.3) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 21.5) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 22.5) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 23.5) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 24.5) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 25.5) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 26.5) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 27.5) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 28.5) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 29.5) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 30.5) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 21.4) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 22.4) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 23.4) {
 setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 24.4) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 25.4) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 26.4) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 27.4) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 28.4) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 29.4) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 30.4) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 21.6) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 22.6) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 23.6) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 24.6) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 25.6) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 26.6) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 27.6) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 28.6) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 29.6) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 30.6) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 21.8) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 22.8) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 23.8) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 24.8) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 25.8) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 26.8) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 27.8) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 28.8) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 29.8) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 30.8) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 21.9) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 22.9) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 23.9) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 24.9) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 25.9) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 26.9) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 27.9) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 28.9) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 29.9) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	} else if (RCol11Row1 === 30.9) {
setBaseNakedL11(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
            ]
          )
	}
}
const reloadc11r2 = (e) => {
	if (RCol11Row2 === 1) {
		setRow2Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row02X,0.5]} />
            ]
          	)

	} else if (RCol11Row2 === 2) {
		setRow2Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row02X,0.5]} />
            ]
          	)
	} else if (RCol11Row2 === 3) {
		setRow2Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row02X,0.5]} />
            ]
          	)
	} else if (RCol11Row2 === 4) {
		setRow2Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
            ]
          	)
	} else if (RCol11Row2 === 5) {
		setRow2Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
            ]
          	)
	} else if (RCol11Row2 === 6) {
		setRow2Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row02X,0.5]} />
            ]
          	)
	} else if (RCol11Row2 === 7) {
		setRow2Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row02X,0.5]} />
            ]
          	)
	} else if (RCol11Row2 === 8) {
		setRow2Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
            ]
          	)
	} else if (RCol11Row2 === 9) {
		setRow2Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
            ]
          	)
	} else if (RCol11Row2 === 10) {
		setRow2Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row02X,0.5]} />
            ]
          	)
	} else if (RCol11Row2 === 11) {
	if (RCol11Row1 < 11) {
		 setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
   }
	} else if (RCol11Row2 === 12) {
	if (RCol11Row1 < 11) {
 		setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol11Row2 === 13) {
	if (RCol11Row1 < 11) {
 		setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol11Row2 === 14) {
	if (RCol11Row1 < 11) {
 		setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol11Row2 === 15) {
	if (RCol11Row1 < 11) {
			setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol11Row2 === 16) {
	if (RCol11Row1 < 11) {
		setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol11Row2 === 17) {
	if (RCol11Row1 < 11) {
setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol11Row2 === 18) {
	if (RCol11Row1 < 11) {
		setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol11Row2 === 19) {
	if (RCol11Row1 < 11) {
		setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol11Row2 === 20) {
	if (RCol11Row1 < 11) {
setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol11Row2 === 11.1) {
	if (RCol11Row1 < 11) {
 		setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col11X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol11Row2 === 12.1) {
	if (RCol11Row1 < 11) {
		setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col11X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol11Row2 === 13.1) {
	if (RCol11Row1 < 11) {
setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col11X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol11Row2 === 14.1) {
	if (RCol11Row1 < 11) {
setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol11Row2 === 15.1) {
	if (RCol11Row1 < 11) {
setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol11Row2 === 16.1) {
	if (RCol11Row1 < 11) {
setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col11X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol11Row2 === 17.1) {
	if (RCol11Row1 < 11) {
setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol11Row2 === 18.1) {
	if (RCol11Row1 < 11) {
setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol11Row2 === 19.1) {
	if (RCol11Row1 < 11) {
setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol11Row2 === 20.1) {
	if (RCol11Row1 < 11) {
setRow2NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
            ]
          )
}
	} 
}

const reloadc11r3 = (e) => {
	if (RCol11Row3 === 1) {
		setRow3Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row03X,0.5]} />
            ]
          	)

	} else if (RCol11Row3 === 2) {
		setRow3Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row03X,0.5]} />
            ]
          	)
	} else if (RCol11Row3 === 3) {
		setRow3Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row03X,0.5]} />
            ]
          	)
	} else if (RCol11Row3 === 4) {
		setRow3Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
            ]
          	)
	} else if (RCol11Row3 === 5) {
		setRow3Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
            ]
          	)
	} else if (RCol11Row3 === 6) {
		setRow3Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row03X,0.5]} />
            ]
          	)
	} else if (RCol11Row3 === 7) {
		setRow3Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row03X,0.5]} />
            ]
          	)
	} else if (RCol11Row3 === 8) {
		setRow3Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
            ]
          	)
	} else if (RCol11Row3 === 9) {
		setRow3Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
            ]
          	)
	} else if (RCol11Row3 === 10) {
		setRow3Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row03X,0.5]} />
            ]
          	)
	} else if (RCol11Row3 === 11) {
	if (RCol11Row2 < 11 || RCol11Row1 > 10) {

		 setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
   }
	} else if (RCol11Row3 === 12) {
	if (RCol11Row2 < 11 || RCol11Row1 > 10) {
 		setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol11Row3 === 13) {
	if (RCol11Row2 < 11 || RCol11Row1 > 10) {
 		setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol11Row3 === 14) {
	if (RCol11Row2 < 11 || RCol11Row1 > 10) {
 		setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol11Row3 === 15) {
	if (RCol11Row2 < 11 || RCol11Row1 > 10) {
			setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol11Row3 === 16) {
	if (RCol11Row2 < 11 || RCol11Row1 > 10) {
		setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol11Row3 === 17) {
	if (RCol11Row2 < 11 || RCol11Row1 > 10) {
setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol11Row3 === 18) {
	if (RCol11Row2 < 11 || RCol11Row1 > 10) {
		setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol11Row3 === 19) {
	if (RCol11Row2 < 11 || RCol11Row1 > 10) {
		setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol11Row3 === 20) {
	if (RCol11Row2 < 11 || RCol11Row1 > 10) {
setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol11Row3 === 11.1) {
	if (RCol11Row2 < 11 || RCol11Row1 > 10) {
 		setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col11X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol11Row3 === 12.1) {
	if (RCol11Row2 < 11 || RCol11Row1 > 10) {
		setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col11X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol11Row3 === 13.1) {
	if (RCol11Row2 < 11 || RCol11Row1 > 10) {
setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col11X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol11Row3 === 14.1) {
	if (RCol11Row2 < 11 || RCol11Row1 > 10) {
setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol11Row3 === 15.1) {
	if (RCol11Row2 < 11 || RCol11Row1 > 10) {
setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol11Row3 === 16.1) {
	if (RCol11Row2 < 11 || RCol11Row1 > 10) {
setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col11X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol11Row3 === 17.1) {
	if (RCol11Row2 < 11 || RCol11Row1 > 10) {
setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol11Row3 === 18.1) {
	if (RCol11Row2 < 11 || RCol11Row1 > 10) {
setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol11Row3 === 19.1) {
	if (RCol11Row2 < 11 || RCol11Row1 > 10) {
setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol11Row3 === 20.1) {
	if (RCol11Row2 < 11 || RCol11Row1 > 10) {
setRow3NakedM11(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
            ]
          )
}
	} 
}

const reloadc11r4 = (e) => {
	if (RCol11Row4 === 1) {
		setRow4Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row04X,0.5]} />
            ]
          	)

	} else if (RCol11Row4 === 2) {
		setRow4Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col11X,row04X,0.5]} />
            ]
          	)
	} else if (RCol11Row4 === 3) {
		setRow4Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row04X,0.5]} />
            ]
          	)
	} else if (RCol11Row4 === 4) {
		setRow4Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row04X,0.5]} />
            ]
          	)
	} else if (RCol11Row4 === 5) {
		setRow4Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
            ]
          	)
	} else if (RCol11Row4 === 6) {
		setRow4Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col11X,row04X,0.5]} />
            ]
          	)
	} else if (RCol11Row4 === 7) {
		setRow4Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col11X,row04X,0.5]} />
            ]
          	)
	} else if (RCol11Row4 === 8) {
		setRow4Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
            ]
          	)
	} else if (RCol11Row4 === 9) {
		setRow4Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
            ]
          	)
	} else if (RCol11Row4 === 10) {
		setRow4Naked11(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col11X,row04X,0.5]} />
            ]
          	)
	} 
}

const reloadc12r1 = (e) => {
	if (RCol12Row1 === 1) {
		setBaseNaked12(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row01X,0.5]} />
            ]
          	)

	} else if (RCol12Row1 === 2) {
		setBaseNaked12(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row01X,0.5]} />
            ]
          	)
	} else if (RCol12Row1 === 3) {
		setBaseNaked12(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row01X,0.5]} />
            ]
          	)
	} else if (RCol12Row1 === 4) {
		setBaseNaked12(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
            ]
          	)
	} else if (RCol12Row1 === 5) {
		setBaseNaked12(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
            ]
          	)
	} else if (RCol12Row1 === 6) {
		setBaseNaked12(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row01X,0.5]} />
            ]
          	)
	} else if (RCol12Row1 === 7) {
		setBaseNaked12(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row01X,0.5]} />
            ]
          	)
	} else if (RCol12Row1 === 8) {
		setBaseNaked12(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
            ]
          	)
	} else if (RCol12Row1 === 9) {
		setBaseNaked12(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
            ]
          	)
	} else if (RCol12Row1 === 10) {
		setBaseNaked12(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row01X,0.5]} />
            ]
          	)
	} else if (RCol12Row1 === 11) {
		 setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
   
	} else if (RCol12Row1 === 12) {
 		setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 13) {
 		setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 14) {
 		setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 15) {
			setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 16) {
		setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 17) {
setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 18) {
		setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 19) {
		setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 20) {
setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 11.1) {
 		setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 12.1) {
		setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 13.1) {
setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 14.1) {
setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 15.1) {
setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 16.1) {
setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 17.1) {
setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 18.1) {
setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 19.1) {
setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 20.1) {
setBaseNakedM12(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 21) {
 setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 22) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 23) {
 setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 24) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 25) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 26) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 27) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 28) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 29) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
		]
          )
	} else if (RCol12Row1 === 30) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 21.1) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 22.1) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 23.1) {
 setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 24.1) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 25.1) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 26.1) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 27.1) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 28.1) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 29.1) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
 		]
          )
	} else if (RCol12Row1 === 30.1) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 21.3) {
 setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 22.3) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 23.3) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 24.3) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 25.3) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 26.3) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 27.3) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 28.3) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 29.3) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 30.3) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 21.5) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 22.5) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 23.5) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 24.5) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 25.5) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 26.5) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 27.5) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 28.5) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 29.5) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 30.5) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 21.4) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 22.4) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 23.4) {
 setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 24.4) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 25.4) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 26.4) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 27.4) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 28.4) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 29.4) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 30.4) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 21.6) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 22.6) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 23.6) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 24.6) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 25.6) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 26.6) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 27.6) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 28.6) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 29.6) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 30.6) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 21.8) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 22.8) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 23.8) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 24.8) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 25.8) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 26.8) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 27.8) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 28.8) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 29.8) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 30.8) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 21.9) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 22.9) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 23.9) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 24.9) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 25.9) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 26.9) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 27.9) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 28.9) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 29.9) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	} else if (RCol12Row1 === 30.9) {
setBaseNakedL12(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
            ]
          )
	}
}

const reloadc12r2 = (e) => {
	if (RCol12Row2 === 1) {
		setRow2Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row02X,0.5]} />
            ]
          	)

	} else if (RCol12Row2 === 2) {
		setRow2Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row02X,0.5]} />
            ]
          	)
	} else if (RCol12Row2 === 3) {
		setRow2Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row02X,0.5]} />
            ]
          	)
	} else if (RCol12Row2 === 4) {
		setRow2Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
            ]
          	)
	} else if (RCol12Row2 === 5) {
		setRow2Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
            ]
          	)
	} else if (RCol12Row2 === 6) {
		setRow2Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row02X,0.5]} />
            ]
          	)
	} else if (RCol12Row2 === 7) {
		setRow2Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row02X,0.5]} />
            ]
          	)
	} else if (RCol12Row2 === 8) {
		setRow2Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
            ]
          	)
	} else if (RCol12Row2 === 9) {
		setRow2Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
            ]
          	)
	} else if (RCol12Row2 === 10) {
		setRow2Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row02X,0.5]} />
            ]
          	)
	} else if (RCol12Row2 === 11) {
	if (RCol12Row1 < 11) {
		 setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
   }
	} else if (RCol12Row2 === 12) {
	if (RCol12Row1 < 11) {
 		setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol12Row2 === 13) {
	if (RCol12Row1 < 11) {
 		setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol12Row2 === 14) {
	if (RCol12Row1 < 11) {
 		setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol12Row2 === 15) {
	if (RCol12Row1 < 11) {
			setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol12Row2 === 16) {
	if (RCol12Row1 < 11) {
		setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol12Row2 === 17) {
	if (RCol12Row1 < 11) {
setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol12Row2 === 18) {
	if (RCol12Row1 < 11) {
		setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol12Row2 === 19) {
	if (RCol12Row1 < 11) {
		setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol12Row2 === 20) {
	if (RCol12Row1 < 11) {
setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol12Row2 === 11.1) {
	if (RCol12Row1 < 11) {
 		setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col12X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol12Row2 === 12.1) {
	if (RCol12Row1 < 11) {
		setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col12X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol12Row2 === 13.1) {
	if (RCol12Row1 < 11) {
setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col12X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol12Row2 === 14.1) {
	if (RCol12Row1 < 11) {
setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol12Row2 === 15.1) {
	if (RCol12Row1 < 11) {
setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol12Row2 === 16.1) {
	if (RCol12Row1 < 11) {
setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col12X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol12Row2 === 17.1) {
	if (RCol12Row1 < 11) {
setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol12Row2 === 18.1) {
	if (RCol12Row1 < 11) {
setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol12Row2 === 19.1) {
	if (RCol12Row1 < 11) {
setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol12Row2 === 20.1) {
	if (RCol12Row1 < 11) {
setRow2NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
            ]
          )
}
	} 
}

const reloadc12r3 = (e) => {
	if (RCol12Row3 === 1) {
		setRow3Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row03X,0.5]} />
            ]
          	)

	} else if (RCol12Row3 === 2) {
		setRow3Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row03X,0.5]} />
            ]
          	)
	} else if (RCol12Row3 === 3) {
		setRow3Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row03X,0.5]} />
            ]
          	)
	} else if (RCol12Row3 === 4) {
		setRow3Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
            ]
          	)
	} else if (RCol12Row3 === 5) {
		setRow3Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
            ]
          	)
	} else if (RCol12Row3 === 6) {
		setRow3Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row03X,0.5]} />
            ]
          	)
	} else if (RCol12Row3 === 7) {
		setRow3Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row03X,0.5]} />
            ]
          	)
	} else if (RCol12Row3 === 8) {
		setRow3Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
            ]
          	)
	} else if (RCol12Row3 === 9) {
		setRow3Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
            ]
          	)
	} else if (RCol12Row3 === 10) {
		setRow3Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row03X,0.5]} />
            ]
          	)
	} else if (RCol12Row3 === 11) {
	if (RCol12Row2 < 11 || RCol12Row1 > 10) {

		 setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
   }
	} else if (RCol12Row3 === 12) {
	if (RCol12Row2 < 11 || RCol12Row1 > 10) {
 		setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol12Row3 === 13) {
	if (RCol12Row2 < 11 || RCol12Row1 > 10) {
 		setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol12Row3 === 14) {
	if (RCol12Row2 < 11 || RCol12Row1 > 10) {
 		setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol12Row3 === 15) {
	if (RCol12Row2 < 11 || RCol12Row1 > 10) {
			setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol12Row3 === 16) {
	if (RCol12Row2 < 11 || RCol12Row1 > 10) {
		setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol12Row3 === 17) {
	if (RCol12Row2 < 11 || RCol12Row1 > 10) {
setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol12Row3 === 18) {
	if (RCol12Row2 < 11 || RCol12Row1 > 10) {
		setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol12Row3 === 19) {
	if (RCol12Row2 < 11 || RCol12Row1 > 10) {
		setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol12Row3 === 20) {
	if (RCol12Row2 < 11 || RCol12Row1 > 10) {
setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol12Row3 === 11.1) {
	if (RCol12Row2 < 11 || RCol12Row1 > 10) {
 		setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col12X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol12Row3 === 12.1) {
	if (RCol12Row2 < 11 || RCol12Row1 > 10) {
		setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col12X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol12Row3 === 13.1) {
	if (RCol12Row2 < 11 || RCol12Row1 > 10) {
setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col12X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol12Row3 === 14.1) {
	if (RCol12Row2 < 11 || RCol12Row1 > 10) {
setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol12Row3 === 15.1) {
	if (RCol12Row2 < 11 || RCol12Row1 > 10) {
setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol12Row3 === 16.1) {
	if (RCol12Row2 < 11 || RCol12Row1 > 10) {
setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col12X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol12Row3 === 17.1) {
	if (RCol12Row2 < 11 || RCol12Row1 > 10) {
setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol12Row3 === 18.1) {
	if (RCol12Row2 < 11 || RCol12Row1 > 10) {
setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol12Row3 === 19.1) {
	if (RCol12Row2 < 11 || RCol12Row1 > 10) {
setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol12Row3 === 20.1) {
	if (RCol12Row2 < 11 || RCol12Row1 > 10) {
setRow3NakedM12(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
            ]
          )
}
	} 
}

const reloadc12r4 = (e) => {
	if (RCol12Row4 === 1) {
		setRow4Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row04X,0.5]} />
            ]
          	)

	} else if (RCol12Row4 === 2) {
		setRow4Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col12X,row04X,0.5]} />
            ]
          	)
	} else if (RCol12Row4 === 3) {
		setRow4Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row04X,0.5]} />
            ]
          	)
	} else if (RCol12Row4 === 4) {
		setRow4Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row04X,0.5]} />
            ]
          	)
	} else if (RCol12Row4 === 5) {
		setRow4Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
            ]
          	)
	} else if (RCol12Row4 === 6) {
		setRow4Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col12X,row04X,0.5]} />
            ]
          	)
	} else if (RCol12Row4 === 7) {
		setRow4Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col12X,row04X,0.5]} />
            ]
          	)
	} else if (RCol12Row4 === 8) {
		setRow4Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
            ]
          	)
	} else if (RCol12Row4 === 9) {
		setRow4Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
            ]
          	)
	} else if (RCol12Row4 === 10) {
		setRow4Naked12(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col12X,row04X,0.5]} />
            ]
          	)
	} 
}

const reloadc13r1 = (e) => {
	if (RCol13Row1 === 1) {
		setBaseNaked13(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row01X,0.5]} />
            ]
          	)

	} else if (RCol13Row1 === 2) {
		setBaseNaked13(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row01X,0.5]} />
            ]
          	)
	} else if (RCol13Row1 === 3) {
		setBaseNaked13(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row01X,0.5]} />
            ]
          	)
	} else if (RCol13Row1 === 4) {
		setBaseNaked13(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
            ]
          	)
	} else if (RCol13Row1 === 5) {
		setBaseNaked13(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
            ]
          	)
	} else if (RCol13Row1 === 6) {
		setBaseNaked13(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row01X,0.5]} />
            ]
          	)
	} else if (RCol13Row1 === 7) {
		setBaseNaked13(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row01X,0.5]} />
            ]
          	)
	} else if (RCol13Row1 === 8) {
		setBaseNaked13(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
            ]
          	)
	} else if (RCol13Row1 === 9) {
		setBaseNaked13(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
            ]
          	)
	} else if (RCol13Row1 === 10) {
		setBaseNaked13(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row01X,0.5]} />
            ]
          	)
	} else if (RCol13Row1 === 11) {
		 setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
   
	} else if (RCol13Row1 === 12) {
 		setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 13) {
 		setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 14) {
 		setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 15) {
			setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 16) {
		setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 17) {
setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 18) {
		setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 19) {
		setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 20) {
setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 11.1) {
 		setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 12.1) {
		setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 13.1) {
setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 14.1) {
setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 15.1) {
setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 16.1) {
setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 17.1) {
setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 18.1) {
setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 19.1) {
setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 20.1) {
setBaseNakedM13(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 21) {
 setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 22) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 23) {
 setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 24) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 25) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 26) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 27) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 28) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 29) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
		]
          )
	} else if (RCol13Row1 === 30) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 21.1) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 22.1) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 23.1) {
 setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 24.1) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 25.1) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 26.1) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 27.1) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 28.1) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 29.1) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
 		]
          )
	} else if (RCol13Row1 === 30.1) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 21.3) {
 setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 22.3) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 23.3) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 24.3) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 25.3) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 26.3) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 27.3) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 28.3) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 29.3) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 30.3) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 21.5) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 22.5) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 23.5) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 24.5) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 25.5) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 26.5) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 27.5) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 28.5) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 29.5) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 30.5) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 21.4) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 22.4) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 23.4) {
 setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 24.4) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 25.4) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 26.4) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 27.4) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 28.4) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 29.4) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 30.4) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 21.6) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 22.6) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 23.6) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 24.6) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 25.6) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 26.6) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 27.6) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 28.6) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 29.6) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 30.6) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 21.8) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 22.8) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 23.8) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 24.8) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 25.8) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 26.8) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 27.8) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 28.8) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 29.8) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 30.8) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 21.9) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 22.9) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 23.9) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 24.9) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 25.9) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 26.9) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 27.9) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 28.9) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 29.9) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	} else if (RCol13Row1 === 30.9) {
setBaseNakedL13(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
            ]
          )
	}
}

const reloadc13r2 = (e) => {
	if (RCol13Row2 === 1) {
		setRow2Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row02X,0.5]} />
            ]
          	)

	} else if (RCol13Row2 === 2) {
		setRow2Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row02X,0.5]} />
            ]
          	)
	} else if (RCol13Row2 === 3) {
		setRow2Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row02X,0.5]} />
            ]
          	)
	} else if (RCol13Row2 === 4) {
		setRow2Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
            ]
          	)
	} else if (RCol13Row2 === 5) {
		setRow2Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
            ]
          	)
	} else if (RCol13Row2 === 6) {
		setRow2Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row02X,0.5]} />
            ]
          	)
	} else if (RCol13Row2 === 7) {
		setRow2Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row02X,0.5]} />
            ]
          	)
	} else if (RCol13Row2 === 8) {
		setRow2Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
            ]
          	)
	} else if (RCol13Row2 === 9) {
		setRow2Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
            ]
          	)
	} else if (RCol13Row2 === 10) {
		setRow2Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row02X,0.5]} />
            ]
          	)
	} else if (RCol13Row2 === 11) {
	if (RCol13Row1 < 11) {
		 setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
   }
	} else if (RCol13Row2 === 12) {
	if (RCol13Row1 < 11) {
 		setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol13Row2 === 13) {
	if (RCol13Row1 < 11) {
 		setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol13Row2 === 14) {
	if (RCol13Row1 < 11) {
 		setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol13Row2 === 15) {
	if (RCol13Row1 < 11) {
			setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol13Row2 === 16) {
	if (RCol13Row1 < 11) {
		setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol13Row2 === 17) {
	if (RCol13Row1 < 11) {
setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol13Row2 === 18) {
	if (RCol13Row1 < 11) {
		setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol13Row2 === 19) {
	if (RCol13Row1 < 11) {
		setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol13Row2 === 20) {
	if (RCol13Row1 < 11) {
setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol13Row2 === 11.1) {
	if (RCol13Row1 < 11) {
 		setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col13X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol13Row2 === 12.1) {
	if (RCol13Row1 < 11) {
		setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col13X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol13Row2 === 13.1) {
	if (RCol13Row1 < 11) {
setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col13X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol13Row2 === 14.1) {
	if (RCol13Row1 < 11) {
setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol13Row2 === 15.1) {
	if (RCol13Row1 < 11) {
setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol13Row2 === 16.1) {
	if (RCol13Row1 < 11) {
setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col13X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol13Row2 === 17.1) {
	if (RCol13Row1 < 11) {
setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol13Row2 === 18.1) {
	if (RCol13Row1 < 11) {
setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol13Row2 === 19.1) {
	if (RCol13Row1 < 11) {
setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol13Row2 === 20.1) {
	if (RCol13Row1 < 11) {
setRow2NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
            ]
          )
}
	} 
}
const reloadc13r3 = (e) => {
	if (RCol13Row3 === 1) {
		setRow3Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row03X,0.5]} />
            ]
          	)

	} else if (RCol13Row3 === 2) {
		setRow3Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row03X,0.5]} />
            ]
          	)
	} else if (RCol13Row3 === 3) {
		setRow3Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row03X,0.5]} />
            ]
          	)
	} else if (RCol13Row3 === 4) {
		setRow3Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
            ]
          	)
	} else if (RCol13Row3 === 5) {
		setRow3Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
            ]
          	)
	} else if (RCol13Row3 === 6) {
		setRow3Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row03X,0.5]} />
            ]
          	)
	} else if (RCol13Row3 === 7) {
		setRow3Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row03X,0.5]} />
            ]
          	)
	} else if (RCol13Row3 === 8) {
		setRow3Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
            ]
          	)
	} else if (RCol13Row3 === 9) {
		setRow3Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
            ]
          	)
	} else if (RCol13Row3 === 10) {
		setRow3Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row03X,0.5]} />
            ]
          	)
	} else if (RCol13Row3 === 11) {
	if (RCol13Row2 < 11 || RCol13Row1 > 10) {

		 setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
   }
	} else if (RCol13Row3 === 12) {
	if (RCol13Row2 < 11 || RCol13Row1 > 10) {
 		setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol13Row3 === 13) {
	if (RCol13Row2 < 11 || RCol13Row1 > 10) {
 		setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol13Row3 === 14) {
	if (RCol13Row2 < 11 || RCol13Row1 > 10) {
 		setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol13Row3 === 15) {
	if (RCol13Row2 < 11 || RCol13Row1 > 10) {
			setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol13Row3 === 16) {
	if (RCol13Row2 < 11 || RCol13Row1 > 10) {
		setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol13Row3 === 17) {
	if (RCol13Row2 < 11 || RCol13Row1 > 10) {
setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol13Row3 === 18) {
	if (RCol13Row2 < 11 || RCol13Row1 > 10) {
		setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol13Row3 === 19) {
	if (RCol13Row2 < 11 || RCol13Row1 > 10) {
		setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol13Row3 === 20) {
	if (RCol13Row2 < 11 || RCol13Row1 > 10) {
setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol13Row3 === 11.1) {
	if (RCol13Row2 < 11 || RCol13Row1 > 10) {
 		setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col13X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol13Row3 === 12.1) {
	if (RCol13Row2 < 11 || RCol13Row1 > 10) {
		setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col13X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol13Row3 === 13.1) {
	if (RCol13Row2 < 11 || RCol13Row1 > 10) {
setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col13X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol13Row3 === 14.1) {
	if (RCol13Row2 < 11 || RCol13Row1 > 10) {
setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol13Row3 === 15.1) {
	if (RCol13Row2 < 11 || RCol13Row1 > 10) {
setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol13Row3 === 16.1) {
	if (RCol13Row2 < 11 || RCol13Row1 > 10) {
setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col13X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol13Row3 === 17.1) {
	if (RCol13Row2 < 11 || RCol13Row1 > 10) {
setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol13Row3 === 18.1) {
	if (RCol13Row2 < 11 || RCol13Row1 > 10) {
setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol13Row3 === 19.1) {
	if (RCol13Row2 < 11 || RCol13Row1 > 10) {
setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol13Row3 === 20.1) {
	if (RCol13Row2 < 11 || RCol13Row1 > 10) {
setRow3NakedM13(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
            ]
          )
}
	} 
}

const reloadc13r4 = (e) => {
	if (RCol13Row4 === 1) {
		setRow4Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row04X,0.5]} />
            ]
          	)

	} else if (RCol13Row4 === 2) {
		setRow4Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col13X,row04X,0.5]} />
            ]
          	)
	} else if (RCol13Row4 === 3) {
		setRow4Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row04X,0.5]} />
            ]
          	)
	} else if (RCol13Row4 === 4) {
		setRow4Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row04X,0.5]} />
            ]
          	)
	} else if (RCol13Row4 === 5) {
		setRow4Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
            ]
          	)
	} else if (RCol13Row4 === 6) {
		setRow4Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col13X,row04X,0.5]} />
            ]
          	)
	} else if (RCol13Row4 === 7) {
		setRow4Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col13X,row04X,0.5]} />
            ]
          	)
	} else if (RCol13Row4 === 8) {
		setRow4Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
            ]
          	)
	} else if (RCol13Row4 === 9) {
		setRow4Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
            ]
          	)
	} else if (RCol13Row4 === 10) {
		setRow4Naked13(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col13X,row04X,0.5]} />
            ]
          	)
	} 
}

const reloadc14r1 = (e) => {
	if (RCol14Row1 === 1) {
		setBaseNaked14(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row01X,0.5]} />
            ]
          	)

	} else if (RCol14Row1 === 2) {
		setBaseNaked14(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row01X,0.5]} />
            ]
          	)
	} else if (RCol14Row1 === 3) {
		setBaseNaked14(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row01X,0.5]} />
            ]
          	)
	} else if (RCol14Row1 === 4) {
		setBaseNaked14(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
            ]
          	)
	} else if (RCol14Row1 === 5) {
		setBaseNaked14(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
            ]
          	)
	} else if (RCol14Row1 === 6) {
		setBaseNaked14(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row01X,0.5]} />
            ]
          	)
	} else if (RCol14Row1 === 7) {
		setBaseNaked14(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row01X,0.5]} />
            ]
          	)
	} else if (RCol14Row1 === 8) {
		setBaseNaked14(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
            ]
          	)
	} else if (RCol14Row1 === 9) {
		setBaseNaked14(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
            ]
          	)
	} else if (RCol14Row1 === 10) {
		setBaseNaked14(
            [
              <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row01X,0.5]} />
            ]
          	)
	} else if (RCol14Row1 === 11) {
		 setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
   
	} else if (RCol14Row1 === 12) {
 		setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 13) {
 		setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 14) {
 		setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 15) {
			setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 16) {
		setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 17) {
setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 18) {
		setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 19) {
		setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 20) {
setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 11.1) {
 		setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 12.1) {
		setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 13.1) {
setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 14.1) {
setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 15.1) {
setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 16.1) {
setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 17.1) {
setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 18.1) {
setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 19.1) {
setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 20.1) {
setBaseNakedM14(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 21) {
 setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 22) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 23) {
 setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 24) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 25) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 26) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 27) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 28) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 29) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
		]
          )
	} else if (RCol14Row1 === 30) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 21.1) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 22.1) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 23.1) {
 setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 24.1) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 25.1) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 26.1) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 27.1) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 28.1) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 29.1) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
 		]
          )
	} else if (RCol14Row1 === 30.1) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 21.3) {
 setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 22.3) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 23.3) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 24.3) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 25.3) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 26.3) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 27.3) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 28.3) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 29.3) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 30.3) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 21.5) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 22.5) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 23.5) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 24.5) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 25.5) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 26.5) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 27.5) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 28.5) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 29.5) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 30.5) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 21.4) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 22.4) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 23.4) {
 setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 24.4) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 25.4) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 26.4) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 27.4) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 28.4) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 29.4) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 30.4) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 21.6) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 22.6) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 23.6) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 24.6) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 25.6) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 26.6) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 27.6) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 28.6) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 29.6) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 30.6) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 21.8) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 22.8) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 23.8) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 24.8) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 25.8) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 26.8) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 27.8) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 28.8) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 29.8) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 30.8) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 21.9) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 22.9) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 23.9) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 24.9) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 25.9) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 26.9) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 27.9) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 28.9) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 29.9) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	} else if (RCol14Row1 === 30.9) {
setBaseNakedL14(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
            ]
          )
	}
}

const reloadc14r2 = (e) => {
	if (RCol14Row2 === 1) {
		setRow2Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row02X,0.5]} />
            ]
          	)

	} else if (RCol14Row2 === 2) {
		setRow2Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row02X,0.5]} />
            ]
          	)
	} else if (RCol14Row2 === 3) {
		setRow2Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row02X,0.5]} />
            ]
          	)
	} else if (RCol14Row2 === 4) {
		setRow2Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
            ]
          	)
	} else if (RCol14Row2 === 5) {
		setRow2Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
            ]
          	)
	} else if (RCol14Row2 === 6) {
		setRow2Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row02X,0.5]} />
            ]
          	)
	} else if (RCol14Row2 === 7) {
		setRow2Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row02X,0.5]} />
            ]
          	)
	} else if (RCol14Row2 === 8) {
		setRow2Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
            ]
          	)
	} else if (RCol14Row2 === 9) {
		setRow2Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
            ]
          	)
	} else if (RCol14Row2 === 10) {
		setRow2Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row02X,0.5]} />
            ]
          	)
	} else if (RCol14Row2 === 11) {
	if (RCol14Row1 < 11) {
		 setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
   }
	} else if (RCol14Row2 === 12) {
	if (RCol14Row1 < 11) {
 		setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol14Row2 === 13) {
	if (RCol14Row1 < 11) {
 		setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol14Row2 === 14) {
	if (RCol14Row1 < 11) {
 		setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol14Row2 === 15) {
	if (RCol14Row1 < 11) {
			setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol14Row2 === 16) {
	if (RCol14Row1 < 11) {
		setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol14Row2 === 17) {
	if (RCol14Row1 < 11) {
setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol14Row2 === 18) {
	if (RCol14Row1 < 11) {
		setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol14Row2 === 19) {
	if (RCol14Row1 < 11) {
		setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol14Row2 === 20) {
	if (RCol14Row1 < 11) {
setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol14Row2 === 11.1) {
	if (RCol14Row1 < 11) {
 		setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col14X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol14Row2 === 12.1) {
	if (RCol14Row1 < 11) {
		setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col14X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol14Row2 === 13.1) {
	if (RCol14Row1 < 11) {
setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col14X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol14Row2 === 14.1) {
	if (RCol14Row1 < 11) {
setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol14Row2 === 15.1) {
	if (RCol14Row1 < 11) {
setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol14Row2 === 16.1) {
	if (RCol14Row1 < 11) {
setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col14X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol14Row2 === 17.1) {
	if (RCol14Row1 < 11) {
setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol14Row2 === 18.1) {
	if (RCol14Row1 < 11) {
setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol14Row2 === 19.1) {
	if (RCol14Row1 < 11) {
setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol14Row2 === 20.1) {
	if (RCol14Row1 < 11) {
setRow2NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
            ]
          )
}
	} 
}

const reloadc14r3 = (e) => {
	if (RCol14Row3 === 1) {
		setRow3Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row03X,0.5]} />
            ]
          	)

	} else if (RCol14Row3 === 2) {
		setRow3Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row03X,0.5]} />
            ]
          	)
	} else if (RCol14Row3 === 3) {
		setRow3Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row03X,0.5]} />
            ]
          	)
	} else if (RCol14Row3 === 4) {
		setRow3Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
            ]
          	)
	} else if (RCol14Row3 === 5) {
		setRow3Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
            ]
          	)
	} else if (RCol14Row3 === 6) {
		setRow3Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row03X,0.5]} />
            ]
          	)
	} else if (RCol14Row3 === 7) {
		setRow3Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row03X,0.5]} />
            ]
          	)
	} else if (RCol14Row3 === 8) {
		setRow3Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
            ]
          	)
	} else if (RCol14Row3 === 9) {
		setRow3Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
            ]
          	)
	} else if (RCol14Row3 === 10) {
		setRow3Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row03X,0.5]} />
            ]
          	)
	} else if (RCol14Row3 === 11) {
	if (RCol14Row2 < 11 || RCol14Row1 > 10) {

		 setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
   }
	} else if (RCol14Row3 === 12) {
	if (RCol14Row2 < 11 || RCol14Row1 > 10) {
 		setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol14Row3 === 13) {
	if (RCol14Row2 < 11 || RCol14Row1 > 10) {
 		setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol14Row3 === 14) {
	if (RCol14Row2 < 11 || RCol14Row1 > 10) {
 		setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol14Row3 === 15) {
	if (RCol14Row2 < 11 || RCol14Row1 > 10) {
			setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol14Row3 === 16) {
	if (RCol14Row2 < 11 || RCol14Row1 > 10) {
		setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol14Row3 === 17) {
	if (RCol14Row2 < 11 || RCol14Row1 > 10) {
setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol14Row3 === 18) {
	if (RCol14Row2 < 11 || RCol14Row1 > 10) {
		setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol14Row3 === 19) {
	if (RCol14Row2 < 11 || RCol14Row1 > 10) {
		setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol14Row3 === 20) {
	if (RCol14Row2 < 11 || RCol14Row1 > 10) {
setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol14Row3 === 11.1) {
	if (RCol14Row2 < 11 || RCol14Row1 > 10) {
 		setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col14X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol14Row3 === 12.1) {
	if (RCol14Row2 < 11 || RCol14Row1 > 10) {
		setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false, seperatorOn:true}} position={[col14X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol14Row3 === 13.1) {
	if (RCol14Row2 < 11 || RCol14Row1 > 10) {
setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col14X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol14Row3 === 14.1) {
	if (RCol14Row2 < 11 || RCol14Row1 > 10) {
setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol14Row3 === 15.1) {
	if (RCol14Row2 < 11 || RCol14Row1 > 10) {
setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol14Row3 === 16.1) {
	if (RCol14Row2 < 11 || RCol14Row1 > 10) {
setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false, seperatorOn:true}} position={[col14X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol14Row3 === 17.1) {
	if (RCol14Row2 < 11 || RCol14Row1 > 10) {
setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol14Row3 === 18.1) {
	if (RCol14Row2 < 11 || RCol14Row1 > 10) {
setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol14Row3 === 19.1) {
	if (RCol14Row2 < 11 || RCol14Row1 > 10) {
setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
            ]
          )
}
	} else if (RCol14Row3 === 20.1) {
	if (RCol14Row2 < 11 || RCol14Row1 > 10) {
setRow3NakedM14(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
            ]
          )
}
	} 
}

const reloadc14r4 = (e) => {
	if (RCol14Row4 === 1) {
		setRow4Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row04X,0.5]} />
            ]
          	)

	} else if (RCol14Row4 === 2) {
		setRow4Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:false}} position={[col14X,row04X,0.5]} />
            ]
          	)
	} else if (RCol14Row4 === 3) {
		setRow4Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row04X,0.5]} />
            ]
          	)
	} else if (RCol14Row4 === 4) {
		setRow4Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row04X,0.5]} />
            ]
          	)
	} else if (RCol14Row4 === 5) {
		setRow4Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:false, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
            ]
          	)
	} else if (RCol14Row4 === 6) {
		setRow4Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:false}} position={[col14X,row04X,0.5]} />
            ]
          	)
	} else if (RCol14Row4 === 7) {
		setRow4Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:false, rightOn:true}} position={[col14X,row04X,0.5]} />
            ]
          	)
	} else if (RCol14Row4 === 8) {
		setRow4Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
            ]
          	)
	} else if (RCol14Row4 === 9) {
		setRow4Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
            ]
          	)
	} else if (RCol14Row4 === 10) {
		setRow4Naked14(
            [
              <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true}} position={[col14X,row04X,0.5]} />
            ]
          	)
	} 
}
const hideDoorsc1r1 = (e) => {
	if (RCol1Row1 === 19) {
		setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )


	} else if (RCol1Row1 === 20) {
setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 19.1) {
setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 20.1) {
setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 29) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 30) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 29.1) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 30.1) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	
	} else if (RCol1Row1 === 29.3) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 30.3) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 29.5) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 30.5) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	
	} else if (RCol1Row1 === 29.4) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 30.4) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	
	} else if (RCol1Row1 === 29.6) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 30.6) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	
	} else if (RCol1Row1 === 29.8) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 30.8) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 29.9) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 30.9) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	}
}
const showDoorsc1r1 = (e) => {
	if (RCol1Row1 === 19) {
		setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 20) {
setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 19.1) {
setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 20.1) {
setBaseNakedM01(
            [
              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row01X,0.5]} />
            ]
          )

	} else if (RCol1Row1 === 29) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
		]
          )
	} else if (RCol1Row1 === 30) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 29.1) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
 		]
          )
	} else if (RCol1Row1 === 30.1) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	
	} else if (RCol1Row1 === 29.3) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 30.3) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	
	} else if (RCol1Row1 === 29.5) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 30.5) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	
	} else if (RCol1Row1 === 29.4) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 30.4) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col01X,row01X,0.5]} />
            ]
          )
	
	} else if (RCol1Row1 === 29.6) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 30.6) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	
	} else if (RCol1Row1 === 29.8) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 30.8) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	
	} else if (RCol1Row1 === 29.9) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	} else if (RCol1Row1 === 30.9) {
setBaseNakedL01(
            [
              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col01X,row01X,0.5]} />
            ]
          )
	}
}
const hideDoorsc1r2 = (e) => {
	if (RCol1Row2 === 19) {
if (RCol1Row1 < 11) {
		setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol1Row2 === 20) {
if (RCol1Row1 < 11) {
setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	
	} else if (RCol1Row2 === 19.1) {
if (RCol1Row1 < 11) {
setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol1Row2 === 20.1) {
if (RCol1Row1 < 11) {
setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	} 
}

const showDoorsc1r2 = (e) => {
	if (RCol1Row2 === 19) {
if (RCol1Row1 < 11) {
		setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol1Row2 === 20) {
if (RCol1Row1 < 11) {
setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	
	} else if (RCol1Row2 === 19.1) {
if (RCol1Row1 < 11) {
setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	} else if (RCol1Row2 === 20.1) {
if (RCol1Row1 < 11) {
setRow2NakedM01(
            [
              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row02X,0.5]} />
            ]
          )
}
	} 
}
const hideDoorsc1r3 = (e) => {
  if (RCol1Row3 === 19) {
  if (RCol1Row2 < 11 || RCol1Row1 > 10) {
      setRow3NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
              ]
            )
  }
    } else if (RCol1Row3 === 20) {
  if (RCol1Row2 < 11 || RCol1Row1 > 10) {
  setRow3NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
              ]
            )
  }
  
    } else if (RCol1Row3 === 19.1) {
  if (RCol1Row2 < 11 || RCol1Row1 > 10) {
  setRow3NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
              ]
            )
  }
    } else if (RCol1Row3 === 20.1) {
  if (RCol1Row2 < 11 || RCol1Row1 > 10) {
  setRow3NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
              ]
            )
  }
    } 
  }

  const showDoorsc1r3 = (e) => {
    if (RCol1Row3 === 19) {
  if (RCol1Row2 < 11 || RCol1Row1 > 10) {
      setRow3NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
              ]
            )
  }
    } else if (RCol1Row3 === 20) {
  if (RCol1Row2 < 11 || RCol1Row1 > 10) {
  setRow3NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col01X,row03X,0.5]} />
              ]
            )
  }
    
    } else if (RCol1Row3 === 19.1) {
  if (RCol1Row2 < 11 || RCol1Row1 > 10) {
  setRow3NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
              ]
            )
  }
    } else if (RCol1Row3 === 20.1) {
  if (RCol1Row2 < 11 || RCol1Row1 > 10) {
  setRow3NakedM01(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col01X,row03X,0.5]} />
              ]
            )
  }
    } 
  }

  const hideDoorsc2r1 = (e) => {
    if (RCol2Row1 === 19) {
      setBaseNakedM02(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
              ]
            )
  
  
    } else if (RCol2Row1 === 20) {
  setBaseNakedM02(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
              ]
            )
  
    } else if (RCol2Row1 === 19.1) {
  setBaseNakedM02(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
              ]
            )
  
    } else if (RCol2Row1 === 20.1) {
  setBaseNakedM02(
              [
                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
              ]
            )
  
    } else if (RCol2Row1 === 29) {
  setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
              ]
            )
    } else if (RCol2Row1 === 30) {
  setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
              ]
            )
    } else if (RCol2Row1 === 29.1) {
  setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
              ]
            )
    } else if (RCol2Row1 === 30.1) {
  setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
              ]
            )
    
    } else if (RCol2Row1 === 29.3) {
  setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
              ]
            )
    } else if (RCol2Row1 === 30.3) {
  setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
              ]
            )
    } else if (RCol2Row1 === 29.5) {
  setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
    } else if (RCol2Row1 === 30.5) {
  setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
    
    } else if (RCol2Row1 === 29.4) {
  setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
              ]
            )
    } else if (RCol2Row1 === 30.4) {
  setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
              ]
            )
    
    } else if (RCol2Row1 === 29.6) {
  setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
    } else if (RCol2Row1 === 30.6) {
  setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
    
    } else if (RCol2Row1 === 29.8) {
  setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
    } else if (RCol2Row1 === 30.8) {
  setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
    } else if (RCol2Row1 === 29.9) {
  setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
    } else if (RCol2Row1 === 30.9) {
  setBaseNakedL02(
              [
                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
              ]
            )
    }
  }
  const hideDoorsc2r2 = (e) => {
    if (RCol2Row2 === 19) {
  if (RCol2Row1 < 11) {
      setRow2NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
              ]
            )
  }
    } else if (RCol2Row2 === 20) {
  if (RCol2Row1 < 11) {
  setRow2NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
              ]
            )
  }
    
    } else if (RCol2Row2 === 19.1) {
  if (RCol2Row1 < 11) {
  setRow2NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
              ]
            )
  }
    } else if (RCol2Row2 === 20.1) {
  if (RCol2Row1 < 11) {
  setRow2NakedM02(
              [
                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
              ]
            )
  }
    } 
  }
  const hideDoorsc2r3 = (e) => {
    if (RCol2Row3 === 19) {
    if (RCol2Row2 < 11 || RCol2Row1 > 10) {
        setRow3NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                ]
              )
    }
      } else if (RCol2Row3 === 20) {
    if (RCol2Row2 < 11 || RCol2Row1 > 10) {
    setRow3NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                ]
              )
    }
    
      } else if (RCol2Row3 === 19.1) {
    if (RCol2Row2 < 11 || RCol2Row1 > 10) {
    setRow3NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
    }
      } else if (RCol2Row3 === 20.1) {
    if (RCol2Row2 < 11 || RCol2Row1 > 10) {
    setRow3NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
    }
      } 
    }

    const showDoorsc2r1 = (e) => {
      if (RCol2Row1 === 19) {
        setBaseNakedM02(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                ]
              )
    
      } else if (RCol2Row1 === 20) {
    setBaseNakedM02(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row01X,0.5]} />
                ]
              )
    
      } else if (RCol2Row1 === 19.1) {
    setBaseNakedM02(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
    
      } else if (RCol2Row1 === 20.1) {
    setBaseNakedM02(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row01X,0.5]} />
                ]
              )
    
      } else if (RCol2Row1 === 29) {
    setBaseNakedL02(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
        ]
              )
      } else if (RCol2Row1 === 30) {
    setBaseNakedL02(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                ]
              )
      } else if (RCol2Row1 === 29.1) {
    setBaseNakedL02(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
         ]
              )
      } else if (RCol2Row1 === 30.1) {
    setBaseNakedL02(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                ]
              )
      
      } else if (RCol2Row1 === 29.3) {
    setBaseNakedL02(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                ]
              )
      } else if (RCol2Row1 === 30.3) {
    setBaseNakedL02(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                ]
              )
      
      } else if (RCol2Row1 === 29.5) {
    setBaseNakedL02(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                ]
              )
      } else if (RCol2Row1 === 30.5) {
    setBaseNakedL02(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                ]
              )
      
      } else if (RCol2Row1 === 29.4) {
    setBaseNakedL02(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                ]
              )
      } else if (RCol2Row1 === 30.4) {
    setBaseNakedL02(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col02X,row01X,0.5]} />
                ]
              )
      
      } else if (RCol2Row1 === 29.6) {
    setBaseNakedL02(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                ]
              )
      } else if (RCol2Row1 === 30.6) {
    setBaseNakedL02(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                ]
              )
      
      } else if (RCol2Row1 === 29.8) {
    setBaseNakedL02(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                ]
              )
      } else if (RCol2Row1 === 30.8) {
    setBaseNakedL02(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                ]
              )
      
      } else if (RCol2Row1 === 29.9) {
    setBaseNakedL02(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                ]
              )
      } else if (RCol2Row1 === 30.9) {
    setBaseNakedL02(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col02X,row01X,0.5]} />
                ]
              )
      }
    }

    const showDoorsc2r2 = (e) => {
      if (RCol2Row2 === 19) {
    if (RCol2Row1 < 11) {
        setRow2NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                ]
              )
    }
      } else if (RCol2Row2 === 20) {
    if (RCol2Row1 < 11) {
    setRow2NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row02X,0.5]} />
                ]
              )
    }
      
      } else if (RCol2Row2 === 19.1) {
    if (RCol2Row1 < 11) {
    setRow2NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
    }
      } else if (RCol2Row2 === 20.1) {
    if (RCol2Row1 < 11) {
    setRow2NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row02X,0.5]} />
                ]
              )
    }
      } 
    }
    const showDoorsc2r3 = (e) => {
      if (RCol2Row3 === 19) {
    if (RCol2Row2 < 11 || RCol2Row1 > 10) {
        setRow3NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                ]
              )
    }
      } else if (RCol2Row3 === 20) {
    if (RCol2Row2 < 11 || RCol2Row1 > 10) {
    setRow3NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col02X,row03X,0.5]} />
                ]
              )
    }
      
      } else if (RCol2Row3 === 19.1) {
    if (RCol2Row2 < 11 || RCol2Row1 > 10) {
    setRow3NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
    }
      } else if (RCol2Row3 === 20.1) {
    if (RCol2Row2 < 11 || RCol2Row1 > 10) {
    setRow3NakedM02(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col02X,row03X,0.5]} />
                ]
              )
    }
      } 
    }
    const hideDoorsc3r1 = (e) => {
      if (RCol3Row1 === 19) {
        setBaseNakedM03(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                ]
              )
    
      } else if (RCol3Row1 === 20) {
    setBaseNakedM03(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                ]
              )
    
      } else if (RCol3Row1 === 19.1) {
    setBaseNakedM03(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
    
      } else if (RCol3Row1 === 20.1) {
    setBaseNakedM03(
                [
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                ]
              )
    
      } else if (RCol3Row1 === 29) {
    setBaseNakedL03(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                ]
              )
      } else if (RCol3Row1 === 30) {
    setBaseNakedL03(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                ]
              )
      } else if (RCol3Row1 === 29.1) {
    setBaseNakedL03(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                ]
              )
      } else if (RCol3Row1 === 30.1) {
    setBaseNakedL03(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                ]
              )
      
      } else if (RCol3Row1 === 29.3) {
    setBaseNakedL03(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                ]
              )
      } else if (RCol3Row1 === 30.3) {
    setBaseNakedL03(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                ]
              )
      } else if (RCol3Row1 === 29.5) {
    setBaseNakedL03(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                ]
              )
      } else if (RCol3Row1 === 30.5) {
    setBaseNakedL03(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                ]
              )
      
      } else if (RCol3Row1 === 29.4) {
    setBaseNakedL03(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                ]
              )
      } else if (RCol3Row1 === 30.4) {
    setBaseNakedL03(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                ]
              )
      
      } else if (RCol3Row1 === 29.6) {
    setBaseNakedL03(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                ]
              )
      } else if (RCol3Row1 === 30.6) {
    setBaseNakedL03(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                ]
              )
      
      } else if (RCol3Row1 === 29.8) {
    setBaseNakedL03(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                ]
              )
      } else if (RCol3Row1 === 30.8) {
    setBaseNakedL03(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                ]
              )
      } else if (RCol3Row1 === 29.9) {
    setBaseNakedL03(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                ]
              )
      } else if (RCol3Row1 === 30.9) {
    setBaseNakedL03(
                [
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                ]
              )
      }
    }

    const hideDoorsc3r2 = (e) => {
      if (RCol3Row2 === 19) {
    if (RCol3Row1 < 11) {
        setRow2NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                ]
              )
    }
      } else if (RCol3Row2 === 20) {
    if (RCol3Row1 < 11) {
    setRow2NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                ]
              )
    }
      
      } else if (RCol3Row2 === 19.1) {
    if (RCol3Row1 < 11) {
    setRow2NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
    }
      } else if (RCol3Row2 === 20.1) {
    if (RCol3Row1 < 11) {
    setRow2NakedM03(
                [
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                ]
              )
    }
      } 
    }
    const hideDoorsc3r3 = (e) => {
      if (RCol3Row3 === 19) {
      if (RCol3Row2 < 11 || RCol3Row1 > 10) {
          setRow3NakedM03(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                  ]
                )
      }
        } else if (RCol3Row3 === 20) {
      if (RCol3Row2 < 11 || RCol3Row1 > 10) {
      setRow3NakedM03(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                  ]
                )
      }
      
        } else if (RCol3Row3 === 19.1) {
      if (RCol3Row2 < 11 || RCol3Row1 > 10) {
      setRow3NakedM03(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                  ]
                )
      }
        } else if (RCol3Row3 === 20.1) {
      if (RCol3Row2 < 11 || RCol3Row1 > 10) {
      setRow3NakedM03(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                  ]
                )
      }
        } 
      }
      const showDoorsc3r1 = (e) => {
        if (RCol3Row1 === 19) {
          setBaseNakedM03(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                  ]
                )
      
        } else if (RCol3Row1 === 20) {
      setBaseNakedM03(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row01X,0.5]} />
                  ]
                )
      
        } else if (RCol3Row1 === 19.1) {
      setBaseNakedM03(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                  ]
                )
      
        } else if (RCol3Row1 === 20.1) {
      setBaseNakedM03(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row01X,0.5]} />
                  ]
                )
      
        } else if (RCol3Row1 === 29) {
      setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
          ]
                )
        } else if (RCol3Row1 === 30) {
      setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                  ]
                )
        } else if (RCol3Row1 === 29.1) {
      setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
           ]
                )
        } else if (RCol3Row1 === 30.1) {
      setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                  ]
                )
        
        } else if (RCol3Row1 === 29.3) {
      setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                  ]
                )
        } else if (RCol3Row1 === 30.3) {
      setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                  ]
                )
        
        } else if (RCol3Row1 === 29.5) {
      setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                  ]
                )
        } else if (RCol3Row1 === 30.5) {
      setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                  ]
                )
        
        } else if (RCol3Row1 === 29.4) {
      setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                  ]
                )
        } else if (RCol3Row1 === 30.4) {
      setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col03X,row01X,0.5]} />
                  ]
                )
        
        } else if (RCol3Row1 === 29.6) {
      setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                  ]
                )
        } else if (RCol3Row1 === 30.6) {
      setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                  ]
                )
        
        } else if (RCol3Row1 === 29.8) {
      setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                  ]
                )
        } else if (RCol3Row1 === 30.8) {
      setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                  ]
                )
        
        } else if (RCol3Row1 === 29.9) {
      setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                  ]
                )
        } else if (RCol3Row1 === 30.9) {
      setBaseNakedL03(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col03X,row01X,0.5]} />
                  ]
                )
        }
      }

      const showDoorsc3r2 = (e) => {
        if (RCol3Row2 === 19) {
      if (RCol3Row1 < 11) {
          setRow2NakedM03(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                  ]
                )
      }
        } else if (RCol3Row2 === 20) {
      if (RCol3Row1 < 11) {
      setRow2NakedM03(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row02X,0.5]} />
                  ]
                )
      }
        
        } else if (RCol3Row2 === 19.1) {
      if (RCol3Row1 < 11) {
      setRow2NakedM03(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                  ]
                )
      }
        } else if (RCol3Row2 === 20.1) {
      if (RCol3Row1 < 11) {
      setRow2NakedM03(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row02X,0.5]} />
                  ]
                )
      }
        } 
      }

      const showDoorsc3r3 = (e) => {
        if (RCol3Row3 === 19) {
      if (RCol3Row2 < 11 || RCol3Row1 > 10) {
          setRow3NakedM03(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                  ]
                )
      }
        } else if (RCol3Row3 === 20) {
      if (RCol3Row2 < 11 || RCol3Row1 > 10) {
      setRow3NakedM03(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col03X,row03X,0.5]} />
                  ]
                )
      }
        
        } else if (RCol3Row3 === 19.1) {
      if (RCol3Row2 < 11 || RCol3Row1 > 10) {
      setRow3NakedM03(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                  ]
                )
      }
        } else if (RCol3Row3 === 20.1) {
      if (RCol3Row2 < 11 || RCol3Row1 > 10) {
      setRow3NakedM03(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col03X,row03X,0.5]} />
                  ]
                )
      }
        } 
      }

      const hideDoorsc4r1 = (e) => {
        if (RCol4Row1 === 19) {
          setBaseNakedM04(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                  ]
                )
      
        } else if (RCol4Row1 === 20) {
      setBaseNakedM04(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                  ]
                )
      
        } else if (RCol4Row1 === 19.1) {
      setBaseNakedM04(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                  ]
                )
      
        } else if (RCol4Row1 === 20.1) {
      setBaseNakedM04(
                  [
                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                  ]
                )
      
        } else if (RCol4Row1 === 29) {
      setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                  ]
                )
        } else if (RCol4Row1 === 30) {
      setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                  ]
                )
        } else if (RCol4Row1 === 29.1) {
      setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                  ]
                )
        } else if (RCol4Row1 === 30.1) {
      setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                  ]
                )
        
        } else if (RCol4Row1 === 29.3) {
      setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                  ]
                )
        } else if (RCol4Row1 === 30.3) {
      setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                  ]
                )
        } else if (RCol4Row1 === 29.5) {
      setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                  ]
                )
        } else if (RCol4Row1 === 30.5) {
      setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                  ]
                )
        
        } else if (RCol4Row1 === 29.4) {
      setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                  ]
                )
        } else if (RCol4Row1 === 30.4) {
      setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                  ]
                )
        
        } else if (RCol4Row1 === 29.6) {
      setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                  ]
                )
        } else if (RCol4Row1 === 30.6) {
      setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                  ]
                )
        
        } else if (RCol4Row1 === 29.8) {
      setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                  ]
                )
        } else if (RCol4Row1 === 30.8) {
      setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                  ]
                )
        } else if (RCol4Row1 === 29.9) {
      setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                  ]
                )
        } else if (RCol4Row1 === 30.9) {
      setBaseNakedL04(
                  [
                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                  ]
                )
        }
      }

      const hideDoorsc4r2 = (e) => {
        if (RCol4Row2 === 19) {
      if (RCol4Row1 < 11) {
          setRow2NakedM04(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                  ]
                )
      }
        } else if (RCol4Row2 === 20) {
      if (RCol4Row1 < 11) {
      setRow2NakedM04(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                  ]
                )
      }
        
        } else if (RCol4Row2 === 19.1) {
      if (RCol4Row1 < 11) {
      setRow2NakedM04(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                  ]
                )
      }
        } else if (RCol4Row2 === 20.1) {
      if (RCol4Row1 < 11) {
      setRow2NakedM04(
                  [
                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                  ]
                )
      }
        } 
      }
      const hideDoorsc4r3 = (e) => {
        if (RCol4Row3 === 19) {
        if (RCol4Row2 < 11 || RCol4Row1 > 10) {
            setRow3NakedM04(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                    ]
                  )
        }
          } else if (RCol4Row3 === 20) {
        if (RCol4Row2 < 11 || RCol4Row1 > 10) {
        setRow3NakedM04(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                    ]
                  )
        }
        
          } else if (RCol4Row3 === 19.1) {
        if (RCol4Row2 < 11 || RCol4Row1 > 10) {
        setRow3NakedM04(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                    ]
                  )
        }
          } else if (RCol4Row3 === 20.1) {
        if (RCol4Row2 < 11 || RCol4Row1 > 10) {
        setRow3NakedM04(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                    ]
                  )
        }
          } 
        }
        const showDoorsc4r1 = (e) => {
          if (RCol4Row1 === 19) {
            setBaseNakedM04(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
        
          } else if (RCol4Row1 === 20) {
        setBaseNakedM04(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
        
          } else if (RCol4Row1 === 19.1) {
        setBaseNakedM04(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                    ]
                  )
        
          } else if (RCol4Row1 === 20.1) {
        setBaseNakedM04(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row01X,0.5]} />
                    ]
                  )
        
          } else if (RCol4Row1 === 29) {
        setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
            ]
                  )
          } else if (RCol4Row1 === 30) {
        setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
          } else if (RCol4Row1 === 29.1) {
        setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
             ]
                  )
          } else if (RCol4Row1 === 30.1) {
        setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
          
          } else if (RCol4Row1 === 29.3) {
        setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
          } else if (RCol4Row1 === 30.3) {
        setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
          
          } else if (RCol4Row1 === 29.5) {
        setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                    ]
                  )
          } else if (RCol4Row1 === 30.5) {
        setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                    ]
                  )
          
          } else if (RCol4Row1 === 29.4) {
        setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
          } else if (RCol4Row1 === 30.4) {
        setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col04X,row01X,0.5]} />
                    ]
                  )
          
          } else if (RCol4Row1 === 29.6) {
        setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                    ]
                  )
          } else if (RCol4Row1 === 30.6) {
        setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                    ]
                  )
          
          } else if (RCol4Row1 === 29.8) {
        setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                    ]
                  )
          } else if (RCol4Row1 === 30.8) {
        setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                    ]
                  )
          
          } else if (RCol4Row1 === 29.9) {
        setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                    ]
                  )
          } else if (RCol4Row1 === 30.9) {
        setBaseNakedL04(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col04X,row01X,0.5]} />
                    ]
                  )
          }
        }
        const showDoorsc4r2 = (e) => {
          if (RCol4Row2 === 19) {
        if (RCol4Row1 < 11) {
            setRow2NakedM04(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                    ]
                  )
        }
          } else if (RCol4Row2 === 20) {
        if (RCol4Row1 < 11) {
        setRow2NakedM04(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row02X,0.5]} />
                    ]
                  )
        }
          
          } else if (RCol4Row2 === 19.1) {
        if (RCol4Row1 < 11) {
        setRow2NakedM04(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                    ]
                  )
        }
          } else if (RCol4Row2 === 20.1) {
        if (RCol4Row1 < 11) {
        setRow2NakedM04(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row02X,0.5]} />
                    ]
                  )
        }
          } 
        }
        const showDoorsc4r3 = (e) => {
          if (RCol4Row3 === 19) {
        if (RCol4Row2 < 11 || RCol4Row1 > 10) {
            setRow3NakedM04(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                    ]
                  )
        }
          } else if (RCol4Row3 === 20) {
        if (RCol4Row2 < 11 || RCol4Row1 > 10) {
        setRow3NakedM04(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col04X,row03X,0.5]} />
                    ]
                  )
        }
          
          } else if (RCol4Row3 === 19.1) {
        if (RCol4Row2 < 11 || RCol4Row1 > 10) {
        setRow3NakedM04(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                    ]
                  )
        }
          } else if (RCol4Row3 === 20.1) {
        if (RCol4Row2 < 11 || RCol4Row1 > 10) {
        setRow3NakedM04(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col04X,row03X,0.5]} />
                    ]
                  )
        }
          } 
        }
        const hideDoorsc5r1 = (e) => {
          if (RCol5Row1 === 19) {
            setBaseNakedM05(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
        
          } else if (RCol5Row1 === 20) {
        setBaseNakedM05(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
        
          } else if (RCol5Row1 === 19.1) {
        setBaseNakedM05(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                    ]
                  )
        
          } else if (RCol5Row1 === 20.1) {
        setBaseNakedM05(
                    [
                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                    ]
                  )
        
          } else if (RCol5Row1 === 29) {
        setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
          } else if (RCol5Row1 === 30) {
        setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
          } else if (RCol5Row1 === 29.1) {
        setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
          } else if (RCol5Row1 === 30.1) {
        setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
          
          } else if (RCol5Row1 === 29.3) {
        setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
          } else if (RCol5Row1 === 30.3) {
        setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
          } else if (RCol5Row1 === 29.5) {
        setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                    ]
                  )
          } else if (RCol5Row1 === 30.5) {
        setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                    ]
                  )
          
          } else if (RCol5Row1 === 29.4) {
        setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
          } else if (RCol5Row1 === 30.4) {
        setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                    ]
                  )
          
          } else if (RCol5Row1 === 29.6) {
        setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                    ]
                  )
          } else if (RCol5Row1 === 30.6) {
        setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                    ]
                  )
          
          } else if (RCol5Row1 === 29.8) {
        setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                    ]
                  )
          } else if (RCol5Row1 === 30.8) {
        setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                    ]
                  )
          } else if (RCol5Row1 === 29.9) {
        setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                    ]
                  )
          } else if (RCol5Row1 === 30.9) {
        setBaseNakedL05(
                    [
                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                    ]
                  )
          }
        }
        const hideDoorsc5r2 = (e) => {
          if (RCol5Row2 === 19) {
        if (RCol5Row1 < 11) {
            setRow2NakedM05(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                    ]
                  )
        }
          } else if (RCol5Row2 === 20) {
        if (RCol5Row1 < 11) {
        setRow2NakedM05(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                    ]
                  )
        }
          
          } else if (RCol5Row2 === 19.1) {
        if (RCol5Row1 < 11) {
        setRow2NakedM05(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                    ]
                  )
        }
          } else if (RCol5Row2 === 20.1) {
        if (RCol5Row1 < 11) {
        setRow2NakedM05(
                    [
                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                    ]
                  )
        }
          } 
        }
        const hideDoorsc5r3 = (e) => {
          if (RCol5Row3 === 19) {
          if (RCol5Row2 < 11 || RCol5Row1 > 10) {
              setRow3NakedM05(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                      ]
                    )
          }
            } else if (RCol5Row3 === 20) {
          if (RCol5Row2 < 11 || RCol5Row1 > 10) {
          setRow3NakedM05(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                      ]
                    )
          }
          
            } else if (RCol5Row3 === 19.1) {
          if (RCol5Row2 < 11 || RCol5Row1 > 10) {
          setRow3NakedM05(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                      ]
                    )
          }
            } else if (RCol5Row3 === 20.1) {
          if (RCol5Row2 < 11 || RCol5Row1 > 10) {
          setRow3NakedM05(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                      ]
                    )
          }
            } 
          }
          const showDoorsc5r1 = (e) => {
            if (RCol5Row1 === 19) {
              setBaseNakedM05(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                      ]
                    )
          
            } else if (RCol5Row1 === 20) {
          setBaseNakedM05(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row01X,0.5]} />
                      ]
                    )
          
            } else if (RCol5Row1 === 19.1) {
          setBaseNakedM05(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                      ]
                    )
          
            } else if (RCol5Row1 === 20.1) {
          setBaseNakedM05(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row01X,0.5]} />
                      ]
                    )
          
            } else if (RCol5Row1 === 29) {
          setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
              ]
                    )
            } else if (RCol5Row1 === 30) {
          setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                      ]
                    )
            } else if (RCol5Row1 === 29.1) {
          setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
               ]
                    )
            } else if (RCol5Row1 === 30.1) {
          setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                      ]
                    )
            
            } else if (RCol5Row1 === 29.3) {
          setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                      ]
                    )
            } else if (RCol5Row1 === 30.3) {
          setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                      ]
                    )
            
            } else if (RCol5Row1 === 29.5) {
          setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                      ]
                    )
            } else if (RCol5Row1 === 30.5) {
          setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                      ]
                    )
            
            } else if (RCol5Row1 === 29.4) {
          setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                      ]
                    )
            } else if (RCol5Row1 === 30.4) {
          setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col05X,row01X,0.5]} />
                      ]
                    )
            
            } else if (RCol5Row1 === 29.6) {
          setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                      ]
                    )
            } else if (RCol5Row1 === 30.6) {
          setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                      ]
                    )
            
            } else if (RCol5Row1 === 29.8) {
          setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                      ]
                    )
            } else if (RCol5Row1 === 30.8) {
          setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                      ]
                    )
            
            } else if (RCol5Row1 === 29.9) {
          setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                      ]
                    )
            } else if (RCol5Row1 === 30.9) {
          setBaseNakedL05(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col05X,row01X,0.5]} />
                      ]
                    )
            }
          }
          const showDoorsc5r2 = (e) => {
            if (RCol5Row2 === 19) {
          if (RCol5Row1 < 11) {
              setRow2NakedM05(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                      ]
                    )
          }
            } else if (RCol5Row2 === 20) {
          if (RCol5Row1 < 11) {
          setRow2NakedM05(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row02X,0.5]} />
                      ]
                    )
          }
            
            } else if (RCol5Row2 === 19.1) {
          if (RCol5Row1 < 11) {
          setRow2NakedM05(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                      ]
                    )
          }
            } else if (RCol5Row2 === 20.1) {
          if (RCol5Row1 < 11) {
          setRow2NakedM05(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row02X,0.5]} />
                      ]
                    )
          }
            } 
          }
          const showDoorsc5r3 = (e) => {
            if (RCol5Row3 === 19) {
          if (RCol5Row2 < 11 || RCol5Row1 > 10) {
              setRow3NakedM05(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                      ]
                    )
          }
            } else if (RCol5Row3 === 20) {
          if (RCol5Row2 < 11 || RCol5Row1 > 10) {
          setRow3NakedM05(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col05X,row03X,0.5]} />
                      ]
                    )
          }
            
            } else if (RCol5Row3 === 19.1) {
          if (RCol5Row2 < 11 || RCol5Row1 > 10) {
          setRow3NakedM05(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                      ]
                    )
          }
            } else if (RCol5Row3 === 20.1) {
          if (RCol5Row2 < 11 || RCol5Row1 > 10) {
          setRow3NakedM05(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col05X,row03X,0.5]} />
                      ]
                    )
          }
            } 
          }
          const hideDoorsc6r1 = (e) => {
            if (RCol6Row1 === 19) {
              setBaseNakedM06(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
          
            } else if (RCol6Row1 === 20) {
          setBaseNakedM06(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
          
            } else if (RCol6Row1 === 19.1) {
          setBaseNakedM06(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
          
            } else if (RCol6Row1 === 20.1) {
          setBaseNakedM06(
                      [
                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
          
            } else if (RCol6Row1 === 29) {
          setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
            } else if (RCol6Row1 === 30) {
          setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
            } else if (RCol6Row1 === 29.1) {
          setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
            } else if (RCol6Row1 === 30.1) {
          setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
            
            } else if (RCol6Row1 === 29.3) {
          setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
            } else if (RCol6Row1 === 30.3) {
          setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
            } else if (RCol6Row1 === 29.5) {
          setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
            } else if (RCol6Row1 === 30.5) {
          setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
            
            } else if (RCol6Row1 === 29.4) {
          setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
            } else if (RCol6Row1 === 30.4) {
          setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                      ]
                    )
            
            } else if (RCol6Row1 === 29.6) {
          setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
            } else if (RCol6Row1 === 30.6) {
          setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
            
            } else if (RCol6Row1 === 29.8) {
          setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
            } else if (RCol6Row1 === 30.8) {
          setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
            } else if (RCol6Row1 === 29.9) {
          setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
            } else if (RCol6Row1 === 30.9) {
          setBaseNakedL06(
                      [
                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                      ]
                    )
            }
          }
          const hideDoorsc6r2 = (e) => {
            if (RCol6Row2 === 19) {
          if (RCol6Row1 < 11) {
              setRow2NakedM06(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                      ]
                    )
          }
            } else if (RCol6Row2 === 20) {
          if (RCol6Row1 < 11) {
          setRow2NakedM06(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                      ]
                    )
          }
            
            } else if (RCol6Row2 === 19.1) {
          if (RCol6Row1 < 11) {
          setRow2NakedM06(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                      ]
                    )
          }
            } else if (RCol6Row2 === 20.1) {
          if (RCol6Row1 < 11) {
          setRow2NakedM06(
                      [
                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                      ]
                    )
          }
            } 
          }
          const hideDoorsc6r3 = (e) => {
            if (RCol6Row3 === 19) {
            if (RCol6Row2 < 11 || RCol6Row1 > 10) {
                setRow3NakedM06(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                        ]
                      )
            }
              } else if (RCol6Row3 === 20) {
            if (RCol6Row2 < 11 || RCol6Row1 > 10) {
            setRow3NakedM06(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                        ]
                      )
            }
            
              } else if (RCol6Row3 === 19.1) {
            if (RCol6Row2 < 11 || RCol6Row1 > 10) {
            setRow3NakedM06(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                        ]
                      )
            }
              } else if (RCol6Row3 === 20.1) {
            if (RCol6Row2 < 11 || RCol6Row1 > 10) {
            setRow3NakedM06(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                        ]
                      )
            }
              } 
            }
            const showDoorsc6r1 = (e) => {
              if (RCol6Row1 === 19) {
                setBaseNakedM06(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                        ]
                      )
            
              } else if (RCol6Row1 === 20) {
            setBaseNakedM06(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row01X,0.5]} />
                        ]
                      )
            
              } else if (RCol6Row1 === 19.1) {
            setBaseNakedM06(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                        ]
                      )
            
              } else if (RCol6Row1 === 20.1) {
            setBaseNakedM06(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row01X,0.5]} />
                        ]
                      )
            
              } else if (RCol6Row1 === 29) {
            setBaseNakedL06(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                ]
                      )
              } else if (RCol6Row1 === 30) {
            setBaseNakedL06(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                        ]
                      )
              } else if (RCol6Row1 === 29.1) {
            setBaseNakedL06(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                 ]
                      )
              } else if (RCol6Row1 === 30.1) {
            setBaseNakedL06(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                        ]
                      )
              
              } else if (RCol6Row1 === 29.3) {
            setBaseNakedL06(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                        ]
                      )
              } else if (RCol6Row1 === 30.3) {
            setBaseNakedL06(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                        ]
                      )
              
              } else if (RCol6Row1 === 29.5) {
            setBaseNakedL06(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                        ]
                      )
              } else if (RCol6Row1 === 30.5) {
            setBaseNakedL06(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                        ]
                      )
              
              } else if (RCol6Row1 === 29.4) {
            setBaseNakedL06(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                        ]
                      )
              } else if (RCol6Row1 === 30.4) {
            setBaseNakedL06(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col06X,row01X,0.5]} />
                        ]
                      )
              
              } else if (RCol6Row1 === 29.6) {
            setBaseNakedL06(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                        ]
                      )
              } else if (RCol6Row1 === 30.6) {
            setBaseNakedL06(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                        ]
                      )
              
              } else if (RCol6Row1 === 29.8) {
            setBaseNakedL06(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                        ]
                      )
              } else if (RCol6Row1 === 30.8) {
            setBaseNakedL06(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                        ]
                      )
              
              } else if (RCol6Row1 === 29.9) {
            setBaseNakedL06(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                        ]
                      )
              } else if (RCol6Row1 === 30.9) {
            setBaseNakedL06(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col06X,row01X,0.5]} />
                        ]
                      )
              }
            }
            const showDoorsc6r2 = (e) => {
              if (RCol6Row2 === 19) {
            if (RCol6Row1 < 11) {
                setRow2NakedM06(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                        ]
                      )
            }
              } else if (RCol6Row2 === 20) {
            if (RCol6Row1 < 11) {
            setRow2NakedM06(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row02X,0.5]} />
                        ]
                      )
            }
              
              } else if (RCol6Row2 === 19.1) {
            if (RCol6Row1 < 11) {
            setRow2NakedM06(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                        ]
                      )
            }
              } else if (RCol6Row2 === 20.1) {
            if (RCol6Row1 < 11) {
            setRow2NakedM06(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row02X,0.5]} />
                        ]
                      )
            }
              } 
            }
            const showDoorsc6r3 = (e) => {
              if (RCol6Row3 === 19) {
            if (RCol6Row2 < 11 || RCol6Row1 > 10) {
                setRow3NakedM06(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                        ]
                      )
            }
              } else if (RCol6Row3 === 20) {
            if (RCol6Row2 < 11 || RCol6Row1 > 10) {
            setRow3NakedM06(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col06X,row03X,0.5]} />
                        ]
                      )
            }
              
              } else if (RCol6Row3 === 19.1) {
            if (RCol6Row2 < 11 || RCol6Row1 > 10) {
            setRow3NakedM06(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                        ]
                      )
            }
              } else if (RCol6Row3 === 20.1) {
            if (RCol6Row2 < 11 || RCol6Row1 > 10) {
            setRow3NakedM06(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col06X,row03X,0.5]} />
                        ]
                      )
            }
              } 
            }
            const hideDoorsc7r1 = (e) => {
              if (RCol7Row1 === 19) {
                setBaseNakedM07(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                        ]
                      )
            
              } else if (RCol7Row1 === 20) {
            setBaseNakedM07(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                        ]
                      )
            
              } else if (RCol7Row1 === 19.1) {
            setBaseNakedM07(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                        ]
                      )
            
              } else if (RCol7Row1 === 20.1) {
            setBaseNakedM07(
                        [
                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                        ]
                      )
            
              } else if (RCol7Row1 === 29) {
            setBaseNakedL07(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                        ]
                      )
              } else if (RCol7Row1 === 30) {
            setBaseNakedL07(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                        ]
                      )
              } else if (RCol7Row1 === 29.1) {
            setBaseNakedL07(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                        ]
                      )
              } else if (RCol7Row1 === 30.1) {
            setBaseNakedL07(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                        ]
                      )
              
              } else if (RCol7Row1 === 29.3) {
            setBaseNakedL07(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                        ]
                      )
              } else if (RCol7Row1 === 30.3) {
            setBaseNakedL07(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                        ]
                      )
              } else if (RCol7Row1 === 29.5) {
            setBaseNakedL07(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                        ]
                      )
              } else if (RCol7Row1 === 30.5) {
            setBaseNakedL07(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                        ]
                      )
              
              } else if (RCol7Row1 === 29.4) {
            setBaseNakedL07(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                        ]
                      )
              } else if (RCol7Row1 === 30.4) {
            setBaseNakedL07(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                        ]
                      )
              
              } else if (RCol7Row1 === 29.6) {
            setBaseNakedL07(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                        ]
                      )
              } else if (RCol7Row1 === 30.6) {
            setBaseNakedL07(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                        ]
                      )
              
              } else if (RCol7Row1 === 29.8) {
            setBaseNakedL07(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                        ]
                      )
              } else if (RCol7Row1 === 30.8) {
            setBaseNakedL07(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                        ]
                      )
              } else if (RCol7Row1 === 29.9) {
            setBaseNakedL07(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                        ]
                      )
              } else if (RCol7Row1 === 30.9) {
            setBaseNakedL07(
                        [
                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                        ]
                      )
              }
            }
            const hideDoorsc7r2 = (e) => {
              if (RCol7Row2 === 19) {
            if (RCol7Row1 < 11) {
                setRow2NakedM07(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                        ]
                      )
            }
              } else if (RCol7Row2 === 20) {
            if (RCol7Row1 < 11) {
            setRow2NakedM07(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                        ]
                      )
            }
              
              } else if (RCol7Row2 === 19.1) {
            if (RCol7Row1 < 11) {
            setRow2NakedM07(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                        ]
                      )
            }
              } else if (RCol7Row2 === 20.1) {
            if (RCol7Row1 < 11) {
            setRow2NakedM07(
                        [
                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                        ]
                      )
            }
              } 
            }
            const hideDoorsc7r3 = (e) => {
              if (RCol7Row3 === 19) {
              if (RCol7Row2 < 11 || RCol7Row1 > 10) {
                  setRow3NakedM07(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                          ]
                        )
              }
                } else if (RCol7Row3 === 20) {
              if (RCol7Row2 < 11 || RCol7Row1 > 10) {
              setRow3NakedM07(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                          ]
                        )
              }
              
                } else if (RCol7Row3 === 19.1) {
              if (RCol7Row2 < 11 || RCol7Row1 > 10) {
              setRow3NakedM07(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                          ]
                        )
              }
                } else if (RCol7Row3 === 20.1) {
              if (RCol7Row2 < 11 || RCol7Row1 > 10) {
              setRow3NakedM07(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                          ]
                        )
              }
                } 
              }
              const showDoorsc7r1 = (e) => {
                if (RCol7Row1 === 19) {
                  setBaseNakedM07(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                          ]
                        )
              
                } else if (RCol7Row1 === 20) {
              setBaseNakedM07(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row01X,0.5]} />
                          ]
                        )
              
                } else if (RCol7Row1 === 19.1) {
              setBaseNakedM07(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                          ]
                        )
              
                } else if (RCol7Row1 === 20.1) {
              setBaseNakedM07(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row01X,0.5]} />
                          ]
                        )
              
                } else if (RCol7Row1 === 29) {
              setBaseNakedL07(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                  ]
                        )
                } else if (RCol7Row1 === 30) {
              setBaseNakedL07(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                          ]
                        )
                } else if (RCol7Row1 === 29.1) {
              setBaseNakedL07(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                   ]
                        )
                } else if (RCol7Row1 === 30.1) {
              setBaseNakedL07(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                          ]
                        )
                
                } else if (RCol7Row1 === 29.3) {
              setBaseNakedL07(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                          ]
                        )
                } else if (RCol7Row1 === 30.3) {
              setBaseNakedL07(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                          ]
                        )
                
                } else if (RCol7Row1 === 29.5) {
              setBaseNakedL07(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                          ]
                        )
                } else if (RCol7Row1 === 30.5) {
              setBaseNakedL07(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                          ]
                        )
                
                } else if (RCol7Row1 === 29.4) {
              setBaseNakedL07(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                          ]
                        )
                } else if (RCol7Row1 === 30.4) {
              setBaseNakedL07(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col07X,row01X,0.5]} />
                          ]
                        )
                
                } else if (RCol7Row1 === 29.6) {
              setBaseNakedL07(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                          ]
                        )
                } else if (RCol7Row1 === 30.6) {
              setBaseNakedL07(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                          ]
                        )
                
                } else if (RCol7Row1 === 29.8) {
              setBaseNakedL07(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                          ]
                        )
                } else if (RCol7Row1 === 30.8) {
              setBaseNakedL07(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                          ]
                        )
                
                } else if (RCol7Row1 === 29.9) {
              setBaseNakedL07(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                          ]
                        )
                } else if (RCol7Row1 === 30.9) {
              setBaseNakedL07(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col07X,row01X,0.5]} />
                          ]
                        )
                }
              }
              const showDoorsc7r2 = (e) => {
                if (RCol7Row2 === 19) {
              if (RCol7Row1 < 11) {
                  setRow2NakedM07(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                          ]
                        )
              }
                } else if (RCol7Row2 === 20) {
              if (RCol7Row1 < 11) {
              setRow2NakedM07(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row02X,0.5]} />
                          ]
                        )
              }
                
                } else if (RCol7Row2 === 19.1) {
              if (RCol7Row1 < 11) {
              setRow2NakedM07(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                          ]
                        )
              }
                } else if (RCol7Row2 === 20.1) {
              if (RCol7Row1 < 11) {
              setRow2NakedM07(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row02X,0.5]} />
                          ]
                        )
              }
                } 
              }
              const showDoorsc7r3 = (e) => {
                if (RCol7Row3 === 19) {
              if (RCol7Row2 < 11 || RCol7Row1 > 10) {
                  setRow3NakedM07(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                          ]
                        )
              }
                } else if (RCol7Row3 === 20) {
              if (RCol7Row2 < 11 || RCol7Row1 > 10) {
              setRow3NakedM07(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col07X,row03X,0.5]} />
                          ]
                        )
              }
                
                } else if (RCol7Row3 === 19.1) {
              if (RCol7Row2 < 11 || RCol7Row1 > 10) {
              setRow3NakedM07(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                          ]
                        )
              }
                } else if (RCol7Row3 === 20.1) {
              if (RCol7Row2 < 11 || RCol7Row1 > 10) {
              setRow3NakedM07(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col07X,row03X,0.5]} />
                          ]
                        )
              }
                } 
              }
              const hideDoorsc8r1 = (e) => {
                if (RCol8Row1 === 19) {
                  setBaseNakedM08(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                          ]
                        )
              
                } else if (RCol8Row1 === 20) {
              setBaseNakedM08(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                          ]
                        )
              
                } else if (RCol8Row1 === 19.1) {
              setBaseNakedM08(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                          ]
                        )
              
                } else if (RCol8Row1 === 20.1) {
              setBaseNakedM08(
                          [
                            <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                          ]
                        )
              
                } else if (RCol8Row1 === 29) {
              setBaseNakedL08(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                } else if (RCol8Row1 === 30) {
              setBaseNakedL08(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                } else if (RCol8Row1 === 29.1) {
              setBaseNakedL08(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                } else if (RCol8Row1 === 30.1) {
              setBaseNakedL08(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                
                } else if (RCol8Row1 === 29.3) {
              setBaseNakedL08(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                } else if (RCol8Row1 === 30.3) {
              setBaseNakedL08(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                } else if (RCol8Row1 === 29.5) {
              setBaseNakedL08(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                } else if (RCol8Row1 === 30.5) {
              setBaseNakedL08(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                
                } else if (RCol8Row1 === 29.4) {
              setBaseNakedL08(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                } else if (RCol8Row1 === 30.4) {
              setBaseNakedL08(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                
                } else if (RCol8Row1 === 29.6) {
              setBaseNakedL08(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                } else if (RCol8Row1 === 30.6) {
              setBaseNakedL08(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                
                } else if (RCol8Row1 === 29.8) {
              setBaseNakedL08(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                } else if (RCol8Row1 === 30.8) {
              setBaseNakedL08(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                } else if (RCol8Row1 === 29.9) {
              setBaseNakedL08(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                } else if (RCol8Row1 === 30.9) {
              setBaseNakedL08(
                          [
                            <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                          ]
                        )
                }
              }
              const hideDoorsc8r2 = (e) => {
                if (RCol8Row2 === 19) {
              if (RCol8Row1 < 11) {
                  setRow2NakedM08(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                          ]
                        )
              }
                } else if (RCol8Row2 === 20) {
              if (RCol8Row1 < 11) {
              setRow2NakedM08(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                          ]
                        )
              }
                
                } else if (RCol8Row2 === 19.1) {
              if (RCol8Row1 < 11) {
              setRow2NakedM08(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                          ]
                        )
              }
                } else if (RCol8Row2 === 20.1) {
              if (RCol8Row1 < 11) {
              setRow2NakedM08(
                          [
                            <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                          ]
                        )
              }
                } 
              }
              const hideDoorsc8r3 = (e) => {
                if (RCol8Row3 === 19) {
                if (RCol8Row2 < 11 || RCol8Row1 > 10) {
                    setRow3NakedM08(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                            ]
                          )
                }
                  } else if (RCol8Row3 === 20) {
                if (RCol8Row2 < 11 || RCol8Row1 > 10) {
                setRow3NakedM08(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                            ]
                          )
                }
                
                  } else if (RCol8Row3 === 19.1) {
                if (RCol8Row2 < 11 || RCol8Row1 > 10) {
                setRow3NakedM08(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                            ]
                          )
                }
                  } else if (RCol8Row3 === 20.1) {
                if (RCol8Row2 < 11 || RCol8Row1 > 10) {
                setRow3NakedM08(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                            ]
                          )
                }
                  } 
                }
                const showDoorsc8r1 = (e) => {
                  if (RCol8Row1 === 19) {
                    setBaseNakedM08(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                
                  } else if (RCol8Row1 === 20) {
                setBaseNakedM08(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                
                  } else if (RCol8Row1 === 19.1) {
                setBaseNakedM08(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                
                  } else if (RCol8Row1 === 20.1) {
                setBaseNakedM08(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                
                  } else if (RCol8Row1 === 29) {
                setBaseNakedL08(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                    ]
                          )
                  } else if (RCol8Row1 === 30) {
                setBaseNakedL08(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                  } else if (RCol8Row1 === 29.1) {
                setBaseNakedL08(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                     ]
                          )
                  } else if (RCol8Row1 === 30.1) {
                setBaseNakedL08(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                  
                  } else if (RCol8Row1 === 29.3) {
                setBaseNakedL08(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                  } else if (RCol8Row1 === 30.3) {
                setBaseNakedL08(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                  
                  } else if (RCol8Row1 === 29.5) {
                setBaseNakedL08(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                  } else if (RCol8Row1 === 30.5) {
                setBaseNakedL08(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                  
                  } else if (RCol8Row1 === 29.4) {
                setBaseNakedL08(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                  } else if (RCol8Row1 === 30.4) {
                setBaseNakedL08(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                  
                  } else if (RCol8Row1 === 29.6) {
                setBaseNakedL08(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                  } else if (RCol8Row1 === 30.6) {
                setBaseNakedL08(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                  
                  } else if (RCol8Row1 === 29.8) {
                setBaseNakedL08(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                  } else if (RCol8Row1 === 30.8) {
                setBaseNakedL08(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                  
                  } else if (RCol8Row1 === 29.9) {
                setBaseNakedL08(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                  } else if (RCol8Row1 === 30.9) {
                setBaseNakedL08(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col08X,row01X,0.5]} />
                            ]
                          )
                  }
                }
                const showDoorsc8r2 = (e) => {
                  if (RCol8Row2 === 19) {
                if (RCol8Row1 < 11) {
                    setRow2NakedM08(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                            ]
                          )
                }
                  } else if (RCol8Row2 === 20) {
                if (RCol8Row1 < 11) {
                setRow2NakedM08(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row02X,0.5]} />
                            ]
                          )
                }
                  
                  } else if (RCol8Row2 === 19.1) {
                if (RCol8Row1 < 11) {
                setRow2NakedM08(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                            ]
                          )
                }
                  } else if (RCol8Row2 === 20.1) {
                if (RCol8Row1 < 11) {
                setRow2NakedM08(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row02X,0.5]} />
                            ]
                          )
                }
                  } 
                }
                const showDoorsc8r3 = (e) => {
                  if (RCol8Row3 === 19) {
                if (RCol8Row2 < 11 || RCol8Row1 > 10) {
                    setRow3NakedM08(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                            ]
                          )
                }
                  } else if (RCol8Row3 === 20) {
                if (RCol8Row2 < 11 || RCol8Row1 > 10) {
                setRow3NakedM08(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col08X,row03X,0.5]} />
                            ]
                          )
                }
                  
                  } else if (RCol8Row3 === 19.1) {
                if (RCol8Row2 < 11 || RCol8Row1 > 10) {
                setRow3NakedM08(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                            ]
                          )
                }
                  } else if (RCol8Row3 === 20.1) {
                if (RCol8Row2 < 11 || RCol8Row1 > 10) {
                setRow3NakedM08(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col08X,row03X,0.5]} />
                            ]
                          )
                }
                  } 
                }
                const hideDoorsc9r1 = (e) => {
                  if (RCol9Row1 === 19) {
                    setBaseNakedM09(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                
                  } else if (RCol9Row1 === 20) {
                setBaseNakedM09(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                
                  } else if (RCol9Row1 === 19.1) {
                setBaseNakedM09(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                
                  } else if (RCol9Row1 === 20.1) {
                setBaseNakedM09(
                            [
                              <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                
                  } else if (RCol9Row1 === 29) {
                setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                  } else if (RCol9Row1 === 30) {
                setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                  } else if (RCol9Row1 === 29.1) {
                setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                  } else if (RCol9Row1 === 30.1) {
                setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                  
                  } else if (RCol9Row1 === 29.3) {
                setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                  } else if (RCol9Row1 === 30.3) {
                setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                  } else if (RCol9Row1 === 29.5) {
                setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                  } else if (RCol9Row1 === 30.5) {
                setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                  
                  } else if (RCol9Row1 === 29.4) {
                setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                  } else if (RCol9Row1 === 30.4) {
                setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                  
                  } else if (RCol9Row1 === 29.6) {
                setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                  } else if (RCol9Row1 === 30.6) {
                setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                  
                  } else if (RCol9Row1 === 29.8) {
                setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                  } else if (RCol9Row1 === 30.8) {
                setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                  } else if (RCol9Row1 === 29.9) {
                setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                  } else if (RCol9Row1 === 30.9) {
                setBaseNakedL09(
                            [
                              <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                            ]
                          )
                  }
                }
                const hideDoorsc9r2 = (e) => {
                  if (RCol9Row2 === 19) {
                if (RCol9Row1 < 11) {
                    setRow2NakedM09(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                            ]
                          )
                }
                  } else if (RCol9Row2 === 20) {
                if (RCol9Row1 < 11) {
                setRow2NakedM09(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                            ]
                          )
                }
                  
                  } else if (RCol9Row2 === 19.1) {
                if (RCol9Row1 < 11) {
                setRow2NakedM09(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                            ]
                          )
                }
                  } else if (RCol9Row2 === 20.1) {
                if (RCol9Row1 < 11) {
                setRow2NakedM09(
                            [
                              <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                            ]
                          )
                }
                  } 
                }
                const hideDoorsc9r3 = (e) => {
                  if (RCol9Row3 === 19) {
                  if (RCol9Row2 < 11 || RCol9Row1 > 10) {
                      setRow3NakedM09(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                              ]
                            )
                  }
                    } else if (RCol9Row3 === 20) {
                  if (RCol9Row2 < 11 || RCol9Row1 > 10) {
                  setRow3NakedM09(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                              ]
                            )
                  }
                  
                    } else if (RCol9Row3 === 19.1) {
                  if (RCol9Row2 < 11 || RCol9Row1 > 10) {
                  setRow3NakedM09(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                              ]
                            )
                  }
                    } else if (RCol9Row3 === 20.1) {
                  if (RCol9Row2 < 11 || RCol9Row1 > 10) {
                  setRow3NakedM09(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                              ]
                            )
                  }
                    } 
                  }
                  const showDoorsc9r1 = (e) => {
                    if (RCol9Row1 === 19) {
                      setBaseNakedM09(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                  
                    } else if (RCol9Row1 === 20) {
                  setBaseNakedM09(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                  
                    } else if (RCol9Row1 === 19.1) {
                  setBaseNakedM09(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                  
                    } else if (RCol9Row1 === 20.1) {
                  setBaseNakedM09(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                  
                    } else if (RCol9Row1 === 29) {
                  setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                      ]
                            )
                    } else if (RCol9Row1 === 30) {
                  setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                    } else if (RCol9Row1 === 29.1) {
                  setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                       ]
                            )
                    } else if (RCol9Row1 === 30.1) {
                  setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                    
                    } else if (RCol9Row1 === 29.3) {
                  setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                    } else if (RCol9Row1 === 30.3) {
                  setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                    
                    } else if (RCol9Row1 === 29.5) {
                  setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                    } else if (RCol9Row1 === 30.5) {
                  setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                    
                    } else if (RCol9Row1 === 29.4) {
                  setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                    } else if (RCol9Row1 === 30.4) {
                  setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                    
                    } else if (RCol9Row1 === 29.6) {
                  setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                    } else if (RCol9Row1 === 30.6) {
                  setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                    
                    } else if (RCol9Row1 === 29.8) {
                  setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                    } else if (RCol9Row1 === 30.8) {
                  setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                    
                    } else if (RCol9Row1 === 29.9) {
                  setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                    } else if (RCol9Row1 === 30.9) {
                  setBaseNakedL09(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col09X,row01X,0.5]} />
                              ]
                            )
                    }
                  }
                  const showDoorsc9r2 = (e) => {
                    if (RCol9Row2 === 19) {
                  if (RCol9Row1 < 11) {
                      setRow2NakedM09(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                              ]
                            )
                  }
                    } else if (RCol9Row2 === 20) {
                  if (RCol9Row1 < 11) {
                  setRow2NakedM09(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row02X,0.5]} />
                              ]
                            )
                  }
                    
                    } else if (RCol9Row2 === 19.1) {
                  if (RCol9Row1 < 11) {
                  setRow2NakedM09(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                              ]
                            )
                  }
                    } else if (RCol9Row2 === 20.1) {
                  if (RCol9Row1 < 11) {
                  setRow2NakedM09(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row02X,0.5]} />
                              ]
                            )
                  }
                    } 
                  }
                  const showDoorsc9r3 = (e) => {
                    if (RCol9Row3 === 19) {
                  if (RCol9Row2 < 11 || RCol9Row1 > 10) {
                      setRow3NakedM09(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                              ]
                            )
                  }
                    } else if (RCol9Row3 === 20) {
                  if (RCol9Row2 < 11 || RCol9Row1 > 10) {
                  setRow3NakedM09(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col09X,row03X,0.5]} />
                              ]
                            )
                  }
                    
                    } else if (RCol9Row3 === 19.1) {
                  if (RCol9Row2 < 11 || RCol9Row1 > 10) {
                  setRow3NakedM09(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                              ]
                            )
                  }
                    } else if (RCol9Row3 === 20.1) {
                  if (RCol9Row2 < 11 || RCol9Row1 > 10) {
                  setRow3NakedM09(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col09X,row03X,0.5]} />
                              ]
                            )
                  }
                    } 
                  }
                  const hideDoorsc10r1 = (e) => {
                    if (RCol10Row1 === 19) {
                      setBaseNakedM10(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                  
                    } else if (RCol10Row1 === 20) {
                  setBaseNakedM10(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                  
                    } else if (RCol10Row1 === 19.1) {
                  setBaseNakedM10(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                  
                    } else if (RCol10Row1 === 20.1) {
                  setBaseNakedM10(
                              [
                                <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                  
                    } else if (RCol10Row1 === 29) {
                  setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                    } else if (RCol10Row1 === 30) {
                  setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                    } else if (RCol10Row1 === 29.1) {
                  setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                    } else if (RCol10Row1 === 30.1) {
                  setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                    
                    } else if (RCol10Row1 === 29.3) {
                  setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                    } else if (RCol10Row1 === 30.3) {
                  setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                    } else if (RCol10Row1 === 29.5) {
                  setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                    } else if (RCol10Row1 === 30.5) {
                  setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                    
                    } else if (RCol10Row1 === 29.4) {
                  setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                    } else if (RCol10Row1 === 30.4) {
                  setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                    
                    } else if (RCol10Row1 === 29.6) {
                  setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                    } else if (RCol10Row1 === 30.6) {
                  setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                    
                    } else if (RCol10Row1 === 29.8) {
                  setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                    } else if (RCol10Row1 === 30.8) {
                  setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                    } else if (RCol10Row1 === 29.9) {
                  setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                    } else if (RCol10Row1 === 30.9) {
                  setBaseNakedL10(
                              [
                                <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                              ]
                            )
                    }
                  }
                  const hideDoorsc10r2 = (e) => {
                    if (RCol10Row2 === 19) {
                  if (RCol10Row1 < 11) {
                      setRow2NakedM10(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                              ]
                            )
                  }
                    } else if (RCol10Row2 === 20) {
                  if (RCol10Row1 < 11) {
                  setRow2NakedM10(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                              ]
                            )
                  }
                    
                    } else if (RCol10Row2 === 19.1) {
                  if (RCol10Row1 < 11) {
                  setRow2NakedM10(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                              ]
                            )
                  }
                    } else if (RCol10Row2 === 20.1) {
                  if (RCol10Row1 < 11) {
                  setRow2NakedM10(
                              [
                                <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                              ]
                            )
                  }
                    } 
                  }
                  const hideDoorsc10r3 = (e) => {
                    if (RCol10Row3 === 19) {
                    if (RCol10Row2 < 11 || RCol10Row1 > 10) {
                        setRow3NakedM10(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                ]
                              )
                    }
                      } else if (RCol10Row3 === 20) {
                    if (RCol10Row2 < 11 || RCol10Row1 > 10) {
                    setRow3NakedM10(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                ]
                              )
                    }
                    
                      } else if (RCol10Row3 === 19.1) {
                    if (RCol10Row2 < 11 || RCol10Row1 > 10) {
                    setRow3NakedM10(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                                ]
                              )
                    }
                      } else if (RCol10Row3 === 20.1) {
                    if (RCol10Row2 < 11 || RCol10Row1 > 10) {
                    setRow3NakedM10(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                                ]
                              )
                    }
                      } 
                    }
                    const showDoorsc10r1 = (e) => {
                      if (RCol10Row1 === 19) {
                        setBaseNakedM10(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                    
                      } else if (RCol10Row1 === 20) {
                    setBaseNakedM10(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                    
                      } else if (RCol10Row1 === 19.1) {
                    setBaseNakedM10(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                    
                      } else if (RCol10Row1 === 20.1) {
                    setBaseNakedM10(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                    
                      } else if (RCol10Row1 === 29) {
                    setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                        ]
                              )
                      } else if (RCol10Row1 === 30) {
                    setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                      } else if (RCol10Row1 === 29.1) {
                    setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                         ]
                              )
                      } else if (RCol10Row1 === 30.1) {
                    setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                      
                      } else if (RCol10Row1 === 29.3) {
                    setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                      } else if (RCol10Row1 === 30.3) {
                    setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                      
                      } else if (RCol10Row1 === 29.5) {
                    setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                      } else if (RCol10Row1 === 30.5) {
                    setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                      
                      } else if (RCol10Row1 === 29.4) {
                    setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                      } else if (RCol10Row1 === 30.4) {
                    setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                      
                      } else if (RCol10Row1 === 29.6) {
                    setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                      } else if (RCol10Row1 === 30.6) {
                    setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                      
                      } else if (RCol10Row1 === 29.8) {
                    setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                      } else if (RCol10Row1 === 30.8) {
                    setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                      
                      } else if (RCol10Row1 === 29.9) {
                    setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                      } else if (RCol10Row1 === 30.9) {
                    setBaseNakedL10(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col10X,row01X,0.5]} />
                                ]
                              )
                      }
                    }
                    const showDoorsc10r2 = (e) => {
                      if (RCol10Row2 === 19) {
                    if (RCol10Row1 < 11) {
                        setRow2NakedM10(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                                ]
                              )
                    }
                      } else if (RCol10Row2 === 20) {
                    if (RCol10Row1 < 11) {
                    setRow2NakedM10(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row02X,0.5]} />
                                ]
                              )
                    }
                      
                      } else if (RCol10Row2 === 19.1) {
                    if (RCol10Row1 < 11) {
                    setRow2NakedM10(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                                ]
                              )
                    }
                      } else if (RCol10Row2 === 20.1) {
                    if (RCol10Row1 < 11) {
                    setRow2NakedM10(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row02X,0.5]} />
                                ]
                              )
                    }
                      } 
                    }
                    const showDoorsc10r3 = (e) => {
                      if (RCol10Row3 === 19) {
                    if (RCol10Row2 < 11 || RCol10Row1 > 10) {
                        setRow3NakedM10(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                ]
                              )
                    }
                      } else if (RCol10Row3 === 20) {
                    if (RCol10Row2 < 11 || RCol10Row1 > 10) {
                    setRow3NakedM10(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col10X,row03X,0.5]} />
                                ]
                              )
                    }
                      
                      } else if (RCol10Row3 === 19.1) {
                    if (RCol10Row2 < 11 || RCol10Row1 > 10) {
                    setRow3NakedM10(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                                ]
                              )
                    }
                      } else if (RCol10Row3 === 20.1) {
                    if (RCol10Row2 < 11 || RCol10Row1 > 10) {
                    setRow3NakedM10(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col10X,row03X,0.5]} />
                                ]
                              )
                    }
                      } 
                    }
                    const hideDoorsc11r1 = (e) => {
                      if (RCol11Row1 === 19) {
                        setBaseNakedM11(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                    
                      } else if (RCol11Row1 === 20) {
                    setBaseNakedM11(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                    
                      } else if (RCol11Row1 === 19.1) {
                    setBaseNakedM11(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                    
                      } else if (RCol11Row1 === 20.1) {
                    setBaseNakedM11(
                                [
                                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                    
                      } else if (RCol11Row1 === 29) {
                    setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                      } else if (RCol11Row1 === 30) {
                    setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                      } else if (RCol11Row1 === 29.1) {
                    setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                      } else if (RCol11Row1 === 30.1) {
                    setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                      
                      } else if (RCol11Row1 === 29.3) {
                    setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                      } else if (RCol11Row1 === 30.3) {
                    setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                      } else if (RCol11Row1 === 29.5) {
                    setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                      } else if (RCol11Row1 === 30.5) {
                    setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                      
                      } else if (RCol11Row1 === 29.4) {
                    setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                      } else if (RCol11Row1 === 30.4) {
                    setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                      
                      } else if (RCol11Row1 === 29.6) {
                    setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                      } else if (RCol11Row1 === 30.6) {
                    setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                      
                      } else if (RCol11Row1 === 29.8) {
                    setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                      } else if (RCol11Row1 === 30.8) {
                    setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                      } else if (RCol11Row1 === 29.9) {
                    setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                      } else if (RCol11Row1 === 30.9) {
                    setBaseNakedL11(
                                [
                                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                ]
                              )
                      }
                    }
                    const hideDoorsc11r2 = (e) => {
                      if (RCol11Row2 === 19) {
                    if (RCol11Row1 < 11) {
                        setRow2NakedM11(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                ]
                              )
                    }
                      } else if (RCol11Row2 === 20) {
                    if (RCol11Row1 < 11) {
                    setRow2NakedM11(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                ]
                              )
                    }
                      
                      } else if (RCol11Row2 === 19.1) {
                    if (RCol11Row1 < 11) {
                    setRow2NakedM11(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                                ]
                              )
                    }
                      } else if (RCol11Row2 === 20.1) {
                    if (RCol11Row1 < 11) {
                    setRow2NakedM11(
                                [
                                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                                ]
                              )
                    }
                      } 
                    }
                    const hideDoorsc11r3 = (e) => {
                      if (RCol11Row3 === 19) {
                      if (RCol11Row2 < 11 || RCol11Row1 > 10) {
                          setRow3NakedM11(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                  ]
                                )
                      }
                        } else if (RCol11Row3 === 20) {
                      if (RCol11Row2 < 11 || RCol11Row1 > 10) {
                      setRow3NakedM11(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                  ]
                                )
                      }
                      
                        } else if (RCol11Row3 === 19.1) {
                      if (RCol11Row2 < 11 || RCol11Row1 > 10) {
                      setRow3NakedM11(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                                  ]
                                )
                      }
                        } else if (RCol11Row3 === 20.1) {
                      if (RCol11Row2 < 11 || RCol11Row1 > 10) {
                      setRow3NakedM11(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                                  ]
                                )
                      }
                        } 
                      }
                      const showDoorsc11r1 = (e) => {
                        if (RCol11Row1 === 19) {
                          setBaseNakedM11(
                                  [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                      
                        } else if (RCol11Row1 === 20) {
                      setBaseNakedM11(
                                  [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                      
                        } else if (RCol11Row1 === 19.1) {
                      setBaseNakedM11(
                                  [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                      
                        } else if (RCol11Row1 === 20.1) {
                      setBaseNakedM11(
                                  [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                      
                        } else if (RCol11Row1 === 29) {
                      setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                          ]
                                )
                        } else if (RCol11Row1 === 30) {
                      setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                        } else if (RCol11Row1 === 29.1) {
                      setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                           ]
                                )
                        } else if (RCol11Row1 === 30.1) {
                      setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                        
                        } else if (RCol11Row1 === 29.3) {
                      setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                        } else if (RCol11Row1 === 30.3) {
                      setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                        
                        } else if (RCol11Row1 === 29.5) {
                      setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                        } else if (RCol11Row1 === 30.5) {
                      setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                        
                        } else if (RCol11Row1 === 29.4) {
                      setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                        } else if (RCol11Row1 === 30.4) {
                      setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                        
                        } else if (RCol11Row1 === 29.6) {
                      setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                        } else if (RCol11Row1 === 30.6) {
                      setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                        
                        } else if (RCol11Row1 === 29.8) {
                      setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                        } else if (RCol11Row1 === 30.8) {
                      setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                        
                        } else if (RCol11Row1 === 29.9) {
                      setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                        } else if (RCol11Row1 === 30.9) {
                      setBaseNakedL11(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col11X,row01X,0.5]} />
                                  ]
                                )
                        }
                      }
                      const showDoorsc11r2 = (e) => {
                        if (RCol11Row2 === 19) {
                      if (RCol11Row1 < 11) {
                          setRow2NakedM11(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                  ]
                                )
                      }
                        } else if (RCol11Row2 === 20) {
                      if (RCol11Row1 < 11) {
                      setRow2NakedM11(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row02X,0.5]} />
                                  ]
                                )
                      }
                        
                        } else if (RCol11Row2 === 19.1) {
                      if (RCol11Row1 < 11) {
                      setRow2NakedM11(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                                  ]
                                )
                      }
                        } else if (RCol11Row2 === 20.1) {
                      if (RCol11Row1 < 11) {
                      setRow2NakedM11(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row02X,0.5]} />
                                  ]
                                )
                      }
                        } 
                      }
                      const showDoorsc11r3 = (e) => {
                        if (RCol11Row3 === 19) {
                      if (RCol11Row2 < 11 || RCol11Row1 > 10) {
                          setRow3NakedM11(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                  ]
                                )
                      }
                        } else if (RCol11Row3 === 20) {
                      if (RCol11Row2 < 11 || RCol11Row1 > 10) {
                      setRow3NakedM11(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col11X,row03X,0.5]} />
                                  ]
                                )
                      }
                        
                        } else if (RCol11Row3 === 19.1) {
                      if (RCol11Row2 < 11 || RCol11Row1 > 10) {
                      setRow3NakedM11(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                                  ]
                                )
                      }
                        } else if (RCol11Row3 === 20.1) {
                      if (RCol11Row2 < 11 || RCol11Row1 > 10) {
                      setRow3NakedM11(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col11X,row03X,0.5]} />
                                  ]
                                )
                      }
                        } 
                      }
                      const hideDoorsc12r1 = (e) => {
                        if (RCol12Row1 === 19) {
                          setBaseNakedM12(
                                  [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                      
                        } else if (RCol12Row1 === 20) {
                      setBaseNakedM12(
                                  [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                      
                        } else if (RCol12Row1 === 19.1) {
                      setBaseNakedM12(
                                  [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                      
                        } else if (RCol12Row1 === 20.1) {
                      setBaseNakedM12(
                                  [
                                    <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                      
                        } else if (RCol12Row1 === 29) {
                      setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                        } else if (RCol12Row1 === 30) {
                      setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                        } else if (RCol12Row1 === 29.1) {
                      setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                        } else if (RCol12Row1 === 30.1) {
                      setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                        
                        } else if (RCol12Row1 === 29.3) {
                      setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                        } else if (RCol12Row1 === 30.3) {
                      setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                        } else if (RCol12Row1 === 29.5) {
                      setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                        } else if (RCol12Row1 === 30.5) {
                      setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                        
                        } else if (RCol12Row1 === 29.4) {
                      setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                        } else if (RCol12Row1 === 30.4) {
                      setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                        
                        } else if (RCol12Row1 === 29.6) {
                      setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                        } else if (RCol12Row1 === 30.6) {
                      setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                        
                        } else if (RCol12Row1 === 29.8) {
                      setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                        } else if (RCol12Row1 === 30.8) {
                      setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                        } else if (RCol12Row1 === 29.9) {
                      setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                        } else if (RCol12Row1 === 30.9) {
                      setBaseNakedL12(
                                  [
                                    <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                  ]
                                )
                        }
                      }
                      const hideDoorsc12r2 = (e) => {
                        if (RCol12Row2 === 19) {
                      if (RCol12Row1 < 11) {
                          setRow2NakedM12(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                                  ]
                                )
                      }
                        } else if (RCol12Row2 === 20) {
                      if (RCol12Row1 < 11) {
                      setRow2NakedM12(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                                  ]
                                )
                      }
                        
                        } else if (RCol12Row2 === 19.1) {
                      if (RCol12Row1 < 11) {
                      setRow2NakedM12(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                                  ]
                                )
                      }
                        } else if (RCol12Row2 === 20.1) {
                      if (RCol12Row1 < 11) {
                      setRow2NakedM12(
                                  [
                                    <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                                  ]
                                )
                      }
                        } 
                      }
                      const hideDoorsc12r3 = (e) => {
                        if (RCol12Row3 === 19) {
                        if (RCol12Row2 < 11 || RCol12Row1 > 10) {
                            setRow3NakedM12(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                    ]
                                  )
                        }
                          } else if (RCol12Row3 === 20) {
                        if (RCol12Row2 < 11 || RCol12Row1 > 10) {
                        setRow3NakedM12(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                    ]
                                  )
                        }
                        
                          } else if (RCol12Row3 === 19.1) {
                        if (RCol12Row2 < 11 || RCol12Row1 > 10) {
                        setRow3NakedM12(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                                    ]
                                  )
                        }
                          } else if (RCol12Row3 === 20.1) {
                        if (RCol12Row2 < 11 || RCol12Row1 > 10) {
                        setRow3NakedM12(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                                    ]
                                  )
                        }
                          } 
                        }
                        const showDoorsc12r1 = (e) => {
                          if (RCol12Row1 === 19) {
                            setBaseNakedM12(
                                    [
                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                        
                          } else if (RCol12Row1 === 20) {
                        setBaseNakedM12(
                                    [
                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                        
                          } else if (RCol12Row1 === 19.1) {
                        setBaseNakedM12(
                                    [
                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                        
                          } else if (RCol12Row1 === 20.1) {
                        setBaseNakedM12(
                                    [
                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                        
                          } else if (RCol12Row1 === 29) {
                        setBaseNakedL12(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                            ]
                                  )
                          } else if (RCol12Row1 === 30) {
                        setBaseNakedL12(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                          } else if (RCol12Row1 === 29.1) {
                        setBaseNakedL12(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                             ]
                                  )
                          } else if (RCol12Row1 === 30.1) {
                        setBaseNakedL12(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                          
                          } else if (RCol12Row1 === 29.3) {
                        setBaseNakedL12(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                          } else if (RCol12Row1 === 30.3) {
                        setBaseNakedL12(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                          
                          } else if (RCol12Row1 === 29.5) {
                        setBaseNakedL12(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                          } else if (RCol12Row1 === 30.5) {
                        setBaseNakedL12(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                          
                          } else if (RCol12Row1 === 29.4) {
                        setBaseNakedL12(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                          } else if (RCol12Row1 === 30.4) {
                        setBaseNakedL12(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                          
                          } else if (RCol12Row1 === 29.6) {
                        setBaseNakedL12(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                          } else if (RCol12Row1 === 30.6) {
                        setBaseNakedL12(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                          
                          } else if (RCol12Row1 === 29.8) {
                        setBaseNakedL12(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                          } else if (RCol12Row1 === 30.8) {
                        setBaseNakedL12(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                          
                          } else if (RCol12Row1 === 29.9) {
                        setBaseNakedL12(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                          } else if (RCol12Row1 === 30.9) {
                        setBaseNakedL12(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col12X,row01X,0.5]} />
                                    ]
                                  )
                          }
                        }
                        const showDoorsc12r2 = (e) => {
                          if (RCol12Row2 === 19) {
                        if (RCol12Row1 < 11) {
                            setRow2NakedM12(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                                    ]
                                  )
                        }
                          } else if (RCol12Row2 === 20) {
                        if (RCol12Row1 < 11) {
                        setRow2NakedM12(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row02X,0.5]} />
                                    ]
                                  )
                        }
                          
                          } else if (RCol12Row2 === 19.1) {
                        if (RCol12Row1 < 11) {
                        setRow2NakedM12(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                                    ]
                                  )
                        }
                          } else if (RCol12Row2 === 20.1) {
                        if (RCol12Row1 < 11) {
                        setRow2NakedM12(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row02X,0.5]} />
                                    ]
                                  )
                        }
                          } 
                        }
                        const showDoorsc12r3 = (e) => {
                          if (RCol12Row2 === 19) {
                        if (RCol12Row2 < 11 || RCol12Row1 > 10) {
                            setRow3NakedM12(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                    ]
                                  )
                        }
                          } else if (RCol12Row2 === 20) {
                        if (RCol12Row2 < 11 || RCol12Row1 > 10) {
                        setRow3NakedM12(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col12X,row03X,0.5]} />
                                    ]
                                  )
                        }
                          
                          } else if (RCol12Row2 === 19.1) {
                        if (RCol12Row2 < 11 || RCol12Row1 > 10) {
                        setRow3NakedM12(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                                    ]
                                  )
                        }
                          } else if (RCol12Row2 === 20.1) {
                        if (RCol12Row2 < 11 || RCol12Row1 > 10) {
                        setRow3NakedM12(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col12X,row03X,0.5]} />
                                    ]
                                  )
                        }
                          } 
                        }
                        const hideDoorsc13r1 = (e) => {
                          if (RCol13Row1 === 19) {
                            setBaseNakedM13(
                                    [
                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                        
                          } else if (RCol13Row1 === 20) {
                        setBaseNakedM13(
                                    [
                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                        
                          } else if (RCol13Row1 === 19.1) {
                        setBaseNakedM13(
                                    [
                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                        
                          } else if (RCol13Row1 === 20.1) {
                        setBaseNakedM13(
                                    [
                                      <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                        
                          } else if (RCol13Row1 === 29) {
                        setBaseNakedL13(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                          } else if (RCol13Row1 === 30) {
                        setBaseNakedL13(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                          } else if (RCol13Row1 === 29.1) {
                        setBaseNakedL13(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                          } else if (RCol13Row1 === 30.1) {
                        setBaseNakedL13(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                          
                          } else if (RCol13Row1 === 29.3) {
                        setBaseNakedL13(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                          } else if (RCol13Row1 === 30.3) {
                        setBaseNakedL13(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                          } else if (RCol13Row1 === 29.5) {
                        setBaseNakedL13(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                          } else if (RCol13Row1 === 30.5) {
                        setBaseNakedL13(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                          
                          } else if (RCol13Row1 === 29.4) {
                        setBaseNakedL13(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                          } else if (RCol13Row1 === 30.4) {
                        setBaseNakedL13(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                          
                          } else if (RCol13Row1 === 29.6) {
                        setBaseNakedL13(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                          } else if (RCol13Row1 === 30.6) {
                        setBaseNakedL13(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                          
                          } else if (RCol13Row1 === 29.8) {
                        setBaseNakedL13(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                          } else if (RCol13Row1 === 30.8) {
                        setBaseNakedL13(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                          } else if (RCol13Row1 === 29.9) {
                        setBaseNakedL13(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                          } else if (RCol13Row1 === 30.9) {
                        setBaseNakedL13(
                                    [
                                      <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                    ]
                                  )
                          }
                        }
                        const hideDoorsc13r2 = (e) => {
                          if (RCol13Row2 === 19) {
                        if (RCol13Row1 < 11) {
                            setRow2NakedM13(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                                    ]
                                  )
                        }
                          } else if (RCol13Row2 === 20) {
                        if (RCol13Row1 < 11) {
                        setRow2NakedM13(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                                    ]
                                  )
                        }
                          
                          } else if (RCol13Row2 === 19.1) {
                        if (RCol13Row1 < 11) {
                        setRow2NakedM13(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                                    ]
                                  )
                        }
                          } else if (RCol13Row2 === 20.1) {
                        if (RCol13Row1 < 11) {
                        setRow2NakedM13(
                                    [
                                      <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                                    ]
                                  )
                        }
                          } 
                        }
                        const hideDoorsc13r3 = (e) => {
                          if (RCol13Row3 === 19) {
                          if (RCol13Row2 < 11 || RCol13Row1 > 10) {
                              setRow3NakedM13(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                      ]
                                    )
                          }
                            } else if (RCol13Row3 === 20) {
                          if (RCol13Row2 < 11 || RCol13Row1 > 10) {
                          setRow3NakedM13(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                      ]
                                    )
                          }
                          
                            } else if (RCol13Row3 === 19.1) {
                          if (RCol13Row2 < 11 || RCol13Row1 > 10) {
                          setRow3NakedM13(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                                      ]
                                    )
                          }
                            } else if (RCol13Row3 === 20.1) {
                          if (RCol13Row2 < 11 || RCol13Row1 > 10) {
                          setRow3NakedM13(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                                      ]
                                    )
                          }
                            } 
                          }
                          const showDoorsc13r1 = (e) => {
                            if (RCol13Row1 === 19) {
                              setBaseNakedM13(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                          
                            } else if (RCol13Row1 === 20) {
                          setBaseNakedM13(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                          
                            } else if (RCol13Row1 === 19.1) {
                          setBaseNakedM13(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                          
                            } else if (RCol13Row1 === 20.1) {
                          setBaseNakedM13(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                          
                            } else if (RCol13Row1 === 29) {
                          setBaseNakedL13(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                              ]
                                    )
                            } else if (RCol13Row1 === 30) {
                          setBaseNakedL13(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                            } else if (RCol13Row1 === 29.1) {
                          setBaseNakedL13(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                               ]
                                    )
                            } else if (RCol13Row1 === 30.1) {
                          setBaseNakedL13(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                            
                            } else if (RCol13Row1 === 29.3) {
                          setBaseNakedL13(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                            } else if (RCol13Row1 === 30.3) {
                          setBaseNakedL13(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                            
                            } else if (RCol13Row1 === 29.5) {
                          setBaseNakedL13(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                            } else if (RCol13Row1 === 30.5) {
                          setBaseNakedL13(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                            
                            } else if (RCol13Row1 === 29.4) {
                          setBaseNakedL13(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                            } else if (RCol13Row1 === 30.4) {
                          setBaseNakedL13(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                            
                            } else if (RCol13Row1 === 29.6) {
                          setBaseNakedL13(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                            } else if (RCol13Row1 === 30.6) {
                          setBaseNakedL13(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                            
                            } else if (RCol13Row1 === 29.8) {
                          setBaseNakedL13(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                            } else if (RCol13Row1 === 30.8) {
                          setBaseNakedL13(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                            
                            } else if (RCol13Row1 === 29.9) {
                          setBaseNakedL13(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                            } else if (RCol13Row1 === 30.9) {
                          setBaseNakedL13(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col13X,row01X,0.5]} />
                                      ]
                                    )
                            }
                          }
                          const showDoorsc13r2 = (e) => {
                            if (RCol13Row2 === 19) {
                          if (RCol13Row1 < 11) {
                              setRow2NakedM13(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                                      ]
                                    )
                          }
                            } else if (RCol13Row2 === 20) {
                          if (RCol13Row1 < 11) {
                          setRow2NakedM13(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row02X,0.5]} />
                                      ]
                                    )
                          }
                            
                            } else if (RCol13Row2 === 19.1) {
                          if (RCol13Row1 < 11) {
                          setRow2NakedM13(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                                      ]
                                    )
                          }
                            } else if (RCol13Row2 === 20.1) {
                          if (RCol13Row1 < 11) {
                          setRow2NakedM13(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row02X,0.5]} />
                                      ]
                                    )
                          }
                            } 
                          }
                          const showDoorsc13r3 = (e) => {
                            if (RCol13Row3 === 19) {
                          if (RCol13Row2 < 11 || RCol13Row1 > 10) {
                              setRow3NakedM13(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                      ]
                                    )
                          }
                            } else if (RCol13Row3 === 20) {
                          if (RCol13Row2 < 11 || RCol13Row1 > 10) {
                          setRow3NakedM13(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col13X,row03X,0.5]} />
                                      ]
                                    )
                          }
                            
                            } else if (RCol13Row3 === 19.1) {
                          if (RCol13Row2 < 11 || RCol13Row1 > 10) {
                          setRow3NakedM13(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                                      ]
                                    )
                          }
                            } else if (RCol13Row3 === 20.1) {
                          if (RCol13Row2 < 11 || RCol13Row1 > 10) {
                          setRow3NakedM13(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col13X,row03X,0.5]} />
                                      ]
                                    )
                          }
                            } 
                          }
                          const hideDoorsc14r1 = (e) => {
                            if (RCol14Row1 === 19) {
                              setBaseNakedM14(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                          
                            } else if (RCol14Row1 === 20) {
                          setBaseNakedM14(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                          
                            } else if (RCol14Row1 === 19.1) {
                          setBaseNakedM14(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                          
                            } else if (RCol14Row1 === 20.1) {
                          setBaseNakedM14(
                                      [
                                        <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                          
                            } else if (RCol14Row1 === 29) {
                          setBaseNakedL14(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                            } else if (RCol14Row1 === 30) {
                          setBaseNakedL14(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                            } else if (RCol14Row1 === 29.1) {
                          setBaseNakedL14(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                            } else if (RCol14Row1 === 30.1) {
                          setBaseNakedL14(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                            
                            } else if (RCol14Row1 === 29.3) {
                          setBaseNakedL14(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                            } else if (RCol14Row1 === 30.3) {
                          setBaseNakedL14(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                            } else if (RCol14Row1 === 29.5) {
                          setBaseNakedL14(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                            } else if (RCol14Row1 === 30.5) {
                          setBaseNakedL14(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                            
                            } else if (RCol14Row1 === 29.4) {
                          setBaseNakedL14(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                            } else if (RCol14Row1 === 30.4) {
                          setBaseNakedL14(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                            
                            } else if (RCol14Row1 === 29.6) {
                          setBaseNakedL14(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                            } else if (RCol14Row1 === 30.6) {
                          setBaseNakedL14(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                            
                            } else if (RCol14Row1 === 29.8) {
                          setBaseNakedL14(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                            } else if (RCol14Row1 === 30.8) {
                          setBaseNakedL14(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                            } else if (RCol14Row1 === 29.9) {
                          setBaseNakedL14(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                            } else if (RCol14Row1 === 30.9) {
                          setBaseNakedL14(
                                      [
                                        <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                      ]
                                    )
                            }
                          }
                          const hideDoorsc14r2 = (e) => {
                            if (RCol14Row2 === 19) {
                          if (RCol14Row1 < 11) {
                              setRow2NakedM14(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                                      ]
                                    )
                          }
                            } else if (RCol14Row2 === 20) {
                          if (RCol14Row1 < 11) {
                          setRow2NakedM14(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                                      ]
                                    )
                          }
                            
                            } else if (RCol14Row2 === 19.1) {
                          if (RCol14Row1 < 11) {
                          setRow2NakedM14(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                                      ]
                                    )
                          }
                            } else if (RCol14Row2 === 20.1) {
                          if (RCol14Row1 < 11) {
                          setRow2NakedM14(
                                      [
                                        <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                                      ]
                                    )
                          }
                            } 
                          }
                          const hideDoorsc14r3 = (e) => {
                            if (RCol14Row3 === 19) {
                            if (RCol14Row2 < 11 || RCol14Row1 > 10) {
                                setRow3NakedM14(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                        ]
                                      )
                            }
                              } else if (RCol14Row3 === 20) {
                            if (RCol14Row2 < 11 || RCol14Row1 > 10) {
                            setRow3NakedM14(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                        ]
                                      )
                            }
                            
                              } else if (RCol14Row3 === 19.1) {
                            if (RCol14Row2 < 11 || RCol14Row1 > 10) {
                            setRow3NakedM14(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                                        ]
                                      )
                            }
                              } else if (RCol14Row3 === 20.1) {
                            if (RCol14Row2 < 11 || RCol14Row1 > 10) {
                            setRow3NakedM14(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:false, longHandleOn:false, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                                        ]
                                      )
                            }
                              } 
                            }
                            const showDoorsc14r1 = (e) => {
                              if (RCol14Row1 === 19) {
                                setBaseNakedM14(
                                        [
                                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                                        ]
                                      )
                            
                              } else if (RCol14Row1 === 20) {
                            setBaseNakedM14(
                                        [
                                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row01X,0.5]} />
                                        ]
                                      )
                            
                              } else if (RCol14Row1 === 19.1) {
                            setBaseNakedM14(
                                        [
                                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                                        ]
                                      )
                            
                              } else if (RCol14Row1 === 20.1) {
                            setBaseNakedM14(
                                        [
                                          <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row01X,0.5]} />
                                        ]
                                      )
                            
                              } else if (RCol14Row1 === 29) {
                            setBaseNakedL14(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                ]
                                      )
                              } else if (RCol14Row1 === 30) {
                            setBaseNakedL14(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                        ]
                                      )
                              } else if (RCol14Row1 === 29.1) {
                            setBaseNakedL14(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                 ]
                                      )
                              } else if (RCol14Row1 === 30.1) {
                            setBaseNakedL14(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                        ]
                                      )
                              
                              } else if (RCol14Row1 === 29.3) {
                            setBaseNakedL14(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                        ]
                                      )
                              } else if (RCol14Row1 === 30.3) {
                            setBaseNakedL14(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                        ]
                                      )
                              
                              } else if (RCol14Row1 === 29.5) {
                            setBaseNakedL14(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                        ]
                                      )
                              } else if (RCol14Row1 === 30.5) {
                            setBaseNakedL14(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                        ]
                                      )
                              
                              } else if (RCol14Row1 === 29.4) {
                            setBaseNakedL14(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                        ]
                                      )
                              } else if (RCol14Row1 === 30.4) {
                            setBaseNakedL14(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:false}} position={[col14X,row01X,0.5]} />
                                        ]
                                      )
                              
                              } else if (RCol14Row1 === 29.6) {
                            setBaseNakedL14(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                        ]
                                      )
                              } else if (RCol14Row1 === 30.6) {
                            setBaseNakedL14(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:false, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                        ]
                                      )
                              
                              } else if (RCol14Row1 === 29.8) {
                            setBaseNakedL14(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                        ]
                                      )
                              } else if (RCol14Row1 === 30.8) {
                            setBaseNakedL14(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                        ]
                                      )
                              
                              } else if (RCol14Row1 === 29.9) {
                            setBaseNakedL14(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                        ]
                                      )
                              } else if (RCol14Row1 === 30.9) {
                            setBaseNakedL14(
                                        [
                                          <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true, seperatorOn2:true, seperatorOn3:true}} position={[col14X,row01X,0.5]} />
                                        ]
                                      )
                              }
                            }
                            const showDoorsc14r2 = (e) => {
                              if (RCol14Row2 === 19) {
                            if (RCol14Row1 < 11) {
                                setRow2NakedM14(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                                        ]
                                      )
                            }
                              } else if (RCol14Row2 === 20) {
                            if (RCol14Row1 < 11) {
                            setRow2NakedM14(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row02X,0.5]} />
                                        ]
                                      )
                            }
                              
                              } else if (RCol14Row2 === 19.1) {
                            if (RCol14Row1 < 11) {
                            setRow2NakedM14(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                                        ]
                                      )
                            }
                              } else if (RCol14Row2 === 20.1) {
                            if (RCol14Row1 < 11) {
                            setRow2NakedM14(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row02X,0.5]} />
                                        ]
                                      )
                            }
                              } 
                            }
                            const showDoorsc14r3 = (e) => {
                              if (RCol14Row3 === 19) {
                            if (RCol14Row2 < 11 || RCol14Row1 > 10) {
                                setRow3NakedM14(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                        ]
                                      )
                            }
                              } else if (RCol14Row3 === 20) {
                            if (RCol14Row2 < 11 || RCol14Row1 > 10) {
                            setRow3NakedM14(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:false}} position={[col14X,row03X,0.5]} />
                                        ]
                                      )
                            }
                              
                              } else if (RCol14Row3 === 19.1) {
                            if (RCol14Row2 < 11 || RCol14Row1 > 10) {
                            setRow3NakedM14(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:false, RoundHandleOn:true, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                                        ]
                                      )
                            }
                              } else if (RCol14Row3 === 20.1) {
                            if (RCol14Row2 < 11 || RCol14Row1 > 10) {
                            setRow3NakedM14(
                                        [
                                          <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:true, doorsOn:true, longHandleOn:true, RoundHandleOn:false, leftOn:true, rightOn:true, seperatorOn:true}} position={[col14X,row03X,0.5]} />
                                        ]
                                      )
                            }
                              } 
                            }

const hideDoors = (e)=> {
  if (RCol1Row1 > 0) {
    hideDoorsc1r1();
  }
  if (RCol1Row2 > 0) {
    hideDoorsc1r2();
  }
  if (RCol1Row3 > 0) {
    hideDoorsc1r3();
  }
  if (RCol2Row1 > 0) {
    hideDoorsc2r1();
  }
  if (RCol2Row2 > 0) {
    hideDoorsc2r2();
  }
  if (RCol2Row3 > 0) {
    hideDoorsc2r3();
  }
  if (RCol3Row1 > 0) {
    hideDoorsc3r1();
  }
  if (RCol3Row2 > 0) {
    hideDoorsc3r2();
  }
  if (RCol3Row3 > 0) {
    hideDoorsc3r3();
  }
  if (RCol4Row1 > 0) {
    hideDoorsc4r1();
  }
  if (RCol4Row2 > 0) {
    hideDoorsc4r2();
  }
  if (RCol4Row3 > 0) {
    hideDoorsc4r3();
  }
  if (RCol5Row1 > 0) {
    hideDoorsc5r1();
  }
  if (RCol5Row2 > 0) {
    hideDoorsc5r2();
  }
  if (RCol5Row3 > 0) {
    hideDoorsc5r3();
  }
  if (RCol6Row1 > 0) {
    hideDoorsc6r1();
  }
  if (RCol6Row2 > 0) {
    hideDoorsc6r2();
  }
  if (RCol6Row3 > 0) {
    hideDoorsc6r3();
  }
  if (RCol7Row1 > 0) {
    hideDoorsc7r1();
  }
  if (RCol7Row2 > 0) {
    hideDoorsc7r2();
  }
  if (RCol7Row3 > 0) {
    hideDoorsc7r3();
  }
  if (RCol8Row1 > 0) {
    hideDoorsc8r1();
  }
  if (RCol8Row2 > 0) {
    hideDoorsc8r2();
  }
  if (RCol8Row3 > 0) {
    hideDoorsc8r3();
  }
  if (RCol9Row1 > 0) {
    hideDoorsc9r1();
  }
  if (RCol9Row2 > 0) {
    hideDoorsc9r2();
  }
  if (RCol9Row3 > 0) {
    hideDoorsc9r3();
  }
  if (RCol10Row1 > 0) {
    hideDoorsc10r1();
  }
  if (RCol10Row2 > 0) {
    hideDoorsc10r2();
  }
  if (RCol10Row3 > 0) {
    hideDoorsc10r3();
  }
  if (RCol11Row1 > 0) {
    hideDoorsc11r1();
  }
  if (RCol11Row2 > 0) {
    hideDoorsc11r2();
  }
  if (RCol11Row3 > 0) {
    hideDoorsc11r3();
  }
  if (RCol12Row1 > 0) {
    hideDoorsc12r1();
  }
  if (RCol12Row2 > 0) {
    hideDoorsc12r2();
  }
  if (RCol12Row3 > 0) {
    hideDoorsc12r3();
  }
  if (RCol13Row1 > 0) {
    hideDoorsc13r1();
  }
  if (RCol13Row2 > 0) {
    hideDoorsc13r2();
  }
  if (RCol13Row3 > 0) {
    hideDoorsc13r3();
  }
  if (RCol14Row1 > 0) {
    hideDoorsc14r1();
  }
  if (RCol14Row2 > 0) {
    hideDoorsc14r2();
  }
  if (RCol14Row3 > 0) {
    hideDoorsc14r3();
  }
  verifydata = "INSIDE PLATES (SHELVES) ARE VISIBLE";
}

const showDoors = (e)=> {
  if (RCol1Row1 > 0) {
    showDoorsc1r1();
  }
  if (RCol1Row2 > 0) {
    showDoorsc1r2();
  }
  if (RCol1Row3 > 0) {
    showDoorsc1r3();
  }
  if (RCol2Row1 > 0) {
    showDoorsc2r1();
  }
  if (RCol2Row2 > 0) {
    showDoorsc2r2();
  }
  if (RCol2Row3 > 0) {
    showDoorsc2r3();
  }
  if (RCol3Row1 > 0) {
    showDoorsc3r1();
  }
  if (RCol3Row2 > 0) {
    showDoorsc3r2();
  }
  if (RCol3Row3 > 0) {
    showDoorsc3r3();
  }
  if (RCol4Row1 > 0) {
    showDoorsc4r1();
  }
  if (RCol4Row2 > 0) {
    showDoorsc4r2();
  }
  if (RCol4Row3 > 0) {
    showDoorsc4r3();
  }
  if (RCol5Row1 > 0) {
    showDoorsc5r1();
  }
  if (RCol5Row2 > 0) {
    showDoorsc5r2();
  }
  if (RCol5Row3 > 0) {
    showDoorsc5r3();
  }
  if (RCol6Row1 > 0) {
    showDoorsc6r1();
  }
  if (RCol6Row2 > 0) {
    showDoorsc6r2();
  }
  if (RCol6Row3 > 0) {
    showDoorsc6r3();
  }
  if (RCol7Row1 > 0) {
    showDoorsc7r1();
  }
  if (RCol7Row2 > 0) {
    showDoorsc7r2();
  }
  if (RCol7Row3 > 0) {
    showDoorsc7r3();
  }
  if (RCol8Row1 > 0) {
    showDoorsc8r1();
  }
  if (RCol8Row2 > 0) {
    showDoorsc8r2();
  }
  if (RCol8Row3 > 0) {
    showDoorsc8r3();
  }
  if (RCol9Row1 > 0) {
    showDoorsc9r1();
  }
  if (RCol9Row2 > 0) {
    showDoorsc9r2();
  }
  if (RCol9Row3 > 0) {
    showDoorsc9r3();
  }
  if (RCol10Row1 > 0) {
    showDoorsc10r1();
  }
  if (RCol10Row2 > 0) {
    showDoorsc10r2();
  }
  if (RCol10Row3 > 0) {
    showDoorsc10r3();
  }
  if (RCol11Row1 > 0) {
    showDoorsc11r1();
  }
  if (RCol11Row2 > 0) {
    showDoorsc11r2();
  }
  if (RCol11Row3 > 0) {
    showDoorsc11r3();
  }
  if (RCol12Row1 > 0) {
    showDoorsc12r1();
  }
  if (RCol12Row2 > 0) {
    showDoorsc12r2();
  }
  if (RCol12Row3 > 0) {
    showDoorsc12r3();
  }
  if (RCol13Row1 > 0) {
    showDoorsc13r1();
  }
  if (RCol13Row2 > 0) {
    showDoorsc13r2();
  }
  if (RCol13Row3 > 0) {
    showDoorsc13r3();
  }
  if (RCol14Row1 > 0) {
    showDoorsc14r1();
  }
  if (RCol14Row2 > 0) {
    showDoorsc14r2();
  }
  if (RCol14Row3 > 0) {
    showDoorsc14r3();
  }

    verifydata = "COMPLETE DESIGN WITH DOORS IS RESTORED";
}

let loadDesign = (e) => {
  if (designLoaded === true) {
    alert("One design is already loaded. Please reset and enter new one.");
  } else {
    if (wallFixed === true) {
        RCol1Row1 = parseFloat(RCol1Row1);
        RCol1Row2 = parseFloat(RCol1Row2);
        RCol1Row3 = parseFloat(RCol1Row3);
        RCol1Row4 = parseFloat(RCol1Row4);
        RCol2Row1 = parseFloat(RCol2Row1);
        RCol2Row2 = parseFloat(RCol2Row2);
        RCol2Row3 = parseFloat(RCol2Row3);
        RCol2Row4 = parseFloat(RCol2Row4);
        RCol3Row1 = parseFloat(RCol3Row1);
        RCol3Row2 = parseFloat(RCol3Row2);
        RCol3Row3 = parseFloat(RCol3Row3);
        RCol3Row4 = parseFloat(RCol3Row4);	
        RCol4Row1 = parseFloat(RCol4Row1);	
        RCol4Row2 = parseFloat(RCol4Row2);	
        RCol4Row3 = parseFloat(RCol4Row3);	
        RCol4Row4 = parseFloat(RCol4Row4);
        RCol5Row1 = parseFloat(RCol5Row1);
        RCol5Row2 = parseFloat(RCol5Row2);
        RCol5Row3 = parseFloat(RCol5Row3);
        RCol5Row4 = parseFloat(RCol5Row4);
        RCol6Row1 = parseFloat(RCol6Row1);
        RCol6Row2 = parseFloat(RCol6Row2);
        RCol6Row3 = parseFloat(RCol6Row3);
        RCol6Row4 = parseFloat(RCol6Row4);
        RCol7Row1 = parseFloat(RCol7Row1);
        RCol7Row2 = parseFloat(RCol7Row2);
        RCol7Row3 = parseFloat(RCol7Row3);
        RCol7Row4 = parseFloat(RCol7Row4);
        RCol8Row1 = parseFloat(RCol8Row1);
        RCol8Row2 = parseFloat(RCol8Row2);
        RCol8Row3 = parseFloat(RCol8Row3);
        RCol8Row4 = parseFloat(RCol8Row4);
        RCol9Row1 = parseFloat(RCol9Row1);
        RCol9Row2 = parseFloat(RCol9Row2);
        RCol9Row3 = parseFloat(RCol9Row3);
        RCol9Row4 = parseFloat(RCol9Row4);
        RCol9Row4 = parseFloat(RCol9Row4);
        RCol10Row2 = parseFloat(RCol10Row2);
        RCol10Row3 = parseFloat(RCol10Row3);
        RCol10Row4 = parseFloat(RCol10Row4);
        RCol11Row1 = parseFloat(RCol11Row1);
        RCol11Row2 = parseFloat(RCol11Row2);
        RCol11Row3 = parseFloat(RCol11Row3);
        RCol11Row4 = parseFloat(RCol11Row4);
        RCol12Row1 = parseFloat(RCol12Row1);
        RCol12Row2 = parseFloat(RCol12Row2);
        RCol12Row3 = parseFloat(RCol12Row3);
        RCol12Row4 = parseFloat(RCol12Row4);
        RCol13Row1 = parseFloat(RCol13Row1);
        RCol13Row2 = parseFloat(RCol13Row2);
        RCol13Row3 = parseFloat(RCol13Row3);
        RCol13Row4 = parseFloat(RCol13Row4);
        RCol14Row1 = parseFloat(RCol14Row1);
        RCol14Row2 = parseFloat(RCol14Row2);
        RCol14Row3 = parseFloat(RCol14Row3);
        RCol14Row4 = parseFloat(RCol14Row4);
        
        if (RCol1Row1 > 0) {
          reloadc1r1();
        }
        if (RCol1Row2 > 0) {
          reloadc1r2();
        }
        if (RCol1Row3 > 0) {
          reloadc1r3();
        }
        if (RCol1Row4 > 0) {
          reloadc1r4();
        }
        if (RCol2Row1 > 0) {
          reloadc2r1();
        }
        if (RCol2Row2 > 0) {
          reloadc2r2();
        }
        if (RCol2Row3 > 0) {
          reloadc2r3();
        }
        if (RCol2Row4 > 0) {
          reloadc2r4();
        }
        if (RCol3Row1 > 0) {
          reloadc3r1();
        }
        if (RCol3Row2 > 0) {
          reloadc3r2();
        }
        if (RCol3Row3 > 0) {
          reloadc3r3();
        }
        if (RCol3Row4 > 0) {
          reloadc3r4();
        }
        if (RCol4Row1 > 0) {
          reloadc4r1();
        }
        if (RCol4Row2 > 0) {
          reloadc4r2();
        }
        if (RCol4Row3 > 0) {
          reloadc4r3();
        }
        if (RCol4Row4 > 0) {
          reloadc4r4();
        }
        if (RCol5Row1 > 0) {
          reloadc5r1();
        }
        if (RCol5Row2 > 0) {
          reloadc5r2();
        }
        if (RCol5Row3 > 0) {
          reloadc5r3();
        }
        if (RCol5Row4 > 0) {
          reloadc5r4();
        }
        if (RCol6Row1 > 0) {
          reloadc6r1();
        }
        if (RCol6Row2 > 0) {
          reloadc6r2();
        }
        if (RCol6Row3 > 0) {
          reloadc6r3();
        }
        if (RCol6Row4 > 0) {
          reloadc6r4();
        }
        if (RCol7Row1 > 0) {
          reloadc7r1();
        }
        if (RCol7Row2 > 0) {
          reloadc7r2();
        }
        if (RCol7Row3 > 0) {
          reloadc7r3();
        }
        if (RCol7Row4 > 0) {
          reloadc7r4();
        }
        if (RCol8Row1 > 0) {
          reloadc8r1();
        }
        if (RCol8Row2 > 0) {
          reloadc8r2();
        }
        if (RCol8Row3 > 0) {
          reloadc8r3();
        }
        if (RCol8Row4 > 0) {
          reloadc8r4();
        }
        if (RCol9Row1 > 0) {
          reloadc9r1();
        }
        if (RCol9Row2 > 0) {
          reloadc9r2();
        }
        if (RCol9Row3 > 0) {
          reloadc9r3();
        }
        if (RCol9Row4 > 0) {
          reloadc9r4();
        }
        if (RCol10Row1 > 0) {
          reloadc10r1();
        }
        if (RCol10Row2 > 0) {
          reloadc10r2();
        }
        if (RCol10Row3 > 0) {
          reloadc10r3();
        }
        if (RCol10Row4 > 0) {
          reloadc10r4();
        }
        if (RCol11Row1 > 0) {
          reloadc11r1();
        }
        if (RCol11Row2 > 0) {
          reloadc11r2();
        }
        if (RCol11Row3 > 0) {
          reloadc11r3();
        }
        if (RCol11Row4 > 0) {
          reloadc11r4();
        }
        if (RCol12Row1 > 0) {
          reloadc12r1();
        }
        if (RCol12Row2 > 0) {
          reloadc12r2();
        }
        if (RCol12Row3 > 0) {
          reloadc12r3();
        }
        if (RCol12Row4 > 0) {
          reloadc12r4();
        }
        if (RCol13Row1 > 0) {
          reloadc13r1();
        }
        if (RCol13Row2 > 0) {
          reloadc13r2();
        }
        if (RCol13Row3 > 0) {
          reloadc13r3();
        }
        if (RCol13Row4 > 0) {
          reloadc13r4();
        }
        if (RCol14Row1 > 0) {
          reloadc14r1();
        }
        if (RCol14Row2 > 0) {
          reloadc14r2();
        }
        if (RCol14Row3 > 0) {
          reloadc14r3();
        }
        if (RCol14Row4 > 0) {
          reloadc14r4();
        }
        getBodyColorCode();
        getBaseColorCode();
        getLeftDoorColorCode();
        getRightDoorColorCode();
        getLeftHandleolorCode();
        getRightHandleColorCode();
        applyColors();
        verifydata = "YOUR VERIFIED DESIGN IS LOADED";
        designLoaded = true;
      } else {
        alert("Fix walls before you load new design.");
      }
  }
}


let saveScreenshot = (e) => {
  setTimeout(function() {
    window.print();
  }, 0)
}

const handleChange = (e) => {
  setDesignNo(e.target.value);
};

const handleSubmit = (e) => {
  if (designLoaded === true) {
    alert("One design is already loaded. Please reset and enter new one.");
    return;
  }
  e.preventDefault();
  axios.get('https://api.phoenix.lk/flodataread/api/readdata.php', {
    params: {
      design_no: designNo
    }
  })
    .then(response => {
      setDesignData(response.data);
      const data = response.data;
      if (data.length > 0) {
        const design = data[0];
        const { design_date_time, price, floweight, roomlength, roomheight, roomwidth, flolength, floheight, c1r1, c1r2, c1r3, c1r4, c2r1, c2r2, c2r3, c2r4, c3r1, c3r2, c3r3, c3r4, c4r1, c4r2, c4r3, c4r4, c5r1, c5r2, c5r3, c5r4, c6r1, c6r2, c6r3, c6r4, c7r1, c7r2, c7r3, c7r4, c8r1, c8r2, c8r3, c8r4, c9r1, c9r2, c9r3, c9r4, c10r1, c10r2, c10r3, c10r4, c11r1, c11r2, c11r3, c11r4, c12r1, c12r2, c12r3, c12r4, c13r1, c13r2, c13r3, c13r4, c14r1, c14r2, c14r3, c14r4, maincolor, basecolor, leftdoorcolor, rightdoorcolor, lefthandlecolor, righthandlecolor } = design;
        setDesignData(design);
        roomLength = roomlength;
        if (roomLength > 0) {
          priceX = price;
          weightX = floweight;
          flodepth = 0.43;
          setInputWidth(roomlength);
          setInputHeight(roomheight);
          setInputDepth(roomwidth);
          setFloWidth(flolength);
          setFloHeight(floheight);
          RCol1Row1 = c1r1;
          RCol1Row2 = c1r2;
          RCol1Row3 = c1r3;
          RCol1Row4 = c1r4;
          RCol2Row1 = c2r1;
          RCol2Row2 = c2r2;
          RCol2Row3 = c2r3;
          RCol2Row4 = c2r4;
          RCol3Row1 = c3r1;
          RCol3Row2 = c3r2;
          RCol3Row3 = c3r3;
          RCol3Row4 = c3r4;	
          RCol4Row1 = c4r1;	
          RCol4Row2 = c4r2;	
          RCol4Row3 = c4r3;	
          RCol4Row4 = c4r4;
          RCol5Row1 = c5r1;
          RCol5Row2 = c5r2;
          RCol5Row3 = c5r3;
          RCol5Row4 = c5r4;
          RCol6Row1 = c6r1;
          RCol6Row2 = c6r2;
          RCol6Row3 = c6r3;
          RCol6Row4 = c6r4;
          RCol7Row1 = c7r1;
          RCol7Row2 = c7r2;
          RCol7Row3 = c7r3;
          RCol7Row4 = c7r4;
          RCol8Row1 = c8r1;
          RCol8Row2 = c8r2;
          RCol8Row3 = c8r3;
          RCol8Row4 = c8r4;
          RCol9Row1 = c9r1;
          RCol9Row2 = c9r2;
          RCol9Row3 = c9r3;
          RCol9Row4 = c9r4;
          RCol10Row1 = c10r1;
          RCol10Row2 = c10r2;
          RCol10Row3 = c10r3;
          RCol10Row4 = c10r4;
          RCol11Row1 = c11r1;
          RCol11Row2 = c11r2;
          RCol11Row3 = c11r3;
          RCol11Row4 = c11r4;
          RCol12Row1 = c12r1;
          RCol12Row2 = c12r2;
          RCol12Row3 = c12r3;
          RCol12Row4 = c12r4;
          RCol13Row1 = c13r1;
          RCol13Row2 = c13r2;
          RCol13Row3 = c13r3;
          RCol13Row4 = c13r4;
          RCol14Row1 = c14r1;
          RCol14Row2 = c14r2;
          RCol14Row3 = c14r3;
          RCol14Row4 = c14r4;
          bodyColor = maincolor;
          BaseColor = basecolor;
          leftDoorColor = leftdoorcolor;
          rightDoorCOlor = rightdoorcolor;
          leftHandleColor = lefthandlecolor;
          rightHandleColor = righthandlecolor;
          verifydata = "YOUR DESIGN NUMBER IS VERIFIED";
          designVerified = true;
        } else {
          verifydata = "INVALID DESIGN NUMBER";
          alert("Number you entered is not a valid design number.");
        }
      } else {
        alert("Number you entered is not a valid design number.");
        verifydata = "IINVALID DESIGN NUMBER";
      }
    })
    .catch(error => {
        //logErrorToMyService(error);
        verifydata = "SOMETHING HAS GONE WRONG!";
      alert("Something has gone wrong.");
    });
};

const designNoKeyDown = (event) => {
  if (event.code === "Enter") {
    if (designLoaded === true) {
      alert("One design is already loaded. Please reset and enter new one.");
      return;
    }
    event.preventDefault();
    axios.get('https://api.phoenix.lk/flodataread/api/readdata.php', {
      params: {
        design_no: designNo
      }
    })
      .then(response => {
        setDesignData(response.data);
        const data = response.data;
        if (data.length > 0) {
          const design = data[0];
          const { design_date_time, price, floweight, roomlength, roomheight, roomwidth, flolength, floheight, c1r1, c1r2, c1r3, c1r4, c2r1, c2r2, c2r3, c2r4, c3r1, c3r2, c3r3, c3r4, c4r1, c4r2, c4r3, c4r4, c5r1, c5r2, c5r3, c5r4, c6r1, c6r2, c6r3, c6r4, c7r1, c7r2, c7r3, c7r4, c8r1, c8r2, c8r3, c8r4, c9r1, c9r2, c9r3, c9r4, c10r1, c10r2, c10r3, c10r4, c11r1, c11r2, c11r3, c11r4, c12r1, c12r2, c12r3, c12r4, c13r1, c13r2, c13r3, c13r4, c14r1, c14r2, c14r3, c14r4, maincolor, basecolor, leftdoorcolor, rightdoorcolor, lefthandlecolor, righthandlecolor } = design;
          setDesignData(design);
          roomLength = roomlength;
          if (roomLength > 0) {
            priceX = price;
            weightX = floweight;
            flodepth = 0.43;
            setInputWidth(roomlength);
            setInputHeight(roomheight);
            setInputDepth(roomwidth);
            setFloWidth(flolength);
            setFloHeight(floheight);
            RCol1Row1 = c1r1;
            RCol1Row2 = c1r2;
            RCol1Row3 = c1r3;
            RCol1Row4 = c1r4;
            RCol2Row1 = c2r1;
            RCol2Row2 = c2r2;
            RCol2Row3 = c2r3;
            RCol2Row4 = c2r4;
            RCol3Row1 = c3r1;
            RCol3Row2 = c3r2;
            RCol3Row3 = c3r3;
            RCol3Row4 = c3r4;	
            RCol4Row1 = c4r1;	
            RCol4Row2 = c4r2;	
            RCol4Row3 = c4r3;	
            RCol4Row4 = c4r4;
            RCol5Row1 = c5r1;
            RCol5Row2 = c5r2;
            RCol5Row3 = c5r3;
            RCol5Row4 = c5r4;
            RCol6Row1 = c6r1;
            RCol6Row2 = c6r2;
            RCol6Row3 = c6r3;
            RCol6Row4 = c6r4;
            RCol7Row1 = c7r1;
            RCol7Row2 = c7r2;
            RCol7Row3 = c7r3;
            RCol7Row4 = c7r4;
            RCol8Row1 = c8r1;
            RCol8Row2 = c8r2;
            RCol8Row3 = c8r3;
            RCol8Row4 = c8r4;
            RCol9Row1 = c9r1;
            RCol9Row2 = c9r2;
            RCol9Row3 = c9r3;
            RCol9Row4 = c9r4;
            RCol10Row1 = c10r1;
            RCol10Row2 = c10r2;
            RCol10Row3 = c10r3;
            RCol10Row4 = c10r4;
            RCol11Row1 = c11r1;
            RCol11Row2 = c11r2;
            RCol11Row3 = c11r3;
            RCol11Row4 = c11r4;
            RCol12Row1 = c12r1;
            RCol12Row2 = c12r2;
            RCol12Row3 = c12r3;
            RCol12Row4 = c12r4;
            RCol13Row1 = c13r1;
            RCol13Row2 = c13r2;
            RCol13Row3 = c13r3;
            RCol13Row4 = c13r4;
            RCol14Row1 = c14r1;
            RCol14Row2 = c14r2;
            RCol14Row3 = c14r3;
            RCol14Row4 = c14r4;
            bodyColor = maincolor;
            BaseColor = basecolor;
            leftDoorColor = leftdoorcolor;
            rightDoorCOlor = rightdoorcolor;
            leftHandleColor = lefthandlecolor;
            rightHandleColor = righthandlecolor;
            verifydata = "YOUR DESIGN NUMBER IS VERIFIED";
            designVerified = true;
          } else {
            verifydata = "INVALID DESIGN NUMBER";
            alert("Number you entered is not a valid design number.");
          }
        } else {
          alert("Number you entered is not a valid design number.");
          verifydata = "INVALID DESIGN NUMBER";
        }
      })
      .catch(error => {
          //logErrorToMyService(error);
          verifydata = "SOMETHING HAS GONE WRONG!";
        alert("Something has gone wrong.");
      });
  };
  
}
  return (
    <div className="row">
    <div  className="canvas-card">
      <div className="top-bar">
          <div className="row">
            <div className="title-image-box">
              <img src="./images/title-image.png" alt="Flo Logo" width="100%"/>
            </div>
           
            <div className="navigatorX">
              <table>
                <tr>
                  <td>
                      <div className="layerReset">
                        <div className="reset-all">
                          <button className="image-buttons" onClick={restAndFixRoomAgain}><img src="./images/reset-button.png" alt="joystick up" width="70px"/></button>
                        </div>
                        <div className="remove-walls">
                          <button className="image-buttons" onClick={onOffWalls}><img src={wallshowhideimg} alt="joystick up" width="70px"/></button>
                        </div>
                      </div>
                  </td>
                 
                  
                </tr>
              </table>
            </div>
          </div>
          <div className="canvasArea" id="screenshot">
          <Canvas style={{ background: "#595757" }} dpr={window.devicePixelRatio} tabIndex='0' onKeyDown={handleKeyDown} >
            <Suspense fallback={<Loader />}>
              <OrbitControls makeDefault />
                <Physics gravity={[0, -10, 0]} size={100} tolerance={0.001} iterations={5} broadphase={"Naive"} step={1/60} shouldInvalidate={true} children allowSleep={false} axisIndex={0} defaultContactMaterial={1e6} id="screenshot">
                  <WideWall x={inputWidth} y={0.1} z={inputHeight} position={[0,posy,0]} rotation={[Math.PI / 2, 0, 0]} visible={wallvisible}/>
                  <SideWalls x={inputHeight} y={0.1} z={inputDepth} position={[-posx,posy,posz]} rotation={[0, 0, Math.PI / 2]} visible={wallvisible}/>
                  <SideWalls x={inputHeight} y={0.1} z={inputDepth} position={[posx,posy,posz]} rotation={[0, 0, Math.PI / 2]} visible={wallvisible}/>
                  <Model10M customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:backOn, doorsOn:doorsOn, longHandleOn:longHandleOn, RoundHandleOn:RoundHandleOn, leftOn:leftOn, rightOn:rightOn, seperatorOn:seperatorOn }} position={[2,200,-300]}/>
                  <Model10MUp customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:backOn, doorsOn:doorsOn, longHandleOn:longHandleOn, RoundHandleOn:RoundHandleOn, leftOn:leftOn, rightOn:rightOn, seperatorOn:seperatorOn }} position={[3,200,-300]}/>                          
                  <Model10 customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:backOn, doorsOn:doorsOn, longHandleOn:longHandleOn, RoundHandleOn:RoundHandleOn, leftOn:leftOn, rightOn:rightOn}} position={[4,200,-300]}/> 
                  <Model10Up customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle }} addRemoveItems={{backOn:backOn, doorsOn:doorsOn, longHandleOn:longHandleOn, RoundHandleOn:RoundHandleOn, leftOn:leftOn, rightOn:rightOn}} position={[-1,200,-300]}/> 
                  <Model10L customColors={{smallRack:back, basefeet:basefeet, leftDoor:leftDoor, rightdoor:rightdoor, back:back, left:back, right:back, lhandle:lhandle, rhandle:rhandle, seperator:back }} addRemoveItems={{backOn:backOn, doorsOn:doorsOn, longHandleOn:longHandleOn, RoundHandleOn:RoundHandleOn, leftOn:leftOn, rightOn:rightOn, seperatorOn:seperatorOn, seperatorOn2:seperatorOn2, seperatorOn3:seperatorOn3 }} position={[-2,200,-300]}/>

                  
                  <Pointer position={[curX+0.2,curY-0.2, 0.53]} rotation={[0, 0, 0]} visible={rightArrrow}/> 
                  <Pointer position={[curX+0.2,curY-0.2, 0.53]} rotation={[0, Math.PI, 0]} visible={leftArrrow}/> 
                  <Pointer position={[curX+0.4,curY+0.2, 0.53]} rotation={[0, 0, Math.PI / 2]} visible={upArrrow}/>   
                  <Pointer position={[curX-0.2,curY+0.2, 0.53]} rotation={[0, 0, -Math.PI / 2]} visible={downArrrow}/>    
                  
                 
                              {[...baseNaked01]}
                              {[...baseNaked02]}
                              {[...baseNaked03]}
                              {[...baseNaked04]}
                              {[...baseNaked05]}
                              {[...baseNaked06]}
                              {[...baseNaked07]}
                              {[...baseNaked08]}
                              {[...baseNaked09]}
                              {[...baseNaked10]}
                              {[...baseNaked11]}
                              {[...baseNaked12]}
                              {[...baseNaked13]}
                              {[...baseNaked14]}

                              {[...row2Naked01]}
                              {[...row2Naked02]}
                              {[...row2Naked03]}
                              {[...row2Naked04]}
                              {[...row2Naked05]}
                              {[...row2Naked06]}
                              {[...row2Naked07]}
                              {[...row2Naked08]}
                              {[...row2Naked09]}
                              {[...row2Naked10]}
                              {[...row2Naked11]}
                              {[...row2Naked12]}
                              {[...row2Naked13]}
                              {[...row2Naked14]}

                              {[...row3Naked01]}
                              {[...row3Naked02]}
                              {[...row3Naked03]}
                              {[...row3Naked04]}
                              {[...row3Naked05]}
                              {[...row3Naked06]}
                              {[...row3Naked07]}
                              {[...row3Naked08]}
                              {[...row3Naked09]}
                              {[...row3Naked10]}
                              {[...row3Naked11]}
                              {[...row3Naked12]}
                              {[...row3Naked13]}
                              {[...row3Naked14]}

                              {[...baseNakedM01]}
                              {[...baseNakedM02]}
                              {[...baseNakedM03]}
                              {[...baseNakedM04]}
                              {[...baseNakedM05]}
                              {[...baseNakedM06]}
                              {[...baseNakedM07]}
                              {[...baseNakedM08]}
                              {[...baseNakedM09]}
                              {[...baseNakedM10]}
                              {[...baseNakedM11]}
                              {[...baseNakedM12]}
                              {[...baseNakedM13]}
                              {[...baseNakedM14]}

                              {[...row2NakedM01]}
                              {[...row2NakedM02]}
                              {[...row2NakedM03]}
                              {[...row2NakedM04]}
                              {[...row2NakedM05]}
                              {[...row2NakedM06]}
                              {[...row2NakedM07]}
                              {[...row2NakedM08]}
                              {[...row2NakedM09]}
                              {[...row2NakedM10]}
                              {[...row2NakedM11]}
                              {[...row2NakedM12]}
                              {[...row2NakedM13]}
                              {[...row2NakedM14]}


                              {[...row4Naked01]}
                              {[...row4Naked02]}
                              {[...row4Naked03]}
                              {[...row4Naked04]}
                              {[...row4Naked05]}
                              {[...row4Naked06]}
                              {[...row4Naked07]}
                              {[...row4Naked08]}
                              {[...row4Naked09]}
                              {[...row4Naked10]}
                              {[...row4Naked11]}
                              {[...row4Naked12]}
                              {[...row4Naked13]}
                              {[...row4Naked14]}

                              {[...row3NakedM01]}
                              {[...row3NakedM02]}
                              {[...row3NakedM03]}
                              {[...row3NakedM04]}
                              {[...row3NakedM05]}
                              {[...row3NakedM06]}
                              {[...row3NakedM07]}
                              {[...row3NakedM08]}
                              {[...row3NakedM09]}
                              {[...row3NakedM10]}
                              {[...row3NakedM11]}
                              {[...row3NakedM12]}
                              {[...row3NakedM13]}
                              {[...row3NakedM14]}

                              {[...baseNakedL01]}
                              {[...baseNakedL02]}
                              {[...baseNakedL03]}
                              {[...baseNakedL04]}
                              {[...baseNakedL05]}
                              {[...baseNakedL06]}
                              {[...baseNakedL07]}
                              {[...baseNakedL08]}
                              {[...baseNakedL09]}
                              {[...baseNakedL10]}
                              {[...baseNakedL11]}
                              {[...baseNakedL12]}
                              {[...baseNakedL13]}
                              {[...baseNakedL14]}

      

                  <Floor x={inputDepth} y={0.05} z={inputWidth} position={[0,0,posz]} rotation={[0, Math.PI / 2, 0]}/>       
                  <Plane  />
                </Physics>
                <ambientLight />
                <pointLight position={[10, 10, 10]} />
                <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />
            </Suspense>
          </Canvas>
          </div>
          <div className="status-bar">
            <table className="tablefontsize">
              <tr>
                <td>FLO Length: {floWidth} Meters</td>
                <td>FLO Height: {floHeight} Meters</td>
                <td>FLO width: {flodepth} Meters</td>
                <td>FLO Weight: {Math.round(weightX)} Kg</td>
                <td><button className="button" value="#ff635a" onClick={saveScreenshot}>GET YOUR SCRENSHOT</button></td>
              </tr>
            </table>
          </div>
        </div>
       
      </div>
      <div className="right-panel-card">

        
            <div className="segments">
              <form onSubmit={handleSubmit}>
              <div >
                  <img src="./images/blank.png" alt="joystick blank" width="15px"/>
                  <label>Enter Design No:</label>
                  <img src="./images/blank.png" alt="joystick blank" width="15px"/>
                    <input type="text" value={designNo} onChange={handleChange} onKeyDown={designNoKeyDown}/>
                    <button className="button" type="submit">VERIFY DESIGN NO.</button>
                  </div>
                </form>
              
            </div>
            
            <div className="segments">
              <div className="grid-row">
                <div className="table-grid"><img src="./images/blank.png" alt="joystick blank" width="15px"/></div>
                <div className="table-grid2"><b>Room Length</b></div>
                <div className="table-grid2"><b>Room Height</b></div>
                <div className="table-grid2"><b>Room Width</b></div>
              </div>
              <div className="grid-row">
                <div className="table-grid"><img src="./images/blank.png" alt="joystick blank" width="15px"/></div>
                <div className="table-grid2">{inputWidth} m</div>
                <div className="table-grid2">{inputHeight} m</div>
                <div className="table-grid2">{inputDepth} m</div>
              </div>
             
              <div><button className="button" onClick={fixRoom}>FIX WALLS</button></div>
              <div className="grid-row">
                <div className="table-grid"><img src="./images/blank.png" alt="joystick blank" width="15px"/></div>
                <div className="table-grid2"><b>Price</b></div>
                <div className="table-grid2"><b>Weight</b></div>
                <div className="table-grid2"><b>Product Length</b></div>
              </div>
              <div className="grid-row">
                <div className="table-grid"><img src="./images/blank.png" alt="joystick blank" width="15px"/></div>
                <div className="table-grid2">Rs. {priceX}</div>
                <div className="table-grid2">{weightX} Kg</div>
                <div className="table-grid2">{floWidth } m</div>
              </div>

            </div>
          
            <div><button className="button" onClick={loadDesign}>LOAD DESIGN</button></div>
            <div className="segments">
                  <img src="./images/belt.png" alt="Small Module" width="100%"/>
              </div>
            <div>
              <table>
                    <tr>
                      <td>
                        <div className="brand-statement-txt2">See Inside Plates (Shelves):</div>
                      </td>
                      <td><button className="image-buttons" onClick={hideDoors}><img src="./images/Open-Doors.png" alt="Open Doors" width="80px"/></button></td>
                      <td><button className="image-buttons" onClick={showDoors}><img src="./images/Close-Doors.png" alt="Close Doors" width="80px"/></button></td>
                    </tr>
                </table>
            </div>
              <div className="segments">
                  <img src="./images/belt.png" alt="Small Module" width="100%"/>
              </div>
              <div className="segments2">
                <h3 className="verifydatastyle">{verifydata}</h3>
              </div>
              
      </div>
    </div>
  );
}

export default App;
